import { Component, Input, OnInit } from '@angular/core';
import { AppConfig } from '@app/config/app-config';
import { MatDialog, MatDialogConfig } from '@angular/material/dialog';
import { SelectMnoComponent } from '../select-mno/select-mno.component';
import { AddNewOfferComponent } from '../add-new-offer/add-new-offer.component';
import { PartnerService } from '@app/_services';

@Component({
	selector: 'api-configuration',
	templateUrl: './api-configuration.component.html',
	styleUrls: ['./api-configuration.component.css']
})

export class ApiConfiguration implements OnInit {

	idMenu: number;
	currentMenu: any;
	menus = AppConfig.menus;
	countryAlreadyChecked = [];

	countries = AppConfig.countries;
	@Input() isEditingPartner;

	constructor(public dialog: MatDialog, private partnerService: PartnerService) {}

	ngOnInit(): void {
		this.currentMenu = this.menus.filter(menu => menu.id == this.idMenu)[0];
		if (!this.isEditingPartner) {
			this.partnerService.getCurrentPartner().setOperatingCountries([]);	
		}
	}

	closeDialog() {
		this.dialog.closeAll();
	}

	openDialogNext() {
		const dialogConfig = new MatDialogConfig();

        dialogConfig.disableClose = true;
        dialogConfig.autoFocus = true;

		this.dialog.closeAll();
		const dialogRef = this.dialog.open(SelectMnoComponent, dialogConfig);
		dialogRef.componentInstance.isEditingPartner = this.isEditingPartner;
	}

	countryCodeToCheck() {
		let code = [];
		this.partnerService.getCurrentPartner().getOperatingCountries().forEach(country => {
			code.push(country.code);
		});
		return code;
	}

	checkSelectedCountry(countryCode) {
		return this.countryCodeToCheck().includes(countryCode);
	}

	openDialogPrevious() {
		const dialogConfig = new MatDialogConfig();

        dialogConfig.disableClose = true;
        dialogConfig.autoFocus = true;

		this.dialog.closeAll();
		const dialogRef = this.dialog.open(AddNewOfferComponent, dialogConfig);
		dialogRef.componentInstance.isEditingPartner = this.isEditingPartner;
	}

	checkCountryValue ($e) {
		let operatingCountries = this.partnerService.getCurrentPartner().getOperatingCountries();
		if ($e.target.checked) {
			let country = this.countries.filter(country => country.code == $e.target.value);
			operatingCountries.push(country[0]);
		} else {
			operatingCountries.forEach((country, i) => {
				if (country.code == $e.target.value) {
					operatingCountries.splice(i, 1);
				}
			});
		}
	}

}
