import { Component, OnInit, Input } from '@angular/core';
import { MatDialog } from '@angular/material/dialog';
import { ProfileNameModalComponent } from './profile-name-modal/profile-name-modal.component';
import { Profil } from '@app/_models';
import { Router, ActivatedRoute } from '@angular/router';
import { AlertService, ProfilService } from '@app/_services';
import { AppConfig } from '@app/config/app-config';

@Component({
	selector: 'app-profil-config',
	templateUrl: './profil-config.component.html',
	styleUrls: ['./profil-config.component.css']
})

export class ProfilConfigComponent implements OnInit {

	@Input() profil : Profil;
	menus = AppConfig.menus;
	profile = {
		profileName: '',
		roles: [],
	}
  rights: any = [];
	loading = false;
	submitted = false;
	constructor(
		public dialog: MatDialog,
		private router: Router,
		private route: ActivatedRoute,
		private profilService: ProfilService,
		private activatedRoute: ActivatedRoute,
		private alertService: AlertService) {}

		ngOnInit(): void {
			this.activatedRoute.queryParams.subscribe(params => {
				this.profile.profileName = params["profile"]
				this.getRightsForProfileName()
			});
		}

		getRightsForProfileName(){
			this.profilService.getRights(this.profile.profileName).subscribe(resp =>{
				 this.rights = resp['data']
				 this.profile.roles = this.rights && this.rights. roles ? this.rights.roles : []
			})
		}

		openDialog(menu: any) {
			let profileRoles =  this.rights && this.rights.roles ? this.rights.roles.find(r => r.menu == menu.label) : [];
			const dialogRef = this.dialog.open(ProfileNameModalComponent, {data: profileRoles});
			dialogRef.componentInstance.idMenu = menu.id;
			dialogRef.afterClosed().subscribe(result => {
				if(result){
					let roleIndex = this.profile && this.profile.roles ? this.profile.roles.findIndex(r => r.menu == result.menu) : -1;
					if(roleIndex != -1)
						this.profile.roles.splice(roleIndex, 1);

					this.profile.roles.push(result)
				}
			});
		}

		saveRights(){
			this.loading = !this.loading;
			this.profilService.addRights(this.profile).subscribe(resp => {
				if(resp['data']){
					this.alertService.success('Success', 'Rights added successfully', { keepAfterRouteChange: true });
					this.dialog.closeAll();
					this.router.navigate(["/home/profils"])
				}else{
					this.alertService.success('Error', 'Error when adding rights', { keepAfterRouteChange: true });
				}
			})
		}

	}
