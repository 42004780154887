<div class="flex flex-col">
	<div class="my-2 overflow-x-auto sm:-mx-6 lg:-mx-8">
		<div class="py-2 align-middle inline-block min-w-full sm:px-6 lg:px-8 grid grid-cols-6 gap-4">

			<div class="col-span-6 pb-5 pl-10 border-gray-200 border-b relative">
				<h6>
					Ajout de nouveau partenaire
				</h6>

				<h6 class="text-blak text-right absolute right-5 top-1 font-bold px-5 cursor-pointer"
					(click)="closeDialog()">
					x
				</h6>
			</div>

			<div class="col-start-2 col-span-4 pt-5">

				<div class="grid grid-cols-4">
					<div class="col-span-1 text-right pt-5 pr-5 mt-5">
						<span class="text-gray-700">Nom du partenaire</span>
					</div>
					<div class="col-span-3 mt-5">
						<input type="text" placeholder="Nom du partenaire" [(ngModel)]="partnerName" />
					</div>

					<div class="col-span-1 text-right pt-5 pr-5 mt-5">
						<span class="text-gray-700">Type de partenaire</span>
					</div>
					<div class="col-span-3 mt-5">
						<select name="cars" id="cars" class="block" [(ngModel)]="partnerType">
							<option value="irt">IRT</option>
							<option value="merchand">Merchand</option>
							<option value="service-provider">Service provider</option>
						</select>
					</div>

					<div class="col-span-1 text-right pt-5 pr-5 mt-5">
						<span class="text-gray-700">Personne à contacter</span>
					</div>
					<div class="col-span-3 mt-5">
						<input type="number" placeholder="Personne à contacter" [(ngModel)]="partnerContact" />
					</div>

					<div class="col-span-4 text-center">
						<button class="bg-yellow-500 text-white font-bold py-2 px-10 rounded-full mt-5"
							(click)="openDialog()">
							Suivant
						</button>
					</div>

				</div>
			</div>
		</div>
	</div>
</div>