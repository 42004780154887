import { NgModule } from '@angular/core';
import { RouterModule } from '@angular/router';
import { ReactiveFormsModule } from '@angular/forms';
import { CommonModule } from '@angular/common';
import { HttpClient } from '@angular/common/http';

import { OrderEmissionRoutingModule } from './order-emission-rooting.module';

import { BankDepositComponent } from './bank-deposit/bank-deposit.component';
import { AddDepositComponent } from './bank-deposit/add/add-deposit.component';
import { RepaymentComponent } from './repayment/repayment.component';
import { ValidationComponent } from './validation/validation.component';

// import ngx-translate and the http loader
import { TranslateLoader, TranslateModule } from '@ngx-translate/core';
import { TranslateHttpLoader } from '@ngx-translate/http-loader';
import { MatMenuModule } from '@angular/material/menu';

@NgModule({
    imports: [
        CommonModule,
        ReactiveFormsModule,
        OrderEmissionRoutingModule,
        TranslateModule.forRoot({
			loader: {
				provide: TranslateLoader,
				useFactory: HttpLoaderFactory,
				deps: [HttpClient],
			},
		}),
        MatMenuModule,
        RouterModule
    ],
    declarations: [
        BankDepositComponent,
        AddDepositComponent,
        RepaymentComponent,
        ValidationComponent
    ]
})
export class OrderEmissionModule { }

// required for AOT compilation
export function HttpLoaderFactory(http: HttpClient): TranslateHttpLoader {
	return new TranslateHttpLoader(http);
}
