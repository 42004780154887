import { Component, OnInit } from '@angular/core';
import { User } from 'src/app/_models';
import { AccountService, MenuState } from '@app/_services/';

@Component({
	selector: 'app-main-wrapper',
	templateUrl: './main-wrapper.component.html',
	styleUrls: ['./main-wrapper.component.css']
})

export class MainWrapperComponent implements OnInit {
	user: User;
	status: boolean;
	selectedService: string;

	ngOnInit(): void {
		this.status = this.menuService.getStatus();
		this.menuService.menuSubject.subscribe(
			(menu: boolean) => {
				console.log('menu : ', menu);
				this.status = menu;
			});
		this.menuService.emitMenu();
	}

	constructor(
		private accountService: AccountService,
		private menuService: MenuState
		// private bnIdle: BnNgIdleService,
		// private alertService: AlertService,
		// private router: Router
	) {
		this.accountService.user.subscribe(x => this.user = x);
		this.accountService.selectedService.subscribe(x => {
			this.selectedService = x
		});
		/* this.bnIdle.startWatching(900).subscribe((res) => {
			if (res) {
				// this.alertService.success("Vous avez été déconnecté pour cause d'inactivité!", { keepAfterRouteChange: true });
				this.logout();
			}
		}); */
	}

	sidebarHandler() {
		// this.status = !this.status;
		this.menuService.setStatus(!this.menuService.getStatus());
		this.status = this.menuService.getStatus();
	}

}
