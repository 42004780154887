<nav class="bg-transparent shadow-none">
	<div class="nav-wrapper">
		<div class="col s12">
			<a href="#" class="breadcrumb app-root text-gray-400">App</a>
			<a href="#" class="breadcrumb text-gray-400">Offer</a>
		</div>
	</div>
</nav>

<div class="content pb-10">
	<div class="content-header bg-white rounded m-5 p-0">
		<div class="grid grid-cols-1 shadow-lg p-5">

			<div class="bg-white grid grid-cols-3 border-b-2 border-gray-200 pb-5">
				<div class="col-span-1 grid grid-cols-2">
					<div class="col-span-1">
						<button class="text-yellow-500 bg-transparent border border-solid 
							border-yellow-500 hover:bg-yellow-500 hover:text-white 
							active:bg-yellow-600 font-bold px-8 py-3 rounded-full 
							outline-none focus:outline-none mr-1 mb-1 ease-linear transition-all 
							duration-150 mr-10" type="button">
							Pending
					  	</button>

						<button class="text-yellow-500 bg-transparent border border-solid 
							border-yellow-500 hover:bg-yellow-500 hover:text-white 
							active:bg-yellow-600 font-bold px-8 py-3 rounded-full 
							outline-none focus:outline-none mr-1 mb-1 ease-linear transition-all 
							duration-150 mr-10" type="button">
							Treated
						</button>
					</div>
				</div>
			</div>

			<!-- This example requires Tailwind CSS v2.0+ -->
			<div class="flex flex-col">
				<div class="my-2 overflow-x-auto sm:-mx-6 lg:-mx-8">
					<div class="py-2 align-middle inline-block min-w-full sm:px-6 lg:px-8">
						<div class="shadow overflow-hidden border-b border-gray-200 sm:rounded-lg">
							<table class="min-w-full divide-y divide-gray-200">
								<thead class="bg-gray-50">
									<tr>
										<th scope="col"
											class="px-6 py-3 text-left text-xs font-medium text-gray-500 uppercase tracking-wider">
											#
										</th>
		
										<th scope="col"
											class="px-6 py-3 text-left text-xs font-medium text-gray-500 uppercase tracking-wider">
											Id
										</th>
		
										<th scope="col"
											class="px-6 py-3 text-left text-xs font-medium text-gray-500 uppercase tracking-wider">
											Partner name
										</th>
		
										<th scope="col"
											class="px-6 py-3 text-left text-xs font-medium text-gray-500 uppercase tracking-wider">
											Deposite date
										</th>

										<th scope="col"
											class="px-6 py-3 text-left text-xs font-medium text-gray-500 uppercase tracking-wider">
											Evidence file
										</th>

										<th scope="col"
											class="px-6 py-3 text-left text-xs font-medium text-gray-500 uppercase tracking-wider">
											Deposit amount
										</th>

										<th scope="col"
											class="px-6 py-3 text-left text-xs font-medium text-gray-500 uppercase tracking-wider">
											Account destinator
										</th>

										<th scope="col"
											class="px-6 py-3 text-left text-xs font-medium text-gray-500 uppercase tracking-wider">
											Status
										</th>

									</tr>
								</thead>

								<tbody class="bg-white divide-y divide-gray-200">
									<tr>
										<td class="px-6 py-4 whitespace-nowrap text-sm text-gray-500">
											1
										</td>

										<td class="px-6 py-4 whitespace-nowrap text-sm text-gray-500">
											Bizao-Order-14520
										</td>

										<td class="px-6 py-4 whitespace-nowrap text-sm text-gray-500">
											Main account - Mobile money
										</td>

										<td class="px-6 py-4 whitespace-nowrap text-sm text-gray-500">
											03-01-2021
										</td>

										<td class="px-6 py-4 whitespace-nowrap text-sm text-gray-500">
											smallworld2021.pdf
										</td>

										<td class="px-6 py-4 whitespace-nowrap text-sm text-gray-500">
											5,000,000 XOF
										</td>

										<td class="px-6 py-4 whitespace-nowrap text-sm text-gray-500">
											XXXXXXXXXXXXXXX
										</td>

										<td class="px-6 py-4 whitespace-nowrap text-sm text-gray-500">
											<div style="padding-top: 0.1em; padding-bottom: 0.1rem" class="text-xs px-3 bg-yellow-200 text-yellow-800 rounded-full">Badge</div>
										</td>
									</tr>

									<tr>
										<td class="px-6 py-4 whitespace-nowrap text-sm text-gray-500">
											2
										</td>

										<td class="px-6 py-4 whitespace-nowrap text-sm text-gray-500">
											Bizao-Order-14520
										</td>

										<td class="px-6 py-4 whitespace-nowrap text-sm text-gray-500">
											Main account - Mobile money
										</td>

										<td class="px-6 py-4 whitespace-nowrap text-sm text-gray-500">
											03-01-2021
										</td>

										<td class="px-6 py-4 whitespace-nowrap text-sm text-gray-500">
											smallworld2021.pdf
										</td>

										<td class="px-6 py-4 whitespace-nowrap text-sm text-gray-500">
											5,000,000 XOF
										</td>

										<td class="px-6 py-4 whitespace-nowrap text-sm text-gray-500">
											XXXXXXXXXXXXXXX
										</td>

										<td class="px-6 py-4 whitespace-nowrap text-sm text-gray-500">
											<div style="padding-top: 0.1em; padding-bottom: 0.1rem" class="text-xs px-3 bg-blue-200 text-blue-800 rounded-full">Badge</div>
										</td>
									</tr>

								</tbody>
							</table>
						</div>
					</div>
				</div>
			</div>
		</div>
	</div>
</div>
