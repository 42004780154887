<div class="flex flex-col w-4/5" style="min-width: 600px;">
	<div class="my-2 overflow-x-auto sm:-mx-6 lg:-mx-8">
		<div class="py-2 align-middle inline-block min-w-full sm:px-5 lg:px-5 grid grid-cols-2">
			<div class="col-span-2 pb-5 pl-10 border-gray-200 border-b">
				<h6>
					Ajout de nouveau profile
				</h6>
			</div>

			<div class="col-span-2 p-5 grid grid-cols-4 pl-10">

				<div class="col-span-1 gap-1 text-right pr-5 pt-5">
					Nom profile
				</div>

				<div class="col-span-2">
					<input type="text" placeholder="Profile name" [(ngModel)]="profileName"/>
				</div>

				<div class="col-span-4 text-center">
					<button class="bg-yellow-500 text-white font-bold py-2 px-10 rounded-full mt-5" (click)="processRights()">
						Suivant
					</button>
				</div>

			</div>

		</div>
	</div>
</div>
