<nav class="bg-transparent shadow-none">
	<div class="nav-wrapper">
		<div class="col s12">
			<a href="#" class="breadcrumb app-root text-gray-400">App</a>
			<a href="#" class="breadcrumb text-gray-400">Paramétres globales</a>
			<a href="#" class="breadcrumb text-gray-400">Paramétres généraux</a>
			<a href="#" class="breadcrumb text-gray-400">Pays MNO / EME</a>
		</div>
	</div>
</nav>

<div class="content">
	<div class="content-header bg-white rounded m-5 p-0">
		<div class="grid grid-cols-1 shadow-lg p-5">

			<div class="bg-white grid grid-cols-2 border-b-2 border-gray-200 pb-5">
				<h6 class="col-span-1">
					Push
				</h6>

                <div class="col-span-1 float-right content-shadow">
					<a routerLink="/home/push/bulk-transfer"
						class="bg-yellow-500 text-white font-bold py-2 px-4 rounded-full float-right">
						Bulk Transfert
					</a>
				</div>
			</div>

			<!-- This example requires Tailwind CSS v2.0+ -->
			<div class="flex flex-col">
				<div class="my-2 overflow-x-auto sm:-mx-6 lg:-mx-8">
					<div class="py-2 align-middle inline-block min-w-full sm:px-6 lg:px-8 grid grid-cols-6 gap-4">

                        <div class="col-start-2 col-span-4">

                            <div class="grid grid-cols-4 mt-10">
                                <div class="col-span-1 text-right pt-5 pr-10">
                                    <span class="text-gray-700">Indiquer le type de l'opération</span>
                                </div>
                                <div class="col-span-3">
                                    <select name="cars" id="cars" class="block">
                                        <option value="volvo">Wallet deposit</option>
                                    </select>
                                </div>

                                <div class="col-span-1 text-right pt-5 pr-5 mt-10">
                                    <span class="text-gray-700">Sélectionner le fichier</span>
                                </div>
                                <div class="col-span-3 mt-20">
                                    <input type="file" />
                                </div>
                            </div>

                            <div class="col-start-2 col-span-4 mt-20">
                                <div class="grid grid-cols-4">
                                    <div class="col-span-1"></div>
                                    <div class="col-span-1">
                                        <button class="text-yellow-500 bg-transparent border border-solid 
                                            border-yellow-500 hover:bg-yellow-500 hover:text-white 
                                            active:bg-yellow-600 font-bold px-8 py-3 rounded-full 
                                            outline-none focus:outline-none mr-1 mb-1 ease-linear transition-all 
                                            duration-150 float-right mr-10"
                                            type="button">
                                            Annuler
                                        </button>
                                    </div>

                                    <div class="col-span-1">
                                        <a routerLink="/home/push/verify-push"
                                            class="bg-yellow-500 text-white active:bg-yellow-600 font-bold
                                            text-base px-8 py-3 rounded-full shadow-md hover:shadow-lg outline-none 
                                            focus:outline-none mr-1 mb-1 ease-linear transition-all duration-150
                                            inline-block">
                                            Vérifier le fichier
                                        </a>
                                    </div>
                                </div>
                            </div>

                        </div>
					</div>
				</div>
			</div>
		</div>
	</div>
</div>
