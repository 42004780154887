<nav>
	<div class="nav-wrapper">
		<div class="col s12" style="padding-left: 25px;">
			<a href="#" class="breadcrumb app-root text-gray-400 text-breadcrumb">App</a>
			<a href="#" class="breadcrumb text-gray-400 text-breadcrumb">Global settings</a>
			<a href="#" class="breadcrumb text-gray-400 text-breadcrumb">Profils</a>
		</div>
	</div>
</nav>

<div class="pb-10">
	<div class="bg-white rounded m-5 p-0 sm:w-full">
		<div class="grid grid-cols-1 shadow-lg p-5">

			<div class="bg-white grid grid-cols-2 border-b-2 border-gray-200 pb-5">
				<h6 class="col-span-1">
					Liste des profiles
					<span class="text-xs px-2 font-medium bg-yellow-500 bg-opacity-10 text-yellow-800 rounded py-0.5 bgBadge bizao-badge">
						{{ profils?.length }}
					</span>
				</h6>

				<div class="col-span-1 float-right content-shadow">
					<button class="bg-yellow-500 text-white font-bold py-2 px-4 rounded-full float-right" (click)="openDialog()">
						Ajouter un nouveau profil
					</button>
				</div>
			</div>

			<!-- This example requires Tailwind CSS v2.0+ -->
			<div class="flex flex-col">
				<div class=" overflow-x-auto sm:-mx-6 lg:-mx-8">
					<div class="py-2 align-middle inline-block min-w-full sm:px-6 lg:px-8">
						<div class="shadow overflow-hidden border-b border-gray-200 sm:rounded-lg">

							<div class="bg-white rounded-lg shadow lg:w-1/1">
								<ul class="divide-y divide-gray-100">

									<li class="p-3 hover:bg-yellow-500 hover:text-white relative" *ngFor="let profil of profils; let i = index" >
										<div class="w-100">{{ profil.name }}</div>
										<div class="right absolute right-5 top-0">
											<button mat-button [matMenuTriggerFor]="menu" class="text-3xl">...</button>
											<mat-menu #menu="matMenu">
												<button mat-menu-item (click)="openDialog(profil)">Modifier</button>
												<button mat-menu-item (click)="deleteItem(profil, i)">Supprimer</button>
											</mat-menu>
										</div>
									</li>

									<!--
										<li class="p-3 hover:bg-yellow-500 hover:text-white relative">
											Finance initiateur Bizao
											<div class="right absolute right-5 top-0">
												<button mat-button [matMenuTriggerFor]="menu" class="text-3xl">...</button>
												<mat-menu #menu="matMenu">
													<button mat-menu-item>Modifier</button>
													<button mat-menu-item>Supprimer</button>
												</mat-menu>
											</div>
										</li>

										<li class="p-3 hover:bg-yellow-500 hover:text-white relative">
											Finance validateur Bizao
											<div class="right absolute right-5 top-0">
												<button mat-button [matMenuTriggerFor]="menu" class="text-3xl">...</button>
												<mat-menu #menu="matMenu">
													<button mat-menu-item>Modifier</button>
													<button mat-menu-item>Supprimer</button>
												</mat-menu>
											</div>
										</li>

										<li class="p-3 hover:bg-yellow-500 hover:text-white relative">
											Support Bizao
											<div class="right absolute right-5 top-0">
												<button mat-button [matMenuTriggerFor]="menu" class="text-3xl">...</button>
												<mat-menu #menu="matMenu">
													<button mat-menu-item>Modifier</button>
													<button mat-menu-item>Supprimer</button>
												</mat-menu>
											</div>
										</li>
									-->

								</ul>
							</div>

						</div>
					</div>
				</div>
			</div>
		</div>
	</div>
</div>
