<nav>
	<div class="nav-wrapper">
		<div class="col s12" style="padding-left: 25px;">
			<a href="#" class="breadcrumb app-root text-gray-400 text-breadcrumb">App</a>
			<a href="#" class="breadcrumb text-gray-400 text-breadcrumb">{{ 'sidenav.partnership.title' | translate }}</a>
			<a href="#" class="breadcrumb text-gray-400 text-breadcrumb">{{ 'sidenav.partnership.management' | translate }}</a>
		</div>
	</div>
</nav>

<div class="content pb-10">
	<div class="bg-white rounded m-5 p-0">
		<div class="grid grid-cols-1 shadow-lg p-5">

			<div class="bg-white grid grid-cols-2 border-b-2 border-gray-200 pb-5">
				<h6 class="col-span-1">
					Gestion des partenaires 
					<span class="text-xs px-2 font-medium bg-yellow-500 bg-opacity-10 text-yellow-800 rounded py-0.5 bgBadge bizao-badge">
						{{ partners.length }}
					</span>
				</h6>

				<div class="col-span-1 float-right content-shadow">
					<button (click)="openDialog()"
						class="bg-yellow-500 text-white font-bold py-2 px-4 rounded-full float-right">
						<img src="../../../../../assets/icones/Boutton/ajout.svg"  alt="Ajout partenaire" class="img-add-partner"/>
						Ajouter un partenaire
					</button>
				</div>
			</div>

			<div class="flex flex-col">
				<div class="my-2 overflow-x-auto sm:-mx-6 lg:-mx-8">
					<div class="py-2 align-middle inline-block min-w-full sm:px-6 lg:px-8">
						<div class="shadow overflow-hidden border-b border-gray-200 sm:rounded-lg">

							<table class="min-w-full divide-y divide-gray-200">
								<thead class="bg-gray-50">
									<tr>
										<th scope="col"
											class="px-6 py-3 text-left text-xs font-medium text-gray-500 uppercase tracking-wider">
											#
										</th>

										<th scope="col"
											class="px-6 py-3 text-left text-xs font-medium text-gray-500 uppercase tracking-wider">
											Nom du partenaire
										</th>

										<th scope="col"
											class="px-6 py-3 text-left text-xs font-medium text-gray-500 uppercase tracking-wider">
											Offre
										</th>

										<th scope="col"
											class="px-6 py-3 text-left text-xs font-medium text-gray-500 uppercase tracking-wider">
											CallBack Url
										</th>

										<th scope="col"
											class="px-6 py-3 text-left text-xs font-medium text-gray-500 uppercase tracking-wider">
											Cancel url
										</th>

										<th scope="col"
											class="px-6 py-3 text-left text-xs font-medium text-gray-500 uppercase tracking-wider">
											Failure url
										</th>

										<th scope="col"
											class="px-6 py-3 text-left text-xs font-medium text-gray-500 uppercase tracking-wider">
											Action
										</th>

										<th scope="col" class="relative px-6 py-3">
											<span class="sr-only">...</span>
										</th>
									</tr>
								</thead>

								<tbody class="bg-white divide-y divide-gray-200">
									<tr  *ngFor="let partner of partners | paginate: { itemsPerPage: 10, currentPage: p }; let i = index;">
										<td class="px-6 py-4 whitespace-nowrap text-sm text-gray-500">
											{{ i + 1 }}
										</td>

										<td class="px-6 py-4 whitespace-nowrap text-sm text-black-500">
											{{ partner.getName() }}
										</td>

										<td class="px-6 py-4 whitespace-nowrap text-sm text-black-500">
											{{ partner.getOffers() }}
										</td>

										<td class="px-6 py-4 whitespace-nowrap text-sm text-black-500">
											{{ partner.getCallbackUrl() }}
										</td>

										<td class="px-6 py-4 whitespace-nowrap text-sm text-black-500">
											{{ partner.getCancelUrl() }}
										</td>

										<td class="px-6 py-4 whitespace-nowrap text-sm text-black-500">
											{{ partner.getFailureUrl() }}
										</td>

										<td class="px-6 py-4 whitespace-nowrap text-gray-500 text-3xl">
											<button mat-button [matMenuTriggerFor]="menu" class="action-button text-3xl">...</button>
											<mat-menu #menu="matMenu">
												<button mat-menu-item (click)="openUpdatePartner(partner.getId())">Modifier</button>
												<button mat-menu-item (click)="openDeletePartnerModal(partner.getId())">Supprimer</button>
											</mat-menu>
										</td>
									</tr>

								</tbody>
							</table>

							<div wire:loading class="top-0 right-0 mr-2 mt-2" *ngIf="loader">
								<svg class="animate-spin h-10 w-10 m-auto rounded-full bg-transparent border-2 border-transparent border-opacity-50 border-loader-color" viewBox="0 0 24 24"></svg>
							</div>

							<pagination-controls
								(pageChange)="p = $event"
								responsive="true"
								previousLabel="Précédent"
								nextLabel="Suivant"></pagination-controls>

						</div>
					</div>
				</div>
			</div>

		</div>
	</div>
</div>
