import { Component, OnInit } from '@angular/core';

@Component({
	selector: 'cancel-button',
	templateUrl: 'cancel-button.component.html',
})

export class CancelButtonComponent implements OnInit{
    ngOnInit(): void {
        throw new Error('Method not implemented.');
    }

}
