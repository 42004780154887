export class UserPartner {
  id?: number;
  name: string;
  type: string;
  contact: string;

  constructor(id: number, type: string, name: string, contact: string){
    this.id = id;
    this.name = name;
    this.type = type;
    this.contact = contact;
  }

  getId(): number{
    return this.id;
  }

}
