<div class="content pb-5">
	<div class="content-header bg-white p-0">
		<div class="grid grid-cols-1">

			<div class="bg-white grid grid-cols-2 border-b border-gray-200 pb-5">
				<h6 class="col-span-1">
					Order de reversement
				</h6>
			</div>

			<!-- This example requires Tailwind CSS v2.0+ -->
			<div class="flex flex-col">
				<div class="my-2 overflow-x-auto sm:-mx-6 lg:-mx-8">
					<div class="py-2 align-middle inline-block min-w-full sm:px-6 lg:px-8 grid grid-cols-1 ">

                        <div class="col-span-1 px-10">
                            <div class="grid grid-cols-4 mt-5">

                                <div class="col-span-4 grid grid-cols-2 py-5">
                                    <div class="col-span-1">
                                        <strong> Montant HT </strong> <br>
                                        90 000 FCFA  
                                    </div>

                                    <div class="col-span-1">
                                        <strong> Taux de commission appliqué </strong><br>
                                        2%
                                    </div>
                                </div>

								<div class="col-span-4 grid grid-cols-2 py-5">
                                    <div class="col-span-1">
                                        <strong> Commission HT </strong> <br>
                                        1 800 FCFA  
                                    </div>

                                    <div class="col-span-1">
                                        <strong> TVA sur commission (18%)</strong><br>
                                        324 F CFA
                                    </div>
                                </div>

								<div class="col-span-4 grid grid-cols-2 py-5">
                                    <div class="col-span-1">
                                        <strong> Commission total TTC </strong> <br>
                                        2 124 FCFA  
                                    </div>

                                    <div class="col-span-1">
                                        <strong> Montant Net à virer </strong><br>
                                        88 148 F CFA
                                    </div>
                                </div>

								<div class="col-span-4 border-t border-gray-200 pt-5 mt-5">
                                    <div class="col-span-1">
                                        Vous êtes sur le point de soumettre une demande de reversement
                                    </div>
                                </div>

                            </div>

                            <div class="col-start-2 col-span-4 mt-5">
                                <div class="grid grid-cols-4">
                                    <div class="col-span-1"></div>
                                    <div class="col-span-1">
                                        <button class="text-yellow-500 bg-transparent border border-solid 
                                            border-yellow-500 hover:bg-yellow-500 hover:text-white 
                                            active:bg-yellow-600 font-bold px-8 py-3 rounded-full 
                                            outline-none focus:outline-none mr-1 mb-1 ease-linear transition-all 
                                            duration-150 float-right mr-10"
                                            type="button">
                                            Précédent
                                        </button>
                                    </div>

                                    <div class="col-span-1">
                                        <button 
                                            class="bg-yellow-500 text-white active:bg-yellow-600 font-bold
                                            text-base px-8 py-3 rounded-full shadow-md hover:shadow-lg outline-none 
                                            focus:outline-none mr-1 mb-1 ease-linear transition-all duration-150
                                            inline-block">
                                            Suivant
                                        </button>
                                    </div>
                                </div>
                            </div>

                        </div>

					</div>
				</div>
			</div>

		</div>
	</div>
</div>
