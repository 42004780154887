import { NgModule } from '@angular/core';
import { RouterModule } from '@angular/router';
import { ReactiveFormsModule } from '@angular/forms';
import { CommonModule } from '@angular/common';
import { HttpClient } from '@angular/common/http';

import { ClientServiceRoutingModule } from './client-service-rooting.module';
import { ClientServiceComponent } from './client-service.component';
import { AddRequestComponent } from './add-request/add-request.component';


// import ngx-translate and the http loader
import { TranslateLoader, TranslateModule } from '@ngx-translate/core';
import { TranslateHttpLoader } from '@ngx-translate/http-loader';
import { MatMenuModule } from '@angular/material/menu';


@NgModule({
    imports: [
        CommonModule,
        ReactiveFormsModule,
        ClientServiceRoutingModule,
        TranslateModule.forRoot({
			loader: {
				provide: TranslateLoader,
				useFactory: HttpLoaderFactory,
				deps: [HttpClient],
			},
		}),
        MatMenuModule,
        RouterModule
    ],
    declarations: [
        ClientServiceComponent,
        AddRequestComponent
    ]
})
export class ClientServiceModule { }

// required for AOT compilation
export function HttpLoaderFactory(http: HttpClient): TranslateHttpLoader {
	return new TranslateHttpLoader(http);
}
