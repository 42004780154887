<div class="grid grid-cols-1">

    <div class="bg-white grid grid-cols-1 border-b-2 border-gray-200 px-5 py-5">
        <h6 class="col-span-1 text-center">
            Est-ce que vous voulez vraiment supprimer ce profil ?
        </h6>
    </div>

    <div class="flex flex-col">
        <div class="my-2 overflow-x-auto sm:-mx-6 lg:-mx-8">
            <div class="py-2 align-middle inline-block min-w-full sm:px-6 lg:px-8 grid grid-cols-6 gap-4">

                <div class="col-start-2 col-span-4">

                    <div class="grid grid-cols-1">

                        <div class="py-2 align-middle inline-block min-w-full sm:px-6 lg:px-8 grid grid-cols-2 mt-5">

                            <div class="col-span-1">
                                <button class="text-yellow-500 bg-transparent border border-solid 
                                    border-yellow-500 hover:bg-yellow-500 hover:text-white 
                                    active:bg-yellow-600 font-bold px-8 py-3 rounded-full 
                                    outline-none focus:outline-none mr-1 mb-1 ease-linear transition-all 
                                    duration-150 float-right" mat-dialog-close
                                    type="button" (click)="dismissDialog()">
                                    NON
                                </button>
                            </div>

                            <div class="col-span-1">
                                <button
                                    class="bg-yellow-500 text-white active:bg-yellow-600 font-bold
                                    text-base px-8 py-3 rounded-full shadow-md hover:shadow-lg outline-none 
                                    focus:outline-none mr-1 mb-1 ease-linear transition-all duration-150
                                    inline-block float-left" cdkFocusInitial (click)="processDelete()">
								    <svg class="animate-spin h-4 w-4 m-auto rounded-full bg-transparent border-2 border-transparent border-opacity-50 border-loader-color inline mr-5" viewBox="0 0 24 24" *ngIf="deleteLoader"></svg>
                                    OUI
                                </button>
                            </div>
                        </div>

                    </div>

                </div>
            </div>
        </div>
    </div>

</div>
