import { Component } from '@angular/core';

@Component({
	selector: 'modal',
	templateUrl: 'modal.component.html',
})

export class ModalComponent {

}
