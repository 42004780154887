import { AfterViewInit, Component, OnInit } from '@angular/core';
import { TransactionService } from '@app/_services';
import { Transaction } from '@app/_models';
import { ModalDetails } from './modal-details/details.component';
import { MatDialog } from '@angular/material/dialog';
import { saveAs } from 'file-saver';

import * as XLSX from 'xlsx';
const EXCEL_TYPE = 'application/vnd.openxmlformats-officedocument.spreadsheetml.sheet;charset=UTF-8';
const EXCEL_EXTENSION = '.xlsx';

@Component({
	selector: 'app-transactions-details',
	templateUrl: './transactions-details.component.html',
	styleUrls: ['./transactions-details.component.css']
})

export class TransactionsDetailsComponent implements OnInit, AfterViewInit {

	p: number = 1;
	transactions: Transaction[] = [];
	transactionsToExport = [];

	constructor(
		private transactionService: TransactionService,
		public dialog: MatDialog
	) { }

	ngAfterViewInit(): void {
		this.transactionService.transactionsSubject.subscribe(
			(transactions: any[]) => {
				this.transactions = transactions;
			}
		);
		this.transactionService.emitTransactions();
	}

	ngOnInit(): void {}

	viewTransactionDetails(id) {
		const transaction = this.transactions.filter(t => t.id === id);
		const dialogRef = this.dialog.open(ModalDetails);
		dialogRef.componentInstance.transaction = transaction[0];
	}

	downloadExcel() {
		this.transactionsToExport = [];
		this.transactions.forEach(transaction => {
			this.transactionsToExport.push({
				'Order ID': transaction.orderId,
				'Eervice Provider': transaction.serviceProvider,
				'MNO Name': transaction.mnoName,
				'Country Code': transaction.countryCode,
				'Language Code': transaction.languageCode,
				'Channel': transaction.channel,
				'Type': transaction.type,
				'Sender': transaction.sender.firstName + transaction.sender.lastName,
				'fromCountryCode': transaction.fromCountryCode,
				'Beneficiary': transaction.beneficiary.firstName + transaction.beneficiary.lastName,
				'To Country Code': transaction.toCountryCode,
				'Currency Code': transaction.currencyCode || 'Bank',
				'Merchant Reference': transaction.merchantReference,
				'State': transaction.state,
				'Created At': transaction.createdAt,
				'Amount': transaction.amount,
				'Status Code': transaction.statusCode,
				'Status Description': transaction.statusDescription,
				'Operator Transaction Id': transaction.operatorTransactionId
			});
		});

		this.exportAsExcelFile(this.transactionsToExport, 'export-excel');
	}

	public exportAsExcelFile(json: any[], excelFileName: string): void {
		const worksheet: XLSX.WorkSheet = XLSX.utils.json_to_sheet(this.transactionsToExport);
		const workbook: XLSX.WorkBook = { Sheets: { 'data': worksheet }, SheetNames: ['data'] };
		const excelBuffer: any = XLSX.write(workbook, { bookType: 'xlsx', type: 'array' });
		this.saveAsExcelFile(excelBuffer, excelFileName);
	}

	private saveAsExcelFile(buffer: any, fileName: string): void {
		const data: Blob = new Blob([buffer], { type: EXCEL_TYPE });
		saveAs(data, fileName + '_export_' + new Date().getTime() + EXCEL_EXTENSION);
	}
}
