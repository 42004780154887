import { Component } from '@angular/core';
import { Router } from '@angular/router';

@Component({
	selector: 'step-2',
	templateUrl: './step-2.component.html'
})

export class StepFlow2 {

    constructor(private router: Router) {}

}
