export class Partner {

    private partnerId: string;
    private partnerName: string;
    private partnerType: string;
    private partnerContact: string;
    private partnerCallbackURL: string;
    private partnerCancelURL: string;
    private partnerFailureURL: string;
    private partnerOperatingCountries: any[];
    private offers: string;

    constructor() { }

    setId(id: string) {
        this.partnerId = id;
    }

    getId() {
        return this.partnerId;
    }

    setType(type: string) {
        this.partnerType = type;
    }

    setName(name: string) {
        this.partnerName = name;
    }

    setContact(contact: string) {
        this.partnerContact = contact;
    }

    setCallbackUrl(callback_url: string) {
        this.partnerCallbackURL = callback_url;
    }

    setCancelUrl(cancel_url: string) {
        this.partnerCancelURL = cancel_url;
    }

    setFailureUrl(failure_url: string) {
        this.partnerFailureURL = failure_url;
    }

    setOperatingCountries(operating_countries: any[]) {
        this.partnerOperatingCountries = operating_countries;
    }

    getOperatingCountries() {
        return this.partnerOperatingCountries;
    }

    setOffers(offers: string) {
        this.offers = offers;
    }

    getType() {
        return this.partnerType;
    }

    getName() {
        return this.partnerName;
    }

    getContact() {
        return this.partnerContact;
    }

    getCallbackUrl() {
        return this.partnerCallbackURL;
    }

    getCancelUrl() {
        return this.partnerCancelURL;
    }

    getFailureUrl() {
        return this.partnerFailureURL;
    }

    getOffers() {
        return this.offers;
    }

}
