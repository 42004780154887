import { Component } from '@angular/core';
import { Router } from '@angular/router';

@Component({
	selector: 'add-fees',
	templateUrl: './add-fees.component.html'
})

export class AddFees {

    constructor(private router: Router) {}

}
