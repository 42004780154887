<nav *ngIf="user && selectedService">
	<div class="nav-wrapper">
		<a href="#" class="brand-logo">
			<img class="responsive-img" src="../../../assets/images/Logo.svg" alt="" />
		</a>
		<ul id="nav-mobile" class="right">

			<li class="hidden md:block lg:block xl:block 2xl:block">
				<div class="ui dropdown button button-balance" suiDropdown [isOpen]="false">
					<div class="text">
						<img class="responsive-img" src="../../../assets/images//Solde.svg" alt="" />
						<!-- {{ 'headerMenu.balance' | translate }}
						<span class="balance">100 &nbsp;000 &nbsp;XOF</span> -->
						<span class="balance">
							{{ services[selectedService] }}
						</span>
					</div>
					<i class="dropdown icon"></i>
					<div class="menu" suiDropdownMenu>
						<div class="item" (click)="selectService('dcb')">DCB</div>
						<div class="item" (click)="selectService('mobile-money')">Mobile money</div>
						<div class="item" (click)="selectService('bulk')">Paiement en masse</div>
						<div class="item" (click)="selectService('mobile-transfer')">Transfert d'argent</div>
					</div>
				</div>
			</li>

			<li>
				<div class="ui dropdown button button-profile p-0" suiDropdown [isOpen]="false">
					<div class="text">
						<!--  <img
							class="responsive-img circle"
							src="../../../assets/images/JR.jpeg"
							alt=""
						/> -->
						{{ user.firstName }}
					</div>
					<i class="dropdown icon"></i>
					<div class="menu" suiDropdownMenu>
						<div class="item"><button (click)="logout()" class="logout_button">Déconnexion</button></div>
					</div>
				</div>
			</li>

<<<<<<< HEAD
			<!--li>
=======
			<li>
>>>>>>> 1301549... Fix advanced filter on dashboard
				<div class="ui dropdown button button-profile p-0" suiDropdown [isOpen]="false">
					<div class="text">
						{{ language }}
					</div>
					<i class="dropdown icon"></i>
					<div class="menu" suiDropdownMenu>
						<div class="item" (click)="useLanguage('en')">
							<img class="responsive-im lang-img" width="5" src="../../../assets/images/us-flag.jpg"
								alt="" />
						</div>
						<div class="item" (click)="useLanguage('fr')">
							<img class="responsive-img lang-img" src="../../../assets/images/france.png" alt="" />
						</div>
					</div>
				</div>
			</li-->

			<li>
				<div class="rounded xl:hidden flex justify-between w-full p-6 items-center border-b border-gray-200 p-0
					mt">
					<div aria-label="toggler" class="flex justify-center items-center">
						<button id="open" aria-label="open" (click)="sidebarHandler()" class="focus:outline-none ">
							<svg [ngClass]="'text-gray-800 ' + (menuService.getStatus() ? 'hidden' : 'block')"
								width="24" height="24" viewBox="0 0 24 24" fill="none"
								xmlns="http://www.w3.org/2000/svg">
								<path d="M4 6H20" stroke="currentColor" stroke-width="1.5" stroke-linecap="round"
									stroke-linejoin="round" />
								<path d="M4 12H20" stroke="currentColor" stroke-width="1.5" stroke-linecap="round"
									stroke-linejoin="round" />
								<path d="M4 18H20" stroke="currentColor" stroke-width="1.5" stroke-linecap="round"
									stroke-linejoin="round" />
							</svg>
						</button>
						<button *ngIf="menuService.getStatus() === true" aria-label="close"
							(click)="sidebarHandler(false)" class=" focus:outline-none ">
							<svg class="text-gray-800" width="24" height="24" viewBox="0 0 24 24" fill="none"
								xmlns="http://www.w3.org/2000/svg">
								<path d="M18 6L6 18" stroke="currentColor" stroke-width="1.5" stroke-linecap="round"
									stroke-linejoin="round" />
								<path d="M6 6L18 18" stroke="currentColor" stroke-width="1.5" stroke-linecap="round"
									stroke-linejoin="round" />
							</svg>
						</button>
					</div>
				</div>

			</li>

		</ul>
	</div>
</nav>
