<div style="background: linear-gradient(90deg,#f6951b,#eb031b)" class="p-10">

</div>
<div class="side-toolbar">

	<aside class="flex flex-col items-left bg-white text-gray-700 shadow h-full">

		<div [ngClass]="status ? 'transform translate-x-0 rounded ease-in-out transition 
			duration-500 flex justify-start items-start w-full sm:w-64 border-b border-gray-200
			flex-col h-full' :'-translate-x-full rounded  ease-in-out transition duration-500 flex justify-start
			items-start w-full sm:w-64 border-b border-gray-200 flex-col h-full transform'">

			<ul class="mt-10 w-full right md:hidden lg:hidden xl:hidden 2xl:hidden">
				<li>
					<div class="ui dropdown button button-balance" suiDropdown [isOpen]="false">
						<div class="text">
							<img class="responsive-img" src="../../../assets/images//Solde.svg" alt="" />
							<!-- {{ 'headerMenu.balance' | translate }}
							<span class="balance">100 &nbsp;000 &nbsp;XOF</span> -->
							<span class="balance">
								{{ services[selectedService] }}
							</span>
						</div>
						<i class="dropdown icon"></i>
						<div class="menu" suiDropdownMenu>
							<div class="item" (click)="selectService('dcb')">DCB</div>
							<div class="item" (click)="selectService('mobile-money')">Mobile money</div>
							<div class="item" (click)="selectService('bulk')">Paiement en masse</div>
							<div class="item" (click)="selectService('mobile-transfer')">Transfert d'argent</div>
						</div>
					</div>
				</li>
			</ul>


			<!-- Start of Dashboard section -->
				<ul class="mt-10 w-full">
					<li>
						<a class="main-title inactiveLink px-6 mt-10">
							{{ 'sidenav.dashboard.title' | translate }}
						</a>
					</li>

					<li class="hover:bg-gray-100">
						<a routerLink="/home" class="h-10 px-6 flex flex text-left items-center w-full focus:text-orange-500">
							<img class="responsive-img nav-item-avatar col-span-1" src="../../../../assets/images/Global.svg" alt="" />
							<span class="text-gray-400">
								{{ 'sidenav.dashboard.global' | translate }}
							</span>
						</a>
					</li>

					<li class="hover:bg-gray-100">
						<a routerLink="/home/traffic" class="h-10 px-6 flex flex text-left items-center w-full focus:text-orange-500">
							<img class="responsive-img nav-item-avatar col-span-1" src="../../../../assets/images/Traffic.svg" alt="" />
							<span class="text-gray-400">
								{{ 'sidenav.dashboard.traffic' | translate }}
							</span>
						</a>
					</li>

					<li class="hover:bg-gray-100">
						<a routerLink="/home/revenue" class="h-10 px-6 flex flex text-left items-center w-full focus:text-orange-500">
							<img class="responsive-img nav-item-avatar col-span-1" src="../../../../assets/images/Revenue.svg" alt="" />
							<span class="text-gray-400">
								{{ 'sidenav.dashboard.revenue' | translate }}
							</span>
						</a>
					</li>

					<li class="hover:bg-gray-100">
						<a routerLink="/home/transaction-details" class="h-10 px-6 flex flex text-left items-center w-full focus:text-orange-500">
							<img class="responsive-img nav-item-avatar col-span-1" src="../../../../assets/images/Transactions_Details.svg" alt="" />
							<span class="text-gray-400">
								{{ 'sidenav.dashboard.transacDetails' | translate }}
							</span>
						</a>
					</li>


				</ul>
			<!-- End of Dashboard section -->

			<!-- Start of Paramétrage global section -->
				<ul class="mt-10 w-full">
					<li>
						<a class="main-title inactiveLink px-6 mt-10">
							{{ 'sidenav.globalSettings.title' | translate }}
						</a>
					</li>

					<!-- Items Section -->
					<li class="">
						<a routerLink="/home/profils/" class="h-10 px-6 flex flex text-left items-center w-full focus:text-orange-500">
							<img class="responsive-img nav-item-avatar"	src="../../../../assets/images/Paramétrage_Généraux.svg" alt="" />
							<span class="text-gray-400">
								Profils
							</span>
						</a>
					</li>

					<!-- Items Section -->
					
				</ul>
			<!-- End of Paramétrage global section -->

			<!-- Start of Utilisateurs de l'outil section -->
				<ul class="mt-10 w-full">
					<li>
						<a class="main-title inactiveLink px-6 mt-10">
							{{ 'sidenav.users.title' | translate }}
						</a>
					</li>
					<!-- Items Section -->
					<li class="hover:bg-gray-100">
						<a routerLink="/home/user" class="h-10 px-6 flex flex text-left items-center w-full focus:text-orange-500">
							<img class="responsive-img nav-item-avatar" src="../../../../assets/images/Gestion_Des_Utilisateurs.svg" alt="" />
							<span class="text-gray-400">
								{{ 'sidenav.users.management' | translate }}
							</span>
						</a>
					</li>
				</ul>
			<!-- End of Utilisateurs de l'outil section -->

			<!-- Start of Partenaires section -->
				<ul class="mt-10 w-full">
					<li>
						<a class="main-title inactiveLink px-6 mt-10">
							{{ 'sidenav.users.title' | translate }}
						</a>
					</li>
					<!-- Items Section -->
					<li class="hover:bg-gray-100">
						<a routerLink="/home/partners-management" class="h-10 px-6 flex flex text-left items-center w-full focus:text-orange-500">
							<img class="responsive-img nav-item-avatar" src="../../../../assets/images/Gestion_Des_Partenaires.svg" alt="" />
							<span class="text-gray-400">
								{{ 'sidenav.partnership.management' | translate }}
							</span>
						</a>
					</li>

				</ul>
			<!-- End of Partenaires section -->

		</div>
	</aside>
</div>
