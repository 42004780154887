import { Component, OnInit } from '@angular/core';
import { MatDialog } from '@angular/material/dialog';
import { VirementComponent } from './ordre-virement/virement.component';

@Component({
	selector: 'app-repayment',
	templateUrl: './repayment.component.html',
	styleUrls: ['./repayment.component.css']
})

export class RepaymentComponent implements OnInit {
	sectionTitle = "Dashboard";
	sectionItem = "Traffic";
	sectionLeftTitle = "Traffic";
	sectionRightTitle = "To be defined";

	constructor(public dialog: MatDialog) {}

	ngOnInit(): void {}

	openDialog() {
		const dialogRef = this.dialog.open(VirementComponent);

		dialogRef.afterClosed().subscribe(result => {
		  	console.log(`Dialog result: ${result}`);
		});
	}

}
