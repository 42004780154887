import { Component, OnInit } from '@angular/core';

@Component({
	selector: 'mno',
	templateUrl: './mno.component.html',
	styleUrls: ['./mno.component.css'],
})
export class MnoComponent implements OnInit {
	constructor() {}

	ngOnInit(): void {}
}
