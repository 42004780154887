import { NgModule } from '@angular/core';
import { RouterModule } from '@angular/router';
import { ReactiveFormsModule, FormsModule } from '@angular/forms';
import { CommonModule } from '@angular/common';
import { HttpClient } from '@angular/common/http';

import { MatMenuModule } from '@angular/material/menu';
import { MatButtonModule } from '@angular/material/button';

import { ProfilsRoutingModule } from './profils-rooting.module';

import { ProfilComponent } from './profil.component';
import { AdminNameModalComponent } from './admin-name-modal/admin-name-modal.component';
import { ProfilConfigComponent } from './profile-config/profil-config.component';
import { ProfileNameModalComponent } from './profile-config/profile-name-modal/profile-name-modal.component';

// import ngx-translate and the http loader
import { TranslateLoader, TranslateModule } from '@ngx-translate/core';
import { TranslateHttpLoader } from '@ngx-translate/http-loader';

@NgModule({
    imports: [
        CommonModule,
        ReactiveFormsModule,
        FormsModule,
        ProfilsRoutingModule,
        MatMenuModule,
        MatButtonModule,
        TranslateModule.forRoot({
			loader: {
				provide: TranslateLoader,
				useFactory: HttpLoaderFactory,
				deps: [HttpClient],
			},
		}),
        RouterModule
    ],
    declarations: [
        ProfilComponent,
        AdminNameModalComponent,
        ProfilConfigComponent,
        ProfileNameModalComponent
    ]
})
export class ProfilsModule { }

// required for AOT compilation
export function HttpLoaderFactory(http: HttpClient): TranslateHttpLoader {
	return new TranslateHttpLoader(http);
}
