<nav>
	<div class="nav-wrapper">
		<div class="col s12" style="padding-left: 25px;">
			<a href="#" class="breadcrumb app-root text-gray-400 text-breadcrumb">App</a>
			<a href="#" class="breadcrumb text-gray-400 text-breadcrumb">Global settings</a>
			<a href="#" class="breadcrumb text-gray-400 text-breadcrumb">Profils</a>
		</div>
	</div>
</nav>

<div class="pb-10">
	<div class="bg-white rounded m-5 p-0 sm:w-full">
		<div class="grid grid-cols-1 shadow-lg p-5">

			<div class="bg-white grid grid-cols-1 border-b border-gray-200 pb-5">
				<h6 class="col-span-1">
					Profile name :
					<span class="text-xs px-2 font-medium bg-yellow-500 bg-opacity-10 text-yellow-800 rounded py-0.5 bgBadge bizao-badge">
						{{ menus.length }}
					</span>
				</h6>
			</div>

			<!-- This example requires Tailwind CSS v2.0+ -->
			<div class="flex flex-col">
				<div class=" overflow-x-auto sm:-mx-6 lg:-mx-8">
					<div class="py-2 align-middle inline-block min-w-full sm:px-6 lg:px-8">
						<div class="overflow-hidden">

							<div class="bg-white lg:w-1/1">
								<ul class="">

									<li class="p-3 bg-gray-100 relative cursor-pointer font-extrabold">
										<span class="mr-5"> # </span> Profiles
									</li>

									<li *ngFor="let menu of menus; let index = index;" class="p-3 hover:bg-yellow-400 hover:text-white relative cursor-pointer" (click)='openDialog(menu)'>
										<span class="mr-5"> {{ index + 1 }} </span> {{ menu.label }}
									</li>

								</ul>
							</div>

						</div>
					</div>

					<div class="col-span-4 py-2 align-middle inline-block min-w-full sm:px-6 lg:px-8 mt-10 text-center">
						<button type="submit" class="bg-yellow-500 text-white active:bg-yellow-600 font-bold
						text-base px-8 py-3 rounded-full shadow-md hover:shadow-lg outline-none
						focus:outline-none mr-1 mb-1 ease-linear transition-all duration-150
						inline-block inline" cdkFocusInitial  (click)="saveRights()">
							Valider
						</button>
					</div>
				</div>
			</div>
		</div>
	</div>
</div>
