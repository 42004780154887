import { Component, OnInit } from '@angular/core';

@Component({
	selector: 'app-commission-config',
	templateUrl: './commission-config.component.html',
	styleUrls: ['./commission-config.component.css']
})

export class CommissionConfig implements OnInit {
	constructor() {}

	ngOnInit(): void {}

}
