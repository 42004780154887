<nav>
	<div class="nav-wrapper">
		<div class="col s12">
			<a href="#" class="breadcrumb app-root">App</a>
			<a href="#" class="breadcrumb">{{ 'sidenav.dashboard.title' | translate }}</a>
			<a href="#" class="breadcrumb">Conversion</a>
		</div>
	</div>
</nav>

<div class="content pb-10">
	<div class="content-header bg-white rounded m-5 p-0">
		<div class="grid grid-cols-2 shadow-lg">
			<div class="col-span-1 bg-white border-r p-10">
				<div>
					<span class="spinner-border spinner-border-lg align-center"></span>
				</div>
                <div class="row-span-1 grid grid-cols-1 justify-center items-center">
					<div class="card-amount text-left pl-5">
                        <h6 class="title text-yellow-500">
                            Revenu
                        </h6>

                        <h5 class="amount text-4xl">
                            {{ amountSum }}<span *ngIf="isMoney()" class="text-xl">{{ currency }}</span>
                        </h5>

                        <span class="description">
                            Total
                        </span>
                    </div>
				</div>

                <div class="row-span-1 grid grid-cols-2 justify-center items-center pt-5 pl-5">
                    <div class="col-span-1 grid grid-rows-1 pt-10">
                        <h6 class="title text-yellow-500">
                            Traffic
                        </h6>
                        <h5 class="amount text-3xl">
                            {{ transactions.length }}<span *ngIf="isMoney()" class="text-xl">{{ currency }}</span>
                        </h5>
    
                        <span class="description">
                            Total
                        </span>
                    </div>

                    <div class="col-span-1 grid grid-rows-1 pt-10">
                        <h5 class="amount text-3xl">
                            {{ successPourcentage }}
                        </h5>
    
                        <span class="description">
                            Success
                        </span>
                    </div>
				</div>
			</div>

			<div class="col-span-1 grid grid-rows-2 pt-10 pb-10">
				<div class="row-span-1 grid grid-cols-1 justify-center items-center">
					<div class="card-amount text-left pl-5">
                        <h6 class="title text-yellow-500 text-1">
                            Unique user
                        </h6>

                        <h5 class="amount text-4xl">
                            1 043 006<span *ngIf="isMoney()" class="text-xl">{{ currency }}</span>
                        </h5>

                        <span class="description">
                            Total
                        </span>
                    </div>
                    
				</div>

				<div class="row-span-1 grid grid-cols-2 justify-center items-center pt-5 pl-5">
                    <div class="col-span-1 grid grid-rows-1 pt-10">
                        <h5 class="amount text-3xl">
                            1 043 006<span *ngIf="isMoney()" class="text-xl">{{ currency }}</span>
                        </h5>
    
                        <span class="description">
                            Success
                        </span>
                    </div>

                    <div class="col-span-1 grid grid-rows-1 pt-10">
                        <h5 class="amount text-3xl">
                            8.98 %
                        </h5>
    
                        <span class="description">
                            Conversion
                        </span>
                    </div>
				</div>
			</div>
		</div>
	</div>

	<div class="content-header bg-white rounded mx-5 mb-10 shadow-xl">
		<div class="grid grid-cols-1 gap-2 p-5">
			<app-transaction-table ></app-transaction-table><table class="min-w-full divide-y divide-gray-200">
				<thead class="bg-gray-50">
					<tr>
						<th scope="col"
							class="px-6 py-3 text-left text-xs font-medium text-gray-500 uppercase tracking-wider">
							#
						</th>

						<th scope="col"
							class="px-6 py-3 text-left text-xs font-medium text-gray-500 uppercase tracking-wider">
							Timestamp
						</th>

						<th scope="col"
							class="px-6 py-3 text-left text-xs font-medium text-gray-500 uppercase tracking-wider">
							FromCountry
						</th>

						<th scope="col"
							class="px-6 py-3 text-left text-xs font-medium text-gray-500 uppercase tracking-wider">
							ToCountry
						</th>

						<th scope="col"
							class="px-6 py-3 text-left text-xs font-medium text-gray-500 uppercase tracking-wider">
							Operator
						</th>

						<th scope="col"
							class="px-6 py-3 text-left text-xs font-medium text-gray-500 uppercase tracking-wider">
							Order ID
						</th>

						<th scope="col"
							class="px-6 py-3 text-left text-xs font-medium text-gray-500 uppercase tracking-wider">
							Merchant Reference
						</th>

						<th scope="col"
							class="px-6 py-3 text-left text-xs font-medium text-gray-500 uppercase tracking-wider">
							Amount
						</th>

						<th scope="col"
							class="px-6 py-3 text-left text-xs font-medium text-gray-500 uppercase tracking-wider">
							Statut
						</th>

						<th scope="col" class="px-6 py-3 text-left text-xs font-medium text-gray-500 uppercase tracking-wider">
							
						</th>
					</tr>
				</thead>

				<tbody class="bg-white divide-y divide-gray-200">
					<!-- *ngFor="let transaction of transactions" --> 
					<tr *ngFor="let transaction of transactions | paginate: { itemsPerPage: 10, currentPage: p }; let index = index;">
						<td class="px-6 py-4 whitespace-nowrap text-sm text-gray-500">
							{{ index + 1 }}
						</td>

						<td class="px-6 py-4 whitespace-nowrap text-sm text-gray-500">
							{{ transaction.createdAt | date:'dd-MM-yyyy HH:mm:ss' }}
						</td>

						<td class="px-6 py-4 whitespace-nowrap text-sm text-gray-500">
							{{ transaction.fromCountryCode }}
						</td>

						<td class="px-6 py-4 whitespace-nowrap text-sm text-gray-500">
							{{ transaction.toCountryCode }}
						</td>

						<td class="px-6 py-4 whitespace-nowrap text-sm text-gray-500">
							{{ transaction.operator ? transaction.operator : '-' }}
						</td>

						<td class="px-6 py-4 whitespace-nowrap text-sm text-gray-500">
							{{ transaction.orderId }}
						</td>

						<td class="px-6 py-4 whitespace-nowrap text-sm text-gray-500">
							{{ transaction.merchantReference }}
						</td>

						<td class="px-6 py-4 whitespace-nowrap text-sm text-gray-500">
							{{ transaction.amount }}
						</td>

						<td class="px-6 py-4 whitespace-nowrap text-sm text-gray-500">
							<div *ngIf="transaction.status == 'inprogress'" style="padding-top: 0.1em; padding-bottom: 0.1rem" class="text-xs px-3 bg-yellow-200 text-yellow-800 rounded-full inline-block">
								In progress
							</div>

							<div *ngIf="transaction.status == 'Failed'" style="padding-top: 0.1em; padding-bottom: 0.1rem" class="text-xs px-3 bg-red-200 text-red-800 rounded-full inline-block">
								Failed
							</div>

							<div *ngIf="transaction.status == 'Successful'" style="padding-top: 0.1em; padding-bottom: 0.1rem" class="text-xs px-3 bg-green-200 text-green-800 rounded-full inline-block">
								Success
							</div>
						</td>

						<td class="px-6 py-4 whitespace-nowrap text-3xl text-gray-500 cursor-pointer" (click)='viewTransactionDetails(transaction.id)'>
							...
						</td>
					</tr>

					<!-- More people... -->
				</tbody>
			</table>

			<pagination-controls (pageChange)="p = $event"></pagination-controls>
		</div>
	</div>

</div>
