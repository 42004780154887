<nav class="bg-transparent shadow-none">
	<div class="nav-wrapper">
		<div class="col s12" style="padding-left: 25px;">
			<a href="#" class="breadcrumb app-root text-gray-400 text-breadcrumb">App</a>
			<a href="#" class="breadcrumb text-gray-400 text-breadcrumb">Paramétres globales</a>
			<a href="#" class="breadcrumb text-gray-400 text-breadcrumb">Paramétres généraux</a>
			<a href="#" class="breadcrumb text-gray-400 text-breadcrumb">Devises</a>
		</div>
	</div>
</nav>

<div class="content pb-10">
	<div class="content-header bg-white rounded m-5 p-0">

		<div class="grid grid-cols-1 shadow-lg p-5">

			<div class="bg-white grid grid-cols-2 border-b border-gray-200 pb-5">
				<h6 class="col-span-1">
					Informations administratives
				</h6>
			</div>

			<div class="flex flex-col">
				<div class="my-2 overflow-x-auto sm:-mx-6 lg:-mx-8">
					<div class="py-2 align-middle inline-block min-w-full sm:px-6 lg:px-8 grid grid-cols-4">

						<div class="col-start-2 col-span-2 pt-5">

							<div class="grid grid-cols-4">

								<div class="col-span-1 text-right pt-5 pr-5 mt-5">
									<span class="text-gray-700">Nom du partenaire</span>
								</div>
								<div class="col-span-3 mt-5">
									<input type = "text" placeholder="Nom du partenaire" [(ngModel)]="partnerName"/>
								</div>

								<div class="col-span-1 text-right pt-5 pr-5 mt-5">
									<span class="text-gray-700">Type de partenaire</span>
								</div>
								<div class="col-span-3 mt-5">
									<select name="partnerType" id="partnerType" class="block" [(ngModel)]="partnerType">
										<option value="irt">IRT</option>
										<option value="merchand">Merchand</option>
										<option value="service-provider">Service provider</option>
									</select>
								</div>

								<div class="col-span-1 text-right pt-5 pr-5 mt-5">
									<span class="text-gray-700">Personne à contacter</span>
								</div>
								<div class="col-span-3 mt-5">
									<input type = "text" placeholder="Personne à contacter" [(ngModel)]="partnerContact"/>
								</div>

							</div>
						</div>

					</div>

				</div>
			</div>
		</div>

		<div class="grid grid-cols-1 shadow-lg p-5">

			<div class="bg-white grid grid-cols-2 border-b border-gray-200 pb-5">
				<h6 class="col-span-1">
					Informations de configuration
				</h6>
			</div>

			<div class="flex flex-col">
				<div class="my-2 overflow-x-auto sm:-mx-6 lg:-mx-8">
					<div class="py-2 align-middle inline-block min-w-full sm:px-6 lg:px-8 grid grid-cols-4">

						<div class="col-start-2 col-span-2 pt-5">

							<div class="grid grid-cols-4">

								<div class="col-span-1 text-right pt-5 pr-5 mt-5">
									<span class="text-gray-700">Offre</span>
								</div>
								<div class="col-span-3 mt-5">
									<select name="offer" id="offer" class="block" [(ngModel)]="offer">
										<option value="dcb">DCB</option>
										<option value="mobile-money">Mobile money</option>
										<option value="money-transfert">Tranfert d'argent</option>
										<option value="bulk">Bulk</option>
									</select>
								</div>

								<div class="col-span-1 text-right pt-5 pr-5 mt-5">
									<span class="text-gray-700">Callback url</span>
								</div>
								<div class="col-span-3 mt-5">
									<input type = "text" placeholder="Callback url" [(ngModel)]="callBackUrl"/>
								</div>

								<div class="col-span-1 text-right pt-5 pr-5 mt-5">
									<span class="text-gray-700">Cancel url</span>
								</div>
								<div class="col-span-3 mt-5">
									<input type = "text" placeholder="Cancel url" [(ngModel)]="cancelUrl"/>
								</div>

								<div class="col-span-1 text-right pt-5 pr-5 mt-5">
									<span class="text-gray-700">Failure url</span>
								</div>
								<div class="col-span-3 mt-5">
									<input type = "text" placeholder="Failure url" [(ngModel)]="failureUrl"/>
								</div>

							</div>
						</div>

					</div>

				</div>
			</div>
		</div>

		<div class="grid grid-cols-1 shadow-lg p-5">

			<div class="bg-white grid grid-cols-2 pb-5">
				<h6 class="col-span-1">
					MNO/EME autorisés
				</h6>
			</div>

			<div class="flex flex-col">
				<div class="my-2 overflow-x-auto sm:-mx-6 lg:-mx-8">
					<div class="py-2 align-middle inline-block min-w-full sm:px-6 lg:px-8 grid grid-cols-3">

                        <div class="col-span-1 p-5" *ngFor="let country of operating_countries; let i = index">
							<span class="text-xs px-2 font-medium bg-gray-500 bg-opacity-10 text-gray-800 rounded 
								inline-block w-full py-3">
								{{ country.name }}
							</span>
							<div class="flex text-left">
								<div class="bg-white rounded-lg w-full">
									<ul class="">
										<li class="p-2 hover:bg-gray-50 cursor-pointer" *ngFor="let mno of country.mno; let i = index">
											{{ mno.name }}
											<div class="float-right relative inline-block w-10 mr-2 align-middle select-none transition duration-200 ease-in">
												<ui-switch [(ngModel)]="mno.selected" disabled="true"></ui-switch>
											</div>
										</li>
									</ul>
								</div>
							</div>
                        </div>
					
					</div>

					<div class="py-2 align-middle inline-block min-w-full sm:px-6 lg:px-8 grid grid-cols-1">
						<div class="col-span-1 grid grid-cols-3">
							<button class="bg-yellow-500 text-white active:bg-yellow-600 font-bold
								text-base px-8 py-3 rounded-full shadow-md hover:shadow-lg outline-none 
								focus:outline-none mr-1 mb-1 ease-linear transition-all duration-150
								inline-block float-left col-start-2 col-span-1" (click)='validatePartner()' *ngIf="!currentPartner.getId()">
								<svg class="animate-spin h-4 w-4 m-auto rounded-full bg-transparent border-2 border-transparent border-opacity-50 border-loader-color inline mr-5" viewBox="0 0 24 24" *ngIf="addPartnerLoader"></svg>
								Valider
							</button>

							<button class="bg-yellow-500 text-white active:bg-yellow-600 font-bold
								text-base px-8 py-3 rounded-full shadow-md hover:shadow-lg outline-none 
								focus:outline-none mr-1 mb-1 ease-linear transition-all duration-150
								inline-block float-left col-start-2 col-span-1" (click)='validateUpdatePartner()' *ngIf="currentPartner.getId()">
								<svg class="animate-spin h-4 w-4 m-auto rounded-full bg-transparent border-2 border-transparent border-opacity-50 border-loader-color inline mr-5" viewBox="0 0 24 24" *ngIf="addPartnerLoader"></svg>
								Modifier
							</button>
						</div>
					</div>

				</div>
			</div>
		</div>
	</div>
</div>
