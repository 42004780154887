<nav class="bg-transparent shadow-none">
	<div class="nav-wrapper">
		<div class="col s12">
			<a href="#" class="breadcrumb app-root text-gray-400">App</a>
			<a href="#" class="breadcrumb text-gray-400">Paramétres globales</a>
			<a href="#" class="breadcrumb text-gray-400">Paramétres généraux</a>
			<a href="#" class="breadcrumb text-gray-400">Pays MNO / EME</a>
		</div>
	</div>
</nav>

<div class="content">
	<div class="content-header bg-white rounded m-5 p-0">
		<div class="grid grid-cols-1 shadow-lg p-5">

			<div class="bg-white grid grid-cols-2 border-b-2 border-gray-200 pb-5">
				<h6 class="col-span-1">
					Currency configuration
				</h6>
			</div>

			<!-- This example requires Tailwind CSS v2.0+ -->
			<div class="flex flex-col">
				<div class="my-2 overflow-x-auto sm:-mx-6 lg:-mx-8">
					<div class="py-2 align-middle inline-block min-w-full sm:px-6 lg:px-8 grid grid-cols-6 gap-4">

                        <div class="col-start-2 col-span-4">

                            <div class="grid grid-cols-4">
                                <div class="col-span-1 text-right pt-5 pr-5">
                                    <span class="text-gray-700">Pays</span>
                                </div>
                                <div class="col-span-3">
                                    <select name="cars" id="cars" class="block">
                                        <option value="volvo">Senegal</option>
                                        <option value="saab">Cote d'Ivoire</option>
                                        <option value="mercedes">Mali</option>
                                        <option value="audi">Tunisie</option>
                                    </select>
                                </div>

                                <div class="col-span-1 text-right pt-5 pr-5 mt-5">
                                    <span class="text-gray-700">Continent</span>
                                </div>
                                <div class="col-span-3 mt-5">
                                    <select name="cars" id="cars" class="block">
                                        <option value="volvo">Afrique</option>
                                        <option value="saab">Asie</option>
                                        <option value="mercedes">Amérique</option>
                                        <option value="audi">Europe</option>
                                        <option value="audi">Océanie</option>
                                    </select>
                                </div>

                            </div>
                        </div>
					</div>
				</div>
			</div>
		</div>
	</div>
</div>



<div class="content pb-10">
	<div class="content-header bg-white rounded m-5 p-0">
		<div class="grid grid-cols-1 shadow-lg p-5">

			<div class="bg-white grid grid-cols-2 border-b-2 border-gray-200 pb-5">
				<h6 class="col-span-1">
					List MNO / EME
				</h6>

				<div class="col-span-1 float-right content-shadow">
					<button (click)="openDialog()"
						class="bg-yellow-500 text-white font-bold py-2 px-4 rounded-full float-right">
						New MNO/EME
                </button>
				</div>
			</div>

			<!-- This example requires Tailwind CSS v2.0+ -->
			<div class="flex flex-col">
				<div class="my-2 overflow-x-auto sm:-mx-6 lg:-mx-8">
					<div class="py-2 align-middle inline-block min-w-full sm:px-6 lg:px-8">

                        <!-- This example requires Tailwind CSS v2.0+ -->
                        <div class="flex flex-col">
                            <div class="my-2 overflow-x-auto sm:-mx-6 lg:-mx-8">
                                <div class="py-2 align-middle inline-block min-w-full sm:px-6 lg:px-8">
                                    <div class="shadow overflow-hidden border-b border-gray-200 sm:rounded-lg">
                                        <table class="min-w-full divide-y divide-gray-200">
                                            <thead class="bg-gray-50">
                                                <tr>
                                                    <th scope="col"
                                                        class="px-6 py-3 text-left text-xs font-medium text-gray-500 uppercase tracking-wider">
                                                        #
                                                    </th>
                    
                                                    <th scope="col"
                                                        class="px-6 py-3 text-left text-xs font-medium text-gray-500 uppercase tracking-wider">
                                                        MNO / EME
                                                    </th>
                    
                                                    <th scope="col"
                                                        class="px-6 py-3 text-left text-xs font-medium text-gray-500 uppercase tracking-wider">
                                                        Begin active date
                                                    </th>
                    
                                                    <th scope="col"
                                                        class="px-6 py-3 text-left text-xs font-medium text-gray-500 uppercase tracking-wider">
                                                        End active date
                                                    </th>

                                                    <th scope="col"
                                                        class="px-6 py-3 text-left text-xs font-medium text-gray-500 uppercase tracking-wider">
                                                        Action
                                                    </th>
                                                </tr>
                                            </thead>

                                            <tbody class="bg-white divide-y divide-gray-200">
                                                <tr>
                                                    <td class="px-6 py-4 whitespace-nowrap text-sm text-gray-500">
                                                        1
                                                    </td>
                    
                                                    <td class="px-6 py-4 whitespace-nowrap text-sm text-gray-500">
                                                        MTN
                                                    </td>
                    
                                                    <td class="px-6 py-4 whitespace-nowrap text-sm text-gray-500">
                                                        04-16-2021
                                                    </td>
                    
                                                    <td class="px-6 py-4 whitespace-nowrap text-sm text-gray-500">
                                                        Undefined
                                                    </td>
                    
                                                    <td class="px-6 py-4 whitespace-nowrap text-sm text-gray-500">
                                                        ...
                                                    </td>
                                                </tr>
                                                <!-- More people... -->
                                            </tbody>
                                        </table>
                                    </div>
                                </div>
                            </div>
                        </div>

                        <div class="col-start-2 col-span-4">
                            <div class="grid grid-cols-4">
                                <div class="col-span-1"></div>
                                <div class="col-span-1">
                                    <button class="text-yellow-500 bg-transparent border border-solid 
                                        border-yellow-500 hover:bg-yellow-500 hover:text-white 
                                        active:bg-yellow-600 font-bold px-8 py-3 rounded-full 
                                        outline-none focus:outline-none mr-1 mb-1 ease-linear transition-all 
                                        duration-150 float-right mr-10"
                                        type="button">
                                        Annuler
                                    </button>
                                </div>

                                <div class="col-span-1">
                                    <a routerLink="/home/general-settings/mno"
                                        class="bg-yellow-500 text-white active:bg-yellow-600 font-bold
                                        text-base px-8 py-3 rounded-full shadow-md hover:shadow-lg outline-none 
                                        focus:outline-none mr-1 mb-1 ease-linear transition-all duration-150
                                        inline-block">
                                        Suivant
                                    </a>
                                </div>
                            </div>
                        </div>
					</div>
				</div>
			</div>
		</div>
	</div>
</div>
