import { NgModule } from '@angular/core';
import { Routes, RouterModule } from '@angular/router';
import { HomeComponent } from './components/home/home.component';
import { AccountsStatementsComponent } from './components/main-wrapper/content-container/accounts-statements/accounts-statements.component';
import { DashboardGlobalComponent } from './components/main-wrapper/content-container/dashboard/dashboard-global/dashboard-global.component';
import { PartnersManagementComponent } from './components/main-wrapper/content-container/partners-management/partners-management.component';
import { InformationsComponent } from './components/main-wrapper/content-container/partners-management/informations/informations.component';

import { PartnersProvisionningComponent } from './components/main-wrapper/content-container/partners-provisionning/partners-provisionning.component';
import { PushComponent } from './components/main-wrapper/content-container/push/push.component';
import { RevenueComponent } from './components/main-wrapper/content-container/dashboard/revenue/revenue.component';
import { TrafficComponent } from './components/main-wrapper/content-container/dashboard/traffic/traffic.component';
import { TransactionsDetailsComponent } from './components/main-wrapper/content-container/dashboard/transactions-details/transactions-details.component';
import { AccountToAccountTransferComponent } from './components/main-wrapper/content-container/account-to-account-transfer/account-to-account-transfer.component';
import { ConversionComponent } from './components/main-wrapper/content-container/dashboard/conversion/conversion.component';

// import { ProfilComponent } from './components/main-wrapper/content-container/global-settings/profils/profil.component';


const generalSettingsModule = () => import('./components/main-wrapper/content-container/global-settings/general-settings/general-setting-rooting.module').then(x => x.GeneralSettingRoutingModule);
const partnerSettings = () => import('./components/main-wrapper/content-container/partners-settings/partner-setting-rooting.module').then(x => x.PartnerSettingsRoutingModule);
const disbursementRoutingModule = () => import('./components/main-wrapper/content-container/disbursement/disbursement-rooting.module').then(x => x.DisbursementRoutingModule);
const orderEmissionRoutingModule = () => import('./components/main-wrapper/content-container/order-emission/order-emission-rooting.module').then(x => x.OrderEmissionRoutingModule);
const clientServiceRoutingModule = () => import('./components/main-wrapper/content-container/client-service/client-service-rooting.module').then(x => x.ClientServiceRoutingModule);
const profilsRoutingModule = () => import('./components/main-wrapper/content-container/global-settings/profils/profils-rooting.module').then(x => x.ProfilsRoutingModule);
const userManagementRoutingModule = () => import('./components/main-wrapper/content-container/user-management/user-management-rooting.module').then(x => x.UserManagementRoutingModule);

import { AuthGuard } from './_helpers';

const routes: Routes = [
	// Tunning
	{
		path:'home', component:HomeComponent, // canActivate : [AuthGuard],
		children : [
			{ path:'', component:DashboardGlobalComponent, canActivate : [AuthGuard] },
			{ path:'transaction-details', component:TransactionsDetailsComponent, canActivate : [AuthGuard] },
			{ path:'revenue', component:RevenueComponent, canActivate : [AuthGuard] },
			{ path:'traffic', component:TrafficComponent, canActivate : [AuthGuard] },
			{ path:'profils', loadChildren:profilsRoutingModule, canActivate : [AuthGuard] },
			{ path:'general-settings', loadChildren:generalSettingsModule, canActivate : [AuthGuard] },
			{ path:'partners-management', component:PartnersManagementComponent, canActivate : [AuthGuard] },
			{ path:'partners-management/recap', component:InformationsComponent, canActivate : [AuthGuard] },
			{ path:'partners-provisionning', component:PartnersProvisionningComponent, canActivate : [AuthGuard] },
			{ path:'account-statements', component:AccountsStatementsComponent, canActivate : [AuthGuard] },
			// {path:'push', component:PushComponent},
			{ path:'push', loadChildren:disbursementRoutingModule, canActivate : [AuthGuard] },
			// {path:'partners-settings', component:PartnersSettingsComponent}
			{ path:'partners-settings', loadChildren:partnerSettings, canActivate : [AuthGuard] },
			{ path:'account-to-account-transfert', component: AccountToAccountTransferComponent, canActivate : [AuthGuard] },
			{ path:'order-emission', loadChildren: orderEmissionRoutingModule, canActivate : [AuthGuard] },
			{ path:'request', loadChildren: clientServiceRoutingModule, canActivate : [AuthGuard] },
			{ path:'conversion', component: ConversionComponent, canActivate : [AuthGuard] },
			{ path:'user', loadChildren: userManagementRoutingModule }
		],
	},
	{ path:'', redirectTo: 'home', pathMatch : 'full' }
];

@NgModule({
	imports: [RouterModule.forRoot(routes)],
	exports: [RouterModule]
})
export class AppRoutingModule { }
