import { AfterViewInit, Component, OnInit } from '@angular/core';
import { PartnerService } from '@app/_services';
import { Router } from '@angular/router';
import { AlertService } from '@app/_services'
import { Partner } from '@app/_models';
import { PartnerUtils } from '@app/utils';

@Component({
	selector: 'informations',
	templateUrl: './informations.component.html',
	styleUrls: ['./informations.component.css'],
})

export class InformationsComponent implements OnInit {
	offer: string;
	callBackUrl: string;
	cancelUrl: string;
	failureUrl: string;

	partnerName: string;
	partnerType: string;
	partnerContact: string;
	operating_countries: any[];

	currentPartner: Partner;
	addPartnerLoader: boolean = false;

	partners: Partner[];

	constructor(private partnerService: PartnerService, private router: Router, private alertService: AlertService) {}

	ngOnInit(): void {

		this.partnerService.partnersSubject.subscribe(
			(partners: any[]) => {
				this.partners = new PartnerUtils().arrayToPartner(partners);
            });
		this.partnerService.emitPartners();

		this.callBackUrl = this.partnerService.getCurrentPartner().getCallbackUrl();
		this.cancelUrl = this.partnerService.getCurrentPartner().getCancelUrl();
		this.failureUrl = this.partnerService.getCurrentPartner().getFailureUrl();

		this.partnerName = this.partnerService.getCurrentPartner().getName();
		this.partnerType = this.partnerService.getCurrentPartner().getType();
		this.partnerContact = this.partnerService.getCurrentPartner().getContact();
		this.offer = this.partnerService.getCurrentPartner().getOffers();

		this.operating_countries = this.partnerService.getCurrentPartner().getOperatingCountries();
		this.currentPartner = this.partnerService.getCurrentPartner();
	}

	validatePartner() {
		this.addPartnerLoader = true;
		this.partnerService.create(this.partnerService.getCurrentPartner())
		.subscribe({
			next: partnerCreated => {
				this.addPartnerLoader = false;
				this.partnerService.initPartnerToAdd();
				if (partnerCreated['partnerId']) {
					this.alertService.success('Success', 'Partenaire créé avec succès !', { keepAfterRouteChange: true });
					this.partnerService.emitUpdatedPartners(partnerCreated);
					this.router.navigate(['/home/partners-management/']);
				} else {
					this.alertService.error('Error', 'Erreur survenu lors de l\'ajout');
				}
			},
			error: error => {
				console.log('error : ', error);
				this.alertService.error('Error', error);
			}
		});
	}

	validateUpdatePartner() {
		this.addPartnerLoader = true;
		this.partnerService.updatePartner(this.partnerService.getCurrentPartner())
		.subscribe({
			next: partnerUpdated => {
				this.addPartnerLoader = false;
				this.partnerService.initPartnerToAdd();
				if (partnerUpdated['partnerId']) {
					this.alertService.success('Success', 'Partenaire modifié avec succès !', { keepAfterRouteChange: true });
					this.partnerService.emitUpdatedPartners(partnerUpdated);
					this.router.navigate(['/home/partners-management/']);
				} else {
					this.alertService.error('Error', 'Erreur survenu lors de la modification');
				}
			},
			error: error => {
				console.log('error : ', error);
				this.alertService.error('Error', error);
			}
		});
	}
}
