import { Component, OnInit } from '@angular/core';

@Component({
	selector: 'app-disbursement',
	templateUrl: './disbursement.component.html',
	styleUrls: ['./disbursement.component.css']
})
export class DisbursementComponent implements OnInit {

	constructor() { }

	ngOnInit(): void {}

}
