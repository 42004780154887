<div>
	<div class="w-full sm:grid sm:grid-cols-7 lg:grid lg:grid-cols-7 xl:grid xl:grid-cols-7 2xl:grid 2xl:grid-cols-7">

		<div *ngIf="user && selectedService"
			[ngClass]="status ? 'overflow-hidden sm:col-span-6 md:col-span-2 lg:col-span-1 xl:col-span-1 2xl:col-span-1
				none-padding transform translate-x-0 rounded ease-in-out transition duration-500 flex 
				justify-start items-start w-full sm:w-64 border-b border-gray-200 flex-col h-full block' :
				'overflow-hidden hidden -translate-x-full rounded  ease-in-out transition duration-500 flex justify-start 
				items-start w-full sm:w-64 border-b border-gray-200 flex-col h-full transform'">
			<app-sidenav></app-sidenav>
		</div>

		<div [ngClass]="user && selectedService ? 'none-padding' : 'none-padding'"
			[ngClass]="status && user && selectedService ? 'sm:col-span-6 md:col-span-5 lg:col-span-6 xl:col-span-6 2xl:col-span-6 
			transform rounded ease-in-out transition duration-500 col s12 m9 l10 xl10 
			none-padding' : 
			'none-padding sm:col-span-7 md:col-span-7 lg:col-span-7 xl:col-span-7 rounded ease-in-out transition duration-500 transform'">
			<alert></alert>
			<app-content-container></app-content-container>
		</div>

	</div>
</div>
