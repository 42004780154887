import { Component, Input } from '@angular/core';
import { FormGroup } from '@angular/forms';
import { MatDialog } from '@angular/material/dialog';
import { PartnerService, AlertService } from '@app/_services';
import { Partner } from '@app/_models';
import { Router } from '@angular/router';

@Component({
    selector: 'delete-alert',
    templateUrl: './delete.component.html',
    styleUrls: ['./delete.component.css']
})

export class DeleteAlert {

    form: FormGroup;
    loading = false;
    submitted = false;
    @Input() parnerId: number;
    deleteLoader: boolean = false;
    partners: Partner[];

    constructor(
        private partnerService: PartnerService,
        private dialog: MatDialog,
        private alertService: AlertService,
        private router: Router
    ) { }

    ngOnInit() {
        this.partnerService.partnersSubject.subscribe(
            (partners: any[]) => {
                this.partners = partners;
            }
        );
        this.partnerService.emitPartners();
    }

    dismissDialog() {
        this.dialog.closeAll();
    }

    processDelete() {
        this.deleteLoader = true;
        this.partnerService.delete(this.parnerId)
            .subscribe(() => {
                this.deleteLoader = false;
                this.alertService.success('Success', 'Partenaire supprimé avec succès', { keepAfterRouteChange: true });
                this.dialog.closeAll();
                this.partnerService.emitPartnersAfterDeletd(this.parnerId);
            },
            error => {
                this.deleteLoader = false;
                this.alertService.error('Error', 'Erreur de suppression : ' + error, { keepAfterRouteChange: true });
                this.dialog.closeAll();
                this.loading = false;
            });
    }
}
