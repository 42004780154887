import { Component, Input, OnInit } from '@angular/core';
import { MatDialog } from '@angular/material/dialog';
import { Router, ActivatedRoute } from '@angular/router';
import { Profil } from '@app/_models';
import { ProfilService } from '@app/_services';

@Component({
	selector: 'app-admin-name-modal',
	templateUrl: './admin-name-modal.component.html',
	styleUrls: ['./admin-name-modal.component.css']
})

export class AdminNameModalComponent implements OnInit {

	@Input() profil: Profil;
	profileName: string;
	loading = false;
	submitted = false;
	constructor(
		public dialog: MatDialog,
		private router: Router,
		private route: ActivatedRoute,
		private profilService: ProfilService) {}

	ngOnInit(): void {
		this.profileName = this.profil?.name;
	}

	processRights() {
		if(this.profil?.name && (this.profil?.name != this.profileName))
			this.updateProfil();

		if(this.profil?.name == this.profileName)
			this.closeDialog();

		if(!this.profil)
		  this.addProfil()
	}

	addProfil(){
		this.profilService.addProfile(this.profileName).subscribe(resp => {
			if(resp['status'] == 200){
				this.closeDialog();
			}
		});
	}

	updateProfil(){
		this.profilService.updateProfile(this.profil.name, this.profileName).subscribe(resp => {
			if(resp['status'] == 200){
				this.closeDialog();
			}
		});
	}

	closeDialog(){
		this.dialog.closeAll();
		this.router.navigate(['/home/profils/config'], { queryParams: {"profile": this.profileName} });
	}

	openProfilePage() {
		this.router.navigateByUrl('');
	}

}
