<nav class="bg-transparent shadow-none">
	<div class="nav-wrapper">
		<div class="col s12">
			<a href="#" class="breadcrumb app-root text-gray-400">App</a>
			<a href="#" class="breadcrumb text-gray-400">Paramétres globales</a>
			<a href="#" class="breadcrumb text-gray-400">Paramétres généraux</a>
			<a href="#" class="breadcrumb text-gray-400">Pays MNO / EME</a>
		</div>
	</div>
</nav>

<div class="content pb-10">
	<div class="content-header bg-white rounded m-5 p-0">
		<div class="grid grid-cols-1 shadow-lg p-5">

			<div class="bg-white grid grid-cols-2 border-b-2 border-gray-200 pb-5">
				<h6 class="col-span-1">
					Currency configuration
				</h6>

				<div class="col-span-1 float-right content-shadow">
					<a routerLink="/home/general-settings/mno/add"
						class="bg-yellow-500 text-white font-bold py-2 px-4 rounded-full float-right">
						New Country
					</a>
				</div>
			</div>

			<div class="flex flex-col">
				<div class="my-2 overflow-x-auto sm:-mx-6 lg:-mx-8">
					<div class="py-2 align-middle inline-block min-w-full sm:px-6 lg:px-8">
						<div class="shadow overflow-hidden border-b border-gray-200 sm:rounded-lg">
							<table class="min-w-full divide-y divide-gray-200">
								<thead class="bg-gray-50">
									<tr>
										<th scope="col"
											class="px-6 py-3 text-left text-xs font-medium text-gray-500 uppercase tracking-wider">
											#
										</th>
		
										<th scope="col"
											class="px-6 py-3 text-left text-xs font-medium text-gray-500 uppercase tracking-wider">
											Continent
										</th>
		
										<th scope="col"
											class="px-6 py-3 text-left text-xs font-medium text-gray-500 uppercase tracking-wider">
											Country
										</th>
		
										<th scope="col"
											class="px-6 py-3 text-left text-xs font-medium text-gray-500 uppercase tracking-wider">
											MNO/EME
										</th>

										<th scope="col"
											class="px-6 py-3 text-left text-xs font-medium text-gray-500 uppercase tracking-wider">
											Begin active date
										</th>
		
										<th scope="col" class="relative px-6 py-3">
											<span class="sr-only">...</span>
										</th>
									</tr>
								</thead>

								<tbody class="bg-white divide-y divide-gray-200">
									<tr>
										<td class="px-6 py-4 whitespace-nowrap text-sm text-gray-500">
											1
										</td>
		
										<td class="px-6 py-4 whitespace-nowrap text-sm text-gray-500">
											Africa
										</td>
		
										<td class="px-6 py-4 whitespace-nowrap text-sm text-gray-500">
											Senegal
										</td>
		
										<td class="px-6 py-4 whitespace-nowrap text-sm text-gray-500">
											Free
										</td>
		
										<td class="px-6 py-4 whitespace-nowrap text-sm text-gray-500">
											04-15-2021
										</td>

										<td class="px-6 py-4 whitespace-nowrap text-sm text-gray-500">
											Undefined
										</td>
		
										<td class="px-6 py-4 whitespace-nowrap text-sm text-gray-500">
											...
										</td>
									</tr>
		
									<tr>
										<td class="px-6 py-4 whitespace-nowrap text-sm text-gray-500">
											2
										</td>
		
										<td class="px-6 py-4 whitespace-nowrap text-sm text-gray-500">
											Africa
										</td>
		
										<td class="px-6 py-4 whitespace-nowrap text-sm text-gray-500">
											Senegal
										</td>
		
										<td class="px-6 py-4 whitespace-nowrap text-sm text-gray-500">
											Free
										</td>
		
										<td class="px-6 py-4 whitespace-nowrap text-sm text-gray-500">
											04-15-2021
										</td>

										<td class="px-6 py-4 whitespace-nowrap text-sm text-gray-500">
											Undefined
										</td>
		
										<td class="px-6 py-4 whitespace-nowrap text-sm text-gray-500">
											...
										</td>
									</tr>
		
									<tr>
										<td class="px-6 py-4 whitespace-nowrap text-sm text-gray-500">
											3
										</td>
		
										<td class="px-6 py-4 whitespace-nowrap text-sm text-gray-500">
											Africa
										</td>
		
										<td class="px-6 py-4 whitespace-nowrap text-sm text-gray-500">
											Senegal
										</td>
		
										<td class="px-6 py-4 whitespace-nowrap text-sm text-gray-500">
											Free
										</td>
		
										<td class="px-6 py-4 whitespace-nowrap text-sm text-gray-500">
											04-15-2021
										</td>

										<td class="px-6 py-4 whitespace-nowrap text-sm text-gray-500">
											Undefined
										</td>
		
										<td class="px-6 py-4 whitespace-nowrap text-sm text-gray-500">
											...
										</td>
									</tr>
		
									<tr>
										<td class="px-6 py-4 whitespace-nowrap text-sm text-gray-500">
											4
										</td>
		
										<td class="px-6 py-4 whitespace-nowrap text-sm text-gray-500">
											Africa
										</td>
		
										<td class="px-6 py-4 whitespace-nowrap text-sm text-gray-500">
											Senegal
										</td>
		
										<td class="px-6 py-4 whitespace-nowrap text-sm text-gray-500">
											Free
										</td>
		
										<td class="px-6 py-4 whitespace-nowrap text-sm text-gray-500">
											04-15-2021
										</td>

										<td class="px-6 py-4 whitespace-nowrap text-sm text-gray-500">
											Undefined
										</td>
		
										<td class="px-6 py-4 whitespace-nowrap text-sm text-gray-500">
											...
										</td>
									</tr>

									<tr>
										<td class="px-6 py-4 whitespace-nowrap text-sm text-gray-500">
											5
										</td>
		
										<td class="px-6 py-4 whitespace-nowrap text-sm text-gray-500">
											Africa
										</td>
		
										<td class="px-6 py-4 whitespace-nowrap text-sm text-gray-500">
											Senegal
										</td>
		
										<td class="px-6 py-4 whitespace-nowrap text-sm text-gray-500">
											Free
										</td>
		
										<td class="px-6 py-4 whitespace-nowrap text-sm text-gray-500">
											04-15-2021
										</td>

										<td class="px-6 py-4 whitespace-nowrap text-sm text-gray-500">
											Undefined
										</td>
		
										<td class="px-6 py-4 whitespace-nowrap text-sm text-gray-500">
											...
										</td>
									</tr>
									<!-- More people... -->
								</tbody>
							</table>
						</div>
					</div>
				</div>
			</div>
		</div>
	</div>
</div>
