<nav>
	<div class="nav-wrapper">
		<div class="col s12">
			<a href="#" class="breadcrumb app-root">App</a>
			<a href="#" class="breadcrumb">{{ 'sidenav.globalSettings.title' | translate }}</a>
			<a href="#" class="breadcrumb">{{ 'sidenav.globalSettings.genSettings' | translate }}</a>
		</div>
	</div>
</nav>

<div class="content">
	<div class="content-header z-depth-1">
		<p class="left">{{ 'sidenav.globalSettings.genSettings' | translate }}</p>
		<p class="right"><img class="responsive-img" src="../../../../../assets/images/Transfert_En_Masse.svg" alt=""></p>
	</div>
</div>
