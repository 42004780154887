<nav>
	<div class="nav-wrapper grid grid-cols-3">
		<div class="px-10 col-span-3 xl:col-span-1 2xl:col-span-1 lg:col-span-1 md:col-span-3 sm:col-span-3" style="padding-left: 25px;">
			<a href="#" class="breadcrumb app-root text-breadcrumb">App</a>
			<a href="#" class="breadcrumb text-breadcrumb">{{ 'sidenav.dashboard.title' | translate }}</a>
			<a href="#" class="breadcrumb text-breadcrumb">{{ 'sidenav.dashboard.transacDetails' | translate }}</a>
		</div>

		<div class="col-span-3 xl:col-span-2 2xl:col-span-2 lg:col-span-2 md:col-span-3 sm:col-span-3">
			<app-filter></app-filter>
		</div>

	</div>
</nav>

<div class="content pb-10">
	<div class="bg-white rounded m-5 p-0 sm:w-full w-full">
		<div class="pr-3 grid grid-cols-3 shadow-lg w-full">

			<div class="col-span-3 grid grid-cols-3 w-full p-3">
				<div class="col-span-2">
					<h6>
						Détails des transactions
					</h6>
				</div>

				<div class="col-span-1">
					<button
						class="bg-white text-black active:bg-white font-bold
						text-base px-5 py-3 rounded-full shadow-md hover:shadow-lg outline-none
						focus:outline-none mr-1 mb-1 ease-linear transition-all duration-150
						inline-block inline btn-filter float-right" cdkFocusInitial (click)='downloadExcel()'>
						<img src="../../../../../../../assets/icones/Boutton/exporter.svg" alt="Filter icone" class="inline mr-2"/>
						Export Excel
					</button>
				</div>
			</div>

			<table class="col-span-3 min-w-full divide-y divide-gray-200 w-full">
				<thead class="bg-gray-50">
					<tr>
						<th scope="col"
							class="px-6 py-3 text-left text-xs font-medium text-gray-500 uppercase tracking-wider">
							#
						</th>

						<th scope="col"
							class="px-6 py-3 text-left text-xs font-medium text-gray-500 uppercase tracking-wider">
							Timestamp
						</th>

						<th scope="col"
							class="px-6 py-3 text-left text-xs font-medium text-gray-500 uppercase tracking-wider">
							FromCountry
						</th>

						<th scope="col"
							class="px-6 py-3 text-left text-xs font-medium text-gray-500 uppercase tracking-wider">
							ToCountry
						</th>

						<th scope="col"
							class="px-6 py-3 text-left text-xs font-medium text-gray-500 uppercase tracking-wider">
							Operator
						</th>

						<th scope="col"
							class="px-6 py-3 text-left text-xs font-medium text-gray-500 uppercase tracking-wider">
							Order ID
						</th>

						<th scope="col"
							class="px-6 py-3 text-left text-xs font-medium text-gray-500 uppercase tracking-wider">
							Merchant Reference
						</th>

						<th scope="col"
							class="px-6 py-3 text-left text-xs font-medium text-gray-500 uppercase tracking-wider">
							Amount
						</th>

						<th scope="col"
							class="px-6 py-3 text-left text-xs font-medium text-gray-500 uppercase tracking-wider">
							Statut
						</th>

						<th scope="col" class="px-6 py-3 text-left text-xs font-medium text-gray-500 uppercase tracking-wider">

						</th>
					</tr>
				</thead>

				<tbody class="bg-white divide-y divide-gray-200">
					<!-- *ngFor="let transaction of transactions" -->
					<tr *ngFor="let transaction of transactions | paginate: { itemsPerPage: 10, currentPage: p }; let index = index;">
						<td class="px-6 py-4 whitespace-nowrap text-sm text-gray-500">
							{{ index + 1 }}
						</td>

						<td class="px-6 py-4 whitespace-nowrap text-sm text-gray-500">
							{{ transaction.createdAt | date:'dd-MM-yyyy HH:mm:ss' }}
						</td>

						<td class="px-6 py-4 whitespace-nowrap text-sm text-gray-500">
							{{ transactionService.country[transaction.fromCountryCode.toUpperCase()] }}
						</td>

						<td class="px-6 py-4 whitespace-nowrap text-sm text-gray-500">
							{{ transactionService.country[transaction.toCountryCode.toUpperCase()] }}
						</td>

						<td class="px-6 py-4 whitespace-nowrap text-sm text-gray-500">
							{{ transaction.mnoName ? transaction.mnoName : '-' }}
						</td>

						<td class="px-6 py-4 whitespace-nowrap text-sm text-gray-500">
							{{ transaction.orderId }}
						</td>

						<td class="px-6 py-4 whitespace-nowrap text-sm text-gray-500">
							{{ transaction.merchantReference }}
						</td>

						<td class="px-6 py-4 whitespace-nowrap text-sm text-gray-500">
							{{ transaction.amount }}
						</td>

						<td class="px-6 py-4 whitespace-nowrap text-sm text-gray-500">
							<div *ngIf="transaction.status == 'inprogress'" style="padding-top: 0.1em; padding-bottom: 0.1rem" class="text-xs px-3 bg-yellow-200 text-yellow-800 rounded-full inline-block">
								In progress
							</div>

							<div *ngIf="transaction.status == 'Failed'" style="padding-top: 0.1em; padding-bottom: 0.1rem" class="text-xs px-3 bg-red-200 text-red-800 rounded-full inline-block">
								Failed
							</div>

							<div *ngIf="transaction.status == 'Successful'" style="padding-top: 0.1em; padding-bottom: 0.1rem" class="text-xs px-3 bg-green-200 text-green-800 rounded-full inline-block">
								Success
							</div>
						</td>

						<td class="px-6 py-4 whitespace-nowrap text-3xl text-gray-500 cursor-pointer" (click)='viewTransactionDetails(transaction.id)'>
							...
						</td>
					</tr>

					<!-- More people... -->
				</tbody>
			</table>

			<div class="col-span-3 pr-10">
				<pagination-controls
					(pageChange)="p = $event"
					responsive="true"
					previousLabel="Précédent"
					nextLabel="Suivant" class="float-right"></pagination-controls>
			</div>
		</div>
	</div>
</div>
