<div class="w-full">
	<div class="content-header bg-white rounded shadow-xl">
		<div class="grid grid-cols-1">

			<div class="flex h-screen overflow-hidden">
				<div class="ml-auto mr-auto mt-32">
					<div class="text-center center mb-5">
						<img class="responsive-img inline" src="../../../assets/images/Logo.svg" alt="" />
					</div>
					<div class="bg-white shadow-md content-shadow rounded grid grid-cols-2">
						
						<div  class="col-span-2 p-20 mb-4">
							<div class="mb-5">
								<h6>
									Select the product or clique on continue to proceed with default
								</h6>
							</div>

							<form class="col-md-12 grid grid-cols-7" [formGroup]="form" (ngSubmit)="onSubmit()">

								<div class="w-full justify-between col-start-3 col-span-3">
									<div class="col-span-1 mt-5">
										<label class="inline-flex">
											<input type="radio" (change)="onSelectService($event)"
												class="form-radio" name="service" value="mobile-money"
												formControlName="service" disabled>
											<span class="text-lg text-grey">Mobile money</span>
										</label>
									</div>
								</div>

								<div class="w-full items-center justify-between col-start-3 col-span-3">
									<div class="col-span-1 mt-5">
										<label class="inline-flex items-center">
											<input type="radio" (change)="onSelectService($event)"
												class="form-radio" name="service" value="mobile-transfer"
												formControlName="service">
											<span class="text-black">Mobile transfer (IRT)</span>
										</label>
									</div>
								</div>

								<div class="w-full items-center justify-between col-start-3 col-span-3">
									<div class="col-span-1 mt-5">
										<label class="inline-flex items-center">
											<input type="radio" (change)="onSelectService($event)"
												class="form-radio" name="service" value="dcb"
												formControlName="service" disabled>
											<span class="text-grey">Direct carrier billing</span>
										</label>
									</div>
								</div>

								<div class="w-full items-center justify-between col-start-3 col-span-3">
									<div class="col-span-1 mt-5">
										<label class="inline-flex items-center">
											<input type="radio" (change)="onSelectService($event)"
												class="form-radio" name="service" value="bulk"
												formControlName="service">
											<span class="text-black">Bulk Payment</span>
										</label>
									</div>
								</div>

								<div class="w-full items-center justify-between col-start-2 col-span-5 mt-10">
									<button  [disabled]="loading" class="bg-yellow-500 text-white font-bold py-4 px-4
										text-lg rounded-full w-full">
										Continue
									</button>
								</div>

							</form>
						</div>
						
					</div>

				</div>
			</div>

		</div>
	</div>
</div>
