import { Component, Input } from '@angular/core';
import { FormGroup } from '@angular/forms';
import { MatDialog } from '@angular/material/dialog';
import { ProfilService, AlertService } from '@app/_services';
import { Profil } from '@app/_models';

@Component({
    selector: 'delete-alert',
    templateUrl: './delete.component.html',
    styleUrls: ['./delete.component.css']
})

export class DeleteAlert {

    form: FormGroup;
    loading = false;
    submitted = false;
    @Input() profil: Profil;
    deleteLoader: boolean = false;
    profils: Profil[];

    constructor(
        private profilService: ProfilService,
        private dialog: MatDialog,
        private alertService: AlertService
    ) { }

    ngOnInit() {
        this.profilService.profilsSubject.subscribe(resp => {
			this.profils =  resp;
		});
        this.profilService.emitProfils();
    }

    dismissDialog() {
        this.dialog.closeAll();
    }

    processDelete() {
        this.deleteLoader = true;
        this.profilService.deleteProfile(this.profil['name']).subscribe(() => {
            this.deleteLoader = false;
            this.alertService.success('Success', 'Profil supprimé avec succès', { keepAfterRouteChange: true });
            this.dialog.closeAll();
            this.profilService.emitPartnersAfterDeletd(this.profil);
        },
        error => {
            this.deleteLoader = false;
            this.alertService.error('Error', 'Erreur de suppression : ' + error, { keepAfterRouteChange: true });
            this.dialog.closeAll();
            this.loading = false;
        });
    }
}
