<nav>
  <div class="nav-wrapper">
    <div class="col s12">
      <a href="#" class="breadcrumb app-root">App</a>
      <a href="#" class="breadcrumb">{{ 'sidenav.operations.title' | translate }}</a>
      <a href="#" class="breadcrumb">{{ 'sidenav.operations.partnersProvisioning' | translate }}</a>
    </div>
  </div>
</nav>

<div class="content">
  <div class="content-header z-depth-1">
    <p class="left">{{ 'sidenav.operations.partnersProvisioning' | translate }}</p>
  <p class="right"><img class="responsive-img" src="../../../../../assets/images/Transfert_En_Masse.svg" alt=""></p>
  </div>
</div>
