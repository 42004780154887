<div class="card-amount text-left pl-5">
    <h6 class="title">
        {{ title }}
        <span class="badge badge-success float-none py-5" *ngIf="isSuccess()">{{ pourcentage.toFixed(2) }} %</span>
        <span class="badge badge-danger float-none py-5" *ngIf="isFails()">{{ pourcentage.toFixed(2) }} %</span>
    </h6>

    <h5 class="amount text-6xl">
        {{ amount }} <span *ngIf="isMoney()" class="text-xl">{{ currency }}</span>
    </h5>

    <span class="description">
        {{ description }}
    </span>

</div>
