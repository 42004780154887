import { Component, OnInit } from '@angular/core';
import { MatDialog } from '@angular/material/dialog';
import { AlertSuccess } from './alert-success/alert-success.component';

@Component({
	selector: 'app-account-to-account-transfer',
	templateUrl: './account-to-account-transfer.component.html',
	styleUrls: ['./account-to-account-transfer.component.css']
})
export class AccountToAccountTransferComponent implements OnInit {

	constructor(public dialog: MatDialog) {}

	ngOnInit(): void {}

	openDialog() {
		const dialogRef = this.dialog.open(AlertSuccess);
	
		dialogRef.afterClosed().subscribe(result => {
		  	console.log(`Dialog result: ${result}`);
		});
	}

}
