import { Component, OnInit } from '@angular/core';

@Component({
  selector: 'app-partners-provisionning',
  templateUrl: './partners-provisionning.component.html',
  styleUrls: ['./partners-provisionning.component.css']
})
export class PartnersProvisionningComponent implements OnInit {
  sectionTitle = "Operations";
  sectionItem = "Provisionning Partenaires";
  sectionLeftTitle = "Provisionning Partenaires";
  sectionRightTitle = "To be defined";
  constructor() { }

  ngOnInit(): void {
  }

}
