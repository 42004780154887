import { Component, Input } from '@angular/core';
import { Router, ActivatedRoute } from '@angular/router';
import { FormBuilder, FormGroup, Validators } from '@angular/forms';
import { first } from 'rxjs/operators';
import { MatDialog } from '@angular/material/dialog';
import { Subscription } from 'rxjs';

import { AccountService, AlertService, PartnerService, ProfilService } from '@app/_services';
import { User, Profil, Partner, UserPartner } from '@app/_models';

@Component({
	selector: 'add-alert',
	templateUrl: './add-alert.component.html',
	styleUrls: ['./add-alert.component.css']
})

export class AddAlert {

	form: FormGroup;
	loading = false;
	submitted = false;
	@Input() user: User;
	addUserLoader: Boolean = false;
	userSubscription: Subscription;
	users: User[];
	profils: Profil[];
	partners: UserPartner[];
	connectionTypes: string[];
	selectedPartner: UserPartner;
	constructor(
		private formBuilder: FormBuilder,
		private route: ActivatedRoute,
		private router: Router,
		private accountService: AccountService,
		private dialog: MatDialog,
		private alertService: AlertService,
		private profilService: ProfilService,
		private partnerService: PartnerService
	) { }

	ngOnInit() {
		this.form = this.formBuilder.group({
			firstName: 		['', Validators.required],
			lastName: 		['', Validators.required],
			address:		['', Validators.required],
			email: 			['', Validators.required],
			phoneNumber:	['', Validators.required],
			country:		['SN', Validators.required],
			city:		['SN', Validators.required],
			zipCode:		['14000', Validators.required],
			departmentId:		[1, Validators.required],
			profile:		    ['', Validators.required],
			partner: [null],
			connectionType: [null, Validators.required],
			status:    [false]
			// password: 		['', [Validators.required, Validators.minLength(6)]]
		});

		if (this.user) {
			this.selectedPartner = this.user.partner;
			this.loadUserDataOnform();
		}

		this.profilService.getAll().subscribe(resp => {
			this.profils = resp['data']
		});
		this.getAllPartners();
		this.getAllConnectionType();

	}

	getAllPartners(){
		this.partnerService.getPartnersForUsers().subscribe(resp => {
			this.partners = resp;
		})
	}

	getAllConnectionType(){
		this.connectionTypes = this.accountService.getAllConnectionType();
	}

	// convenience getter for easy access to form fields
	get f() { return this.form.controls; }

	onSubmit() {
		this.submitted = true;

		// reset alerts on submit
		this.alertService.clear();

		// stop here if form is invalid
		if (this.form.invalid) {
			return;
		}

		this.loading = true;

		if (this.user) {
			this.updateUser();
		} else {
			this.addUser();
		}
	}

	addUser() {
		this.addUserLoader = true;
		let data = Object.assign({}, this.form.value);
		//delete this.selectedPartner['id'];
		data["partner"] = this.selectedPartner;
		this.accountService.register(data)
		.pipe(first())
		.subscribe({
			next: (userCreated) => {
				this.alertService.success('Success', 'Registration successful', { keepAfterRouteChange: true });
				this.dialog.closeAll();
				this.addUserLoader = false;
				this.accountService.pushUser(userCreated['data'] as User);
			},
			error: error => {
				this.alertService.error('Error', error);
				this.loading = false;
			}
		});
	}

	updateUser() {
		this.addUserLoader = true;
		let data = Object.assign({}, this.form.value);
		data["partner"] = this.selectedPartner;
		this.accountService.update(this.user.id, data)
		.pipe(first())
		.subscribe({
			next: (userUpdated) => {
				this.addUserLoader = false;
				this.alertService.success('Success', 'Update successful', { keepAfterRouteChange: true });
				this.dialog.closeAll();
			},
			error: error => {
				this.addUserLoader = false;
				this.dialog.closeAll();
				this.alertService.success('Success', 'Update successful', { keepAfterRouteChange: true });
				this.loading = false;
			}
		});
	}

	loadUserDataOnform() {

		this.form.patchValue({
			firstName: this.user.firstName,
			lastName: this.user.lastName,
			address: this.user.address,
			email: this.user.email,
			phoneNumber: this.user.phoneNumber,
			country: this.user.country,
			status: this.user.status,
			profile: this.user.profile,
			partner: this.user.partner,
			connectionType: this.user.connectionType
			// password: this.user.password
		});
	}

	setPartnerInfos(partnerId){
		this.selectedPartner = this.partners.find(p => p['id'] == partnerId)

	}
}
