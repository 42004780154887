import { Component, OnInit } from '@angular/core';

@Component({
  selector: 'app-accounts-statements',
  templateUrl: './accounts-statements.component.html',
  styleUrls: ['./accounts-statements.component.css']
})
export class AccountsStatementsComponent implements OnInit {
  sectionTitle = "Operations";
  sectionItem = "Account Statements";
  sectionLeftTitle = "Account Statements";
  sectionRightTitle = "To be defined";
  constructor() { }

  ngOnInit(): void {
  }

}
