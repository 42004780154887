export class TransactionFilterUtils {

    filterByPartnerId(transactions, serviceProvider) {
        return transactions.filter(transaction => transaction.serviceProvider == serviceProvider);
    }

    filterByMerchantReference(transactions, merchantReference) {
        return transactions.filter(transaction => transaction.merchantReference == merchantReference);
    }

    filterFromCountry(transactions, country) {
        return transactions.filter(transaction => transaction.fromCountryCode == country);
    }

    filterToCountry(transactions, country) {
        return transactions.filter(transaction => transaction.toCountryCode.toLowerCase() == country.toLowerCase());
    }

    filterTrafic(transactions, successOrFail) {
        return transactions.filter(transaction => transaction.status.toLowerCase() == successOrFail.toLowerCase());
    }

    filterByMno(transactions, mnoName) {
        return transactions.filter(transaction => transaction.mnoName == mnoName);
    }

    filterByStatus(transactions, status) {
        return transactions.filter(transaction => transaction.status == status);
    }

}
