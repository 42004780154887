<nav>
	<div class="nav-wrapper">
		<div class="col s12" style="padding-left: 25px;">
			<a href="#" class="breadcrumb app-root text-gray-400 text-breadcrumb">App</a>
			<a href="#" class="breadcrumb text-gray-400 text-breadcrumb">Gestion des utilisateurs</a>
			<a href="#" class="breadcrumb text-gray-400 text-breadcrumb">Liste utilisateurs</a>
		</div>
	</div>
</nav>

<!-- <div class="content">
	<div class="content-header z-depth-1">
		<p class="left">{{ 'sidenav.users.management' | translate }}</p>
		<p class="right"><img class="responsive-img" src="../../../../../assets/images/Transfert_En_Masse.svg" alt="">
		</p>
	</div>
</div> -->

<div class="content pb-10">
	<div class="bg-white rounded mx-5 mb-10 shadow-xl">
		<div class="grid grid-cols-1 gap-2 p-5">

			<div class="bg-white grid grid-cols-2 border-b border-gray-200 border-solid pb-5">
				<h6 class="col-span-1">
					Gestion des utilisateurs
				</h6>

				<div class="col-span-1 float-right content-shadow">
					<button (click)='openDialog()'
						class="bg-yellow-500 text-white font-bold py-2 px-4 rounded-full float-right">
						Ajouter un utilisateur
				</button>
				</div>
			</div>

			<div wire:loading class="top-0 right-0 mr-2 mt-2" *ngIf="!users">
				<svg class="animate-spin h-10 w-10 m-auto rounded-full bg-transparent border-2 border-transparent border-opacity-50 border-loader-color" viewBox="0 0 24 24"></svg>
			</div>

			<table class="min-w-full divide-y divide-gray-200">
				<thead class="bg-gray-50">
					<tr>
						<th scope="col"
							class="px-6 py-3 text-left text-xs font-medium text-gray-500 uppercase tracking-wider">
							#
						</th>

						<th scope="col"
							class="px-6 py-3 text-left text-xs font-medium text-gray-500 uppercase tracking-wider">
							Nom
						</th>

						<th scope="col"
							class="px-6 py-3 text-left text-xs font-medium text-gray-500 uppercase tracking-wider">
							Prénom
						</th>

						<th scope="col"
							class="px-6 py-3 text-left text-xs font-medium text-gray-500 uppercase tracking-wider">
							Email
						</th>

						<th scope="col"
							class="px-6 py-3 text-left text-xs font-medium text-gray-500 uppercase tracking-wider">
							Adresse
						</th>

						<th scope="col"
							class="px-6 py-3 text-left text-xs font-medium text-gray-500 uppercase tracking-wider">
							Profil
						</th>

						<th scope="col"
							class="px-6 py-3 text-left text-xs font-medium text-gray-500 uppercase tracking-wider">
							Partenaire
						</th>

						<th scope="col"
							class="px-6 py-3 text-left text-xs font-medium text-gray-500 uppercase tracking-wider">
							Téléphone
						</th>

						<th scope="col"
							class="px-6 py-3 text-left text-xs font-medium text-gray-500 uppercase tracking-wider">
							Etat
						</th>

						<th scope="col" class="px-6 py-3 text-left text-xs font-medium text-gray-500 uppercase tracking-wider">
							Action
						</th>
					</tr>
				</thead>

				<tbody class="bg-white divide-y divide-gray-200">

					<tr *ngFor="let user of users; let i = index">
						<td class="px-6 py-4 whitespace-nowrap text-sm text-gray-500">
							{{ user.id }}
						</td>

						<td class="px-6 py-4 whitespace-nowrap text-sm text-gray-500">
							{{ user.lastName }}
						</td>

						<td class="px-6 py-4 whitespace-nowrap text-sm text-gray-500">
							{{ user.firstName }}
						</td>

						<td class="px-6 py-4 whitespace-nowrap text-sm text-gray-500">
							{{ user.email }}
						</td>

						<td class="px-6 py-4 whitespace-nowrap text-sm text-gray-500">
							{{ user.address }}
						</td>

						<td class="px-6 py-4 whitespace-nowrap text-sm text-gray-500">
							{{ user?.profile }}
						</td>

						<td class="px-6 py-4 whitespace-nowrap text-sm text-gray-500">
							{{user?.partner?.name}}
						</td>

						<td class="px-6 py-4 whitespace-nowrap text-sm text-gray-500">
							{{ user.phoneNumber }}
						</td>

						<td class="px-6 py-4 whitespace-nowrap text-sm text-gray-500">
							<ui-switch [(ngModel)]="user.status" (valueChange)="onChangeUser($event, user)"></ui-switch>
						</td>

						<td class="px-6 py-4 whitespace-nowrap text-sm text-gray-500">
							<button mat-button [matMenuTriggerFor]="menu" class="action-button">...</button>
							<mat-menu #menu="matMenu">
								<button mat-menu-item (click)="openUpdateUser(user.id)">Modifier</button>
								<button mat-menu-item (click)="openDeleteUserModal(user.id)">Supprimer</button>
							</mat-menu>
						</td>
					</tr>

					<!--
					<tr>
						<td class="px-6 py-4 whitespace-nowrap text-sm text-gray-500">
							1
						</td>

						<td class="px-6 py-4 whitespace-nowrap text-sm text-gray-500">
							CISSE
						</td>

						<td class="px-6 py-4 whitespace-nowrap text-sm text-gray-500">
							Djibril
						</td>

						<td class="px-6 py-4 whitespace-nowrap text-sm text-gray-500">
							djibril.cisse@bizao.com
						</td>

						<td class="px-6 py-4 whitespace-nowrap text-sm text-gray-500">
							Dakar
						</td>

						<td class="px-6 py-4 whitespace-nowrap text-sm text-gray-500">
							Admin
						</td>

						<td class="px-6 py-4 whitespace-nowrap text-sm text-gray-500">
							Bizao
						</td>

						<td class="px-6 py-4 whitespace-nowrap text-sm text-gray-500">
							221 77 777 77 77
						</td>

						<td class="px-6 py-4 whitespace-nowrap text-sm text-gray-500">
							<label for="toggle" class="toggle-label block overflow-hidden h-6 rounded-full bg-gray-300 cursor-pointer"></label>
							<input type="checkbox" name="toggle" id="toggle" class="toggle-checkbox absolute block w-6 h-6 rounded-full bg-white border-4 appearance-none cursor-pointer" />
						</td>

						<td class="px-6 py-4 whitespace-nowrap text-sm text-gray-500">
							<button mat-button [matMenuTriggerFor]="menu">...</button>
							<mat-menu #menu="matMenu">
								<button mat-menu-item>Modifier</button>
								<button mat-menu-item>Supprimer</button>
							</mat-menu>
						</td>
					</tr>

					<tr>
						<td class="px-6 py-4 whitespace-nowrap text-sm text-gray-500">
							1
						</td>

						<td class="px-6 py-4 whitespace-nowrap text-sm text-gray-500">
							CISSE
						</td>

						<td class="px-6 py-4 whitespace-nowrap text-sm text-gray-500">
							Djibril
						</td>

						<td class="px-6 py-4 whitespace-nowrap text-sm text-gray-500">
							djibril.cisse@bizao.com
						</td>

						<td class="px-6 py-4 whitespace-nowrap text-sm text-gray-500">
							Dakar
						</td>

						<td class="px-6 py-4 whitespace-nowrap text-sm text-gray-500">
							Admin
						</td>

						<td class="px-6 py-4 whitespace-nowrap text-sm text-gray-500">
							Bizao
						</td>

						<td class="px-6 py-4 whitespace-nowrap text-sm text-gray-500">
							221 77 777 77 77
						</td>

						<td class="px-6 py-4 whitespace-nowrap text-sm text-gray-500">
							<label for="toggle" class="toggle-label block overflow-hidden h-6 rounded-full bg-gray-300 cursor-pointer"></label>
							<input type="checkbox" name="toggle" id="toggle" class="toggle-checkbox absolute block w-6 h-6 rounded-full bg-white border-4 appearance-none cursor-pointer" />
						</td>

						<td class="px-6 py-4 whitespace-nowrap text-sm text-gray-500">
							<button mat-button [matMenuTriggerFor]="menu">...</button>
							<mat-menu #menu="matMenu">
								<button mat-menu-item>Modifier</button>
								<button mat-menu-item>Supprimer</button>
							</mat-menu>
						</td>
					</tr>

					<tr>
						<td class="px-6 py-4 whitespace-nowrap text-sm text-gray-500">
							1
						</td>

						<td class="px-6 py-4 whitespace-nowrap text-sm text-gray-500">
							CISSE
						</td>

						<td class="px-6 py-4 whitespace-nowrap text-sm text-gray-500">
							Djibril
						</td>

						<td class="px-6 py-4 whitespace-nowrap text-sm text-gray-500">
							djibril.cisse@bizao.com
						</td>

						<td class="px-6 py-4 whitespace-nowrap text-sm text-gray-500">
							Dakar
						</td>

						<td class="px-6 py-4 whitespace-nowrap text-sm text-gray-500">
							Admin
						</td>

						<td class="px-6 py-4 whitespace-nowrap text-sm text-gray-500">
							Bizao
						</td>

						<td class="px-6 py-4 whitespace-nowrap text-sm text-gray-500">
							221 77 777 77 77
						</td>

						<td class="px-6 py-4 whitespace-nowrap text-sm text-gray-500">
							<label for="toggle" class="toggle-label block overflow-hidden h-6 rounded-full bg-gray-300 cursor-pointer"></label>
							<input type="checkbox" name="toggle" id="toggle" class="toggle-checkbox absolute block w-6 h-6 rounded-full bg-white border-4 appearance-none cursor-pointer" />
						</td>

						<td class="px-6 py-4 whitespace-nowrap text-sm text-gray-500">
							<button mat-button [matMenuTriggerFor]="menu">...</button>
							<mat-menu #menu="matMenu">
								<button mat-menu-item>Modifier</button>
								<button mat-menu-item>Supprimer</button>
							</mat-menu>
						</td>
					</tr>

					<tr>
						<td class="px-6 py-4 whitespace-nowrap text-sm text-gray-500">
							1
						</td>

						<td class="px-6 py-4 whitespace-nowrap text-sm text-gray-500">
							CISSE
						</td>

						<td class="px-6 py-4 whitespace-nowrap text-sm text-gray-500">
							Djibril
						</td>

						<td class="px-6 py-4 whitespace-nowrap text-sm text-gray-500">
							djibril.cisse@bizao.com
						</td>

						<td class="px-6 py-4 whitespace-nowrap text-sm text-gray-500">
							Dakar
						</td>

						<td class="px-6 py-4 whitespace-nowrap text-sm text-gray-500">
							Admin
						</td>

						<td class="px-6 py-4 whitespace-nowrap text-sm text-gray-500">
							Bizao
						</td>

						<td class="px-6 py-4 whitespace-nowrap text-sm text-gray-500">
							221 77 777 77 77
						</td>

						<td class="px-6 py-4 whitespace-nowrap text-sm text-gray-500">
							<label for="toggle" class="toggle-label block overflow-hidden h-6 rounded-full bg-gray-300 cursor-pointer"></label>
							<input type="checkbox" name="toggle" id="toggle" class="toggle-checkbox absolute block w-6 h-6 rounded-full bg-white border-4 appearance-none cursor-pointer" />
						</td>

						<td class="px-6 py-4 whitespace-nowrap text-sm text-gray-500">
							<button mat-button [matMenuTriggerFor]="menu">...</button>
							<mat-menu #menu="matMenu">
								<button mat-menu-item>Modifier</button>
								<button mat-menu-item>Supprimer</button>
							</mat-menu>
						</td>
					</tr> -->
					<!-- More people... -->

				</tbody>
			</table>

		</div>
	</div>
</div>
