// This file can be replaced during build by using the `fileReplacements` array.
// `ng build --prod` replaces `environment.ts` with `environment.prod.ts`.
// The list of file replacements can be found in `angular.json`.

//export const environment = {
//    api: 'https://bsc-api-gateway.herokuapp.com/api',
//    production: false,
//    ppApi: 'https://bsc-user-manager.herokuapp.com/api'
//};

export const environment = {
	// api: 'http://104.155.55.244:8084/api',
	production: false,
	// ppApi: 'http://104.155.55.244:8082/api',

	api: 'https://bsc-api-gateway.herokuapp.com/api',
	// production: false,
	//ppApi: 'https://bsc-user-manager.herokuapp.com/api',
	// keycloack: "https://mistral-backend-app.herokuapp.com/api"
};
