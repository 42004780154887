<div class="grid grid-cols-1">

	<div class="bg-white grid grid-cols-2 border-b border-gray-200 border-solid pl-5 py-5">
		<h6 class="col-span-1">
			{{ user ? 'Modifier utilisateur' : 'Ajouter un utilisateur' }}
		</h6>
	</div>

	<!-- This example requires Tailwind CSS v2.0+ -->
	<div class="flex flex-col">
		<div class="my-2 overflow-x-auto sm:-mx-6 lg:-mx-8">
			<div class="py-2 align-middle inline-block min-w-full sm:px-6 lg:px-8 grid grid-cols-6 gap-4"
				style="height: 500px; overflow-y: scroll;">

				<div class="col-start-2 col-span-4">

					<div class="grid grid-cols-1">
						<!-- <div class="col-span-1 text-right pt-5 pr-5">
								<span class="text-gray-700">Select IRT</span>
							</div>
							<div class="col-span-3">
							<select name="cars" id="cars" class="block">
							<option value="volvo">Small world</option>
							</select>
						</div> -->
						<form class="grid grid-cols-4 col-span-1" [formGroup]="form" (ngSubmit)="onSubmit()">

							<div class="col-span-1 text-right pt-5 pr-5 mt-5">
								<span class="text-gray-700">Prénom</span>
							</div>
							<div class="col-span-3 mt-5">
								<input type="text" formControlName="firstName" placeholder="Prénom" class="form-control"
									[ngClass]="{ 'is-invalid': submitted && f.firstName.errors }" />
								<div *ngIf="submitted && f.firstName.errors" class="invalid-feedback">
									<div *ngIf="f.firstName.errors.required">First Name is required</div>
								</div>
							</div>

							<div class="col-span-1 text-right pt-5 pr-5 mt-5">
								<span class="text-gray-700">Nom</span>
							</div>
							<div class="col-span-3 mt-5">
								<input type="text" placeholder="Nom" formControlName="lastName" class="form-control"
									[ngClass]="{ 'is-invalid': submitted && f.lastName.errors }" />
								<div *ngIf="submitted && f.lastName.errors" class="invalid-feedback">
									<div *ngIf="f.lastName.errors.required">Last Name is required</div>
								</div>
							</div>

							<div class="col-span-1 text-right pt-5 pr-5 mt-5">
								<span class="text-gray-700">Email</span>
							</div>
							<div class="col-span-3 mt-5">
								<input type="email" formControlName="email" placeholder="email" class="form-control"
									[ngClass]="{ 'is-invalid': submitted && f.email.errors }" />
								<div *ngIf="submitted && f.email.errors" class="invalid-feedback">
									<div *ngIf="f.email.errors.required">Email is required</div>
								</div>
							</div>

							<div class="col-span-1 text-right pt-5 pr-5 mt-5">
								<span class="text-gray-700">Téléphone</span>
							</div>
							<div class="col-span-3 mt-5">
								<input type="text" formControlName="phoneNumber" placeholder="Téléphone"
									class="form-control"
									[ngClass]="{ 'is-invalid': submitted && f.phoneNumber.errors }" />
								<div *ngIf="submitted && f.phoneNumber.errors" class="invalid-feedback">
									<div *ngIf="f.phoneNumber.errors.required">Le téléphone est requis</div>
								</div>
							</div>

							<div class="col-span-1 text-right pt-5 pr-5 mt-5">
								<span class="text-gray-700">Pays</span>
							</div>
							<div class="col-span-3 mt-5">
								<input type="text" formControlName="country" placeholder="Pays" class="form-control"
									[ngClass]="{ 'is-invalid': submitted && f.country.errors }" />
								<div *ngIf="submitted && f.country.errors" class="invalid-feedback">
									<div *ngIf="f.country.errors.required">Le pays est requis</div>
								</div>
							</div>

							<div class="col-span-1 text-right pt-5 pr-5 mt-5">
								<span class="text-gray-700">Adresse <em class="text-red">*</em></span>
							</div>
							<div class="col-span-3 mt-5">
								<input type="text" formControlName="address" placeholder="Adresse" class="form-control"
									[ngClass]="{ 'is-invalid': submitted && f.address.errors }" />
								<div *ngIf="submitted && f.address.errors" class="invalid-feedback">
									<div *ngIf="f.address.errors.required">L'adresse est requis</div>
								</div>
							</div>

							<div class="col-span-1 text-right pt-5 pr-5 mt-5">
								<span class="text-gray-700">Profil</span>
							</div>
							<div class="col-span-3 mt-5">
								<select class="block" id="profil" name="profil" formControlName="profile"
									[ngClass]="{ 'is-invalid': submitted && f.profile.errors }" required>
									<!--  <select class="form-control" id="profil" name="profil" formControlName="profil" [ngClass]="{ 'is-invalid': submitted && f.offer.errors }" required> -->
									<option disabled>Sélectionnez un profil</option>
									<option *ngFor="let profil of profils" [value]="profil.name">
										{{ profil.name }}
									</option>
								</select>
								<svg class="animate-spin h-4 w-4 m-auto rounded-full bg-transparent border-2 border-transparent border-opacity-50 border-loader-color inline mr-5"
									viewBox="0 0 24 24" *ngIf="!profils"></svg>
							</div>

							<div class="col-span-1 text-right pt-5 pr-5 mt-5">
								<span class="text-gray-700">Type de connexion</span>
							</div>
							<div class="col-span-3 mt-5">
								<select class="block" id="connectionType" name="connectionType"
									formControlName="connectionType"
									[ngClass]="{ 'is-invalid': submitted && f.profile.errors }" required>
									<!--  <select class="form-control" id="profil" name="profil" formControlName="profil" [ngClass]="{ 'is-invalid': submitted && f.offer.errors }" required> -->
									<option disabled>Sélectionnez un type de connexion</option>
									<option *ngFor="let type of connectionTypes" [value]="type">
										{{ type }}
									</option>
								</select>
								<svg class="animate-spin h-4 w-4 m-auto rounded-full bg-transparent border-2 border-transparent border-opacity-50 border-loader-color inline mr-5"
									viewBox="0 0 24 24" *ngIf="!profils"></svg>
							</div>


							<div class="col-span-1 text-right pt-5 pr-5 mt-5">
								<span class="text-gray-700">Partenaire</span>
							</div>
							<div class="col-span-3 mt-5">
								<select class="block" id="partenaires" name="partenaires" #ptn
									(change)="setPartnerInfos(ptn.value)"
									[ngClass]="{ 'is-invalid': submitted && f.partner.errors }" required>
									<!--  <select class="form-control" id="profil" name="profil" formControlName="profil" [ngClass]="{ 'is-invalid': submitted && f.offer.errors }" required> -->
									<option disabled [attr.selected]="(selectedPartner?.name == null) ? true : null">
										Sélectionnez un partenaire</option>
									<option *ngFor="let partner of partners" [value]="partner.id"
										[attr.selected]="(selectedPartner?.name == partner?.name) ? true : null">
										{{ partner?.name }}
									</option>
								</select>
								<svg class="animate-spin h-4 w-4 m-auto rounded-full bg-transparent border-2 border-transparent border-opacity-50 border-loader-color inline mr-5"
									viewBox="0 0 24 24" *ngIf="!profils"></svg>
							</div>

							<!--
								<div class="col-span-1 text-right pt-5 pr-5 mt-5">
									<span class="text-gray-700">Mot de passe</span>
									</div>
									<div class="col-span-3 mt-5">
									<input type="password" formControlName="password" placeholder="Mot de passe" class="form-control" [ngClass]="{ 'is-invalid': submitted && f.password.errors }" />
									<div *ngIf="submitted && f.password.errors" class="invalid-feedback">
									<div *ngIf="f.password.errors.required">Password is required</div>
									<div *ngIf="f.password.errors.minlength">Password must be at least 6 characters</div>
									</div>
								</div>
							-->

							<div
								class="col-span-4 py-2 align-middle inline-block min-w-full sm:px-6 lg:px-8 mt-10 text-center">
								<button type="submit" class="bg-yellow-500 text-white active:bg-yellow-600 font-bold
									text-base px-8 py-3 rounded-full shadow-md hover:shadow-lg outline-none
									focus:outline-none mr-1 mb-1 ease-linear transition-all duration-150
									inline-block inline" cdkFocusInitial>
									<svg class="animate-spin h-4 w-4 m-auto rounded-full bg-transparent border-2 border-transparent border-opacity-50 border-loader-color inline mr-5"
										viewBox="0 0 24 24" *ngIf="addUserLoader"></svg>
									Enregistrer
								</button>
							</div>

						</form>
					</div>

				</div>
			</div>
		</div>
	</div>
</div>
