<nav>
	<div class="nav-wrapper">
		<div class="col s12">
			<a href="#" class="breadcrumb app-root text-gray-400">App</a>
			<a href="#" class="breadcrumb text-gray-400">Paramétres globales</a>
			<a href="#" class="breadcrumb text-gray-400">Paramétres généraux</a>
			<a href="#" class="breadcrumb text-gray-400">Devises</a>
		</div>
	</div>
</nav>

<div class="content pb-10">
	<div class="content-header bg-white rounded m-5 p-0">
		<div class="grid grid-cols-1 shadow-lg p-5">

			<div class="bg-white grid grid-cols-2 border-b-2 border-gray-200 pb-5">
				<h6 class="col-span-1">
					Currency configuration
				</h6>

				<div class="col-span-1 float-right content-shadow">
					<a routerLink="/home/general-settings/devises/add"
						class="bg-yellow-500 text-white font-bold py-2 px-4 rounded-full float-right">
						New Currency
					</a>
				</div>
			</div>

			<!-- This example requires Tailwind CSS v2.0+ -->
			<div class="flex flex-col">
				<div class="my-2 overflow-x-auto sm:-mx-6 lg:-mx-8">
					<div class="py-2 align-middle inline-block min-w-full sm:px-6 lg:px-8">
						<div class="shadow overflow-hidden border-b border-gray-200 sm:rounded-lg">
							<table class="min-w-full divide-y divide-gray-200">
								<thead class="bg-gray-50">
									<tr>
										<th scope="col"
											class="px-6 py-3 text-left text-xs font-medium text-gray-500 uppercase tracking-wider">
											#
										</th>

										<th scope="col"
											class="px-6 py-3 text-left text-xs font-medium text-gray-500 uppercase tracking-wider">
											Currency
										</th>

										<th scope="col"
											class="px-6 py-3 text-left text-xs font-medium text-gray-500 uppercase tracking-wider">
											Continent
										</th>

										<th scope="col"
											class="px-6 py-3 text-left text-xs font-medium text-gray-500 uppercase tracking-wider">
											Country
										</th>

										<th scope="col"
											class="px-6 py-3 text-left text-xs font-medium text-gray-500 uppercase tracking-wider">
											MNO
										</th>

										<th scope="col" class="relative px-6 py-3">
											Action
										</th>
									</tr>
								</thead>

								<tbody class="bg-white divide-y divide-gray-200">
									<tr>
										<td class="px-6 py-4 whitespace-nowrap text-sm text-gray-500">
											1
										</td>

										<td class="px-6 py-4 whitespace-nowrap text-sm text-gray-500">
											ECO
										</td>

										<td class="px-6 py-4 whitespace-nowrap text-sm text-gray-500">
											Africa
										</td>

										<td class="px-6 py-4 whitespace-nowrap text-sm text-gray-500">
											Ivory Coast
										</td>

										<td class="px-6 py-4 whitespace-nowrap text-sm text-gray-500">
											<span class="inline-flex items-center justify-center px-2 py-1 text-xs font-bold leading-none text-white bg-yellow-400 rounded mr-5">Orange</span>
											<span class="inline-flex items-center justify-center px-2 py-1 text-xs font-bold leading-none text-white bg-yellow-400 rounded mr-5">MTN</span>
											<span class="inline-flex items-center justify-center px-2 py-1 text-xs font-bold leading-none text-white bg-yellow-400 rounded mr-5">Mooov</span>
										</td>

										<td class="px-6 py-4 whitespace-nowrap text-sm text-gray-500">
											<button mat-button [matMenuTriggerFor]="menu">...</button>
											<mat-menu #menu="matMenu">
												<button mat-menu-item>Modifier</button>
												<button mat-menu-item>Supprimer</button>
											</mat-menu>
										</td>
									</tr>

									<tr>
										<td class="px-6 py-4 whitespace-nowrap text-sm text-gray-500">
											2
										</td>

										<td class="px-6 py-4 whitespace-nowrap text-sm text-gray-500">
											ECO
										</td>

										<td class="px-6 py-4 whitespace-nowrap text-sm text-gray-500">
											Africa
										</td>

										<td class="px-6 py-4 whitespace-nowrap text-sm text-gray-500">
											Ivory Coast
										</td>

										<td class="px-6 py-4 whitespace-nowrap text-sm text-gray-500">
											<span class="inline-flex items-center justify-center px-2 py-1 text-xs font-bold leading-none text-white bg-yellow-400 rounded mr-5">MTN</span>
										</td>

										<td class="px-6 py-4 whitespace-nowrap text-sm text-gray-500">
											<button mat-button [matMenuTriggerFor]="menu">...</button>
											<mat-menu #menu="matMenu">
												<button mat-menu-item>Modifier</button>
												<button mat-menu-item>Supprimer</button>
											</mat-menu>
										</td>
									</tr>

									<tr>
										<td class="px-6 py-4 whitespace-nowrap text-sm text-gray-500">
											3
										</td>

										<td class="px-6 py-4 whitespace-nowrap text-sm text-gray-500">
											ECO
										</td>

										<td class="px-6 py-4 whitespace-nowrap text-sm text-gray-500">
											Africa
										</td>

										<td class="px-6 py-4 whitespace-nowrap text-sm text-gray-500">
											Ivory Coast
										</td>

										<td class="px-6 py-4 whitespace-nowrap text-sm text-gray-500">
											<span class="inline-flex items-center justify-center px-2 py-1 text-xs font-bold leading-none text-white bg-yellow-400 rounded mr-5">MTN</span>
											<span class="inline-flex items-center justify-center px-2 py-1 text-xs font-bold leading-none text-white bg-yellow-400 rounded mr-5">Mooov</span>
										</td>

										<td class="px-6 py-4 whitespace-nowrap text-sm text-gray-500">
											<button mat-button [matMenuTriggerFor]="menu">...</button>
											<mat-menu #menu="matMenu">
												<button mat-menu-item>Modifier</button>
												<button mat-menu-item>Supprimer</button>
											</mat-menu>
										</td>
									</tr>

									<tr>
										<td class="px-6 py-4 whitespace-nowrap text-sm text-gray-500">
											4
										</td>

										<td class="px-6 py-4 whitespace-nowrap text-sm text-gray-500">
											ECO
										</td>

										<td class="px-6 py-4 whitespace-nowrap text-sm text-gray-500">
											Africa
										</td>

										<td class="px-6 py-4 whitespace-nowrap text-sm text-gray-500">
											Ivory Coast
										</td>

										<td class="px-6 py-4 whitespace-nowrap text-sm text-gray-500">
											<span class="inline-flex items-center justify-center px-2 py-1 text-xs font-bold leading-none text-white bg-yellow-400 rounded mr-5">Orange</span>
										</td>

										<td class="px-6 py-4 whitespace-nowrap text-sm text-gray-500">
											<button mat-button [matMenuTriggerFor]="menu">...</button>
											<mat-menu #menu="matMenu">
												<button mat-menu-item>Modifier</button>
												<button mat-menu-item>Supprimer</button>
											</mat-menu>
										</td>
									</tr>

									<tr>
										<td class="px-6 py-4 whitespace-nowrap text-sm text-gray-500">
											5
										</td>
		
										<td class="px-6 py-4 whitespace-nowrap text-sm text-gray-500">
											ECO
										</td>
		
										<td class="px-6 py-4 whitespace-nowrap text-sm text-gray-500">
											Africa
										</td>
		
										<td class="px-6 py-4 whitespace-nowrap text-sm text-gray-500">
											Ivory Coast
										</td>
		
										<td class="px-6 py-4 whitespace-nowrap text-sm text-gray-500">
											<span class="inline-flex items-center justify-center px-2 py-1 text-xs font-bold leading-none text-white bg-yellow-400 rounded mr-5">Orange</span>
											<span class="inline-flex items-center justify-center px-2 py-1 text-xs font-bold leading-none text-white bg-yellow-400 rounded mr-5">MTN</span>
										</td>
		
										<td class="px-6 py-4 whitespace-nowrap text-sm text-gray-500">
											<button mat-button [matMenuTriggerFor]="menu">...</button>
											<mat-menu #menu="matMenu">
												<button mat-menu-item>Modifier</button>
												<button mat-menu-item>Supprimer</button>
											</mat-menu>
										</td>
									</tr>
									<!-- More people... -->
								</tbody>
							</table>
						</div>
					</div>
				</div>
			</div>
		</div>
	</div>
</div>
