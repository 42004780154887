<nav class="bg-transparent shadow-none">
	<div class="nav-wrapper">
		<div class="col s12">
			<a href="#" class="breadcrumb app-root text-gray-400">App</a>
			<a href="#" class="breadcrumb text-gray-400">Paramétres globales</a>
			<a href="#" class="breadcrumb text-gray-400">Paramétres généraux</a>
			<a href="#" class="breadcrumb text-gray-400">Devises</a>
		</div>
	</div>
</nav>

<div class="content pb-10">
	<div class="content-header bg-white rounded m-5 p-0">
		<div class="grid grid-cols-1 shadow-lg p-5">

			<div class="bg-white grid grid-cols-2 border-b-2 border-gray-200 pb-5">
				<h6 class="col-span-1">
					Currency configuration
				</h6>
			</div>

			<!-- This example requires Tailwind CSS v2.0+ -->
			<div class="flex flex-col">
				<div class="my-2 overflow-x-auto sm:-mx-6 lg:-mx-8">
					<div class="py-2 align-middle inline-block min-w-full sm:px-6 lg:px-8 grid grid-cols-3">

                        <div class="col-span-1 p-5">
							<span class="text-xs px-2 font-medium bg-gray-500 bg-opacity-10 text-gray-800 rounded 
								inline-block w-full py-3">
								Cote d'Ivoire
							</span>
							<div class="flex text-left">
								<div class="bg-white rounded-lg w-full">
									<ul class="">
										<li class="p-2 hover:bg-gray-50 cursor-pointer">
											Orange

											<div class="float-right relative inline-block w-10 mr-2 align-middle select-none transition duration-200 ease-in">
												<input
													type="checkbox"
													name="toggle"
													id="toggle"
													class="toggle-checkbox absolute block w-6 h-6 rounded-full
														bg-white border-4 appearance-none cursor-pointer" />
												<label
													for="toggle"
													class="toggle-label block overflow-hidden h-6 rounded-full bg-gray-300 cursor-pointer">
												</label>
											</div>
										</li>

										<li class="p-2 hover:bg-gray-50 cursor-pointer">
											MTN
											<div class="float-right relative inline-block w-10 mr-2 align-middle select-none transition duration-200 ease-in">
												<input
													type="checkbox"
													name="toggle"
													id="toggle"
													class="toggle-checkbox absolute block w-6 h-6 rounded-full
														bg-white border-4 appearance-none cursor-pointer" />
												<label
													for="toggle"
													class="toggle-label block overflow-hidden h-6 rounded-full bg-gray-300 cursor-pointer">
												</label>
											</div>
										</li>

										<li class="p-2 hover:bg-gray-50 cursor-pointer">
											Moov
											<div class="float-right relative inline-block w-10 mr-2 align-middle select-none transition duration-200 ease-in">
												<input
													type="checkbox"
													name="toggle"
													id="toggle"
													class="toggle-checkbox absolute block w-6 h-6 rounded-full
														bg-white border-4 appearance-none cursor-pointer" />
												<label
													for="toggle"
													class="toggle-label block overflow-hidden h-6 rounded-full bg-gray-300 cursor-pointer">
												</label>
											</div>
										</li>
									</ul>
								</div>
							</div>
                        </div>

						<div class="col-span-1 p-5">
							<span class="text-xs px-2 font-medium bg-gray-500 bg-opacity-10 text-gray-800 rounded 
								inline-block w-full py-3">
								Sénégal
							</span>
							<div class="flex text-left">
								<div class="bg-white rounded-lg w-full">
									<ul class="">
										<li class="p-2 hover:bg-gray-50 cursor-pointer">
											Orange

											<div class="float-right relative inline-block w-10 mr-2 align-middle select-none transition duration-200 ease-in">
												<input
													type="checkbox"
													name="toggle"
													id="toggle"
													class="toggle-checkbox absolute block w-6 h-6 rounded-full
														bg-white border-4 appearance-none cursor-pointer" />
												<label
													for="toggle"
													class="toggle-label block overflow-hidden h-6 rounded-full bg-gray-300 cursor-pointer">
												</label>
											</div>
										</li>

										<li class="p-2 hover:bg-gray-50 cursor-pointer">
											MTN
											<div class="float-right relative inline-block w-10 mr-2 align-middle select-none transition duration-200 ease-in">
												<input
													type="checkbox"
													name="toggle"
													id="toggle"
													class="toggle-checkbox absolute block w-6 h-6 rounded-full
														bg-white border-4 appearance-none cursor-pointer" />
												<label
													for="toggle"
													class="toggle-label block overflow-hidden h-6 rounded-full bg-gray-300 cursor-pointer">
												</label>
											</div>
										</li>

										<li class="p-2 hover:bg-gray-50 cursor-pointer">
											Moov
											<div class="float-right relative inline-block w-10 mr-2 align-middle select-none transition duration-200 ease-in">
												<input
													type="checkbox"
													name="toggle"
													id="toggle"
													class="toggle-checkbox absolute block w-6 h-6 rounded-full
														bg-white border-4 appearance-none cursor-pointer" />
												<label
													for="toggle"
													class="toggle-label block overflow-hidden h-6 rounded-full bg-gray-300 cursor-pointer">
												</label>
											</div>
										</li>
									</ul>
								</div>
							</div>
                        </div>

						<div class="col-span-1 p-5">
							<span class="text-xs px-2 font-medium bg-gray-500 bg-opacity-10 text-gray-800 rounded 
								inline-block w-full py-3">
								Mali
							</span>
							<div class="flex text-left">
								<div class="bg-white rounded-lg w-full">
									<ul class="">
										<li class="p-2 hover:bg-gray-50 cursor-pointer">
											Orange

											<div class="float-right relative inline-block w-10 mr-2 align-middle select-none transition duration-200 ease-in">
												<input
													type="checkbox"
													name="toggle"
													id="toggle"
													class="toggle-checkbox absolute block w-6 h-6 rounded-full
														bg-white border-4 appearance-none cursor-pointer" />
												<label
													for="toggle"
													class="toggle-label block overflow-hidden h-6 rounded-full bg-gray-300 cursor-pointer">
												</label>
											</div>
										</li>

										<li class="p-2 hover:bg-gray-50 cursor-pointer">
											MTN
											<div class="float-right relative inline-block w-10 mr-2 align-middle select-none transition duration-200 ease-in">
												<input
													type="checkbox"
													name="toggle"
													id="toggle"
													class="toggle-checkbox absolute block w-6 h-6 rounded-full
														bg-white border-4 appearance-none cursor-pointer" />
												<label
													for="toggle"
													class="toggle-label block overflow-hidden h-6 rounded-full bg-gray-300 cursor-pointer">
												</label>
											</div>
										</li>

										<li class="p-2 hover:bg-gray-50 cursor-pointer">
											Moov
											<div class="float-right relative inline-block w-10 mr-2 align-middle select-none transition duration-200 ease-in">
												<input
													type="checkbox"
													name="toggle"
													id="toggle"
													class="toggle-checkbox absolute block w-6 h-6 rounded-full
														bg-white border-4 appearance-none cursor-pointer" />
												<label
													for="toggle"
													class="toggle-label block overflow-hidden h-6 rounded-full bg-gray-300 cursor-pointer">
												</label>
											</div>
										</li>
									</ul>
								</div>
							</div>
                        </div>

						<div class="col-span-1 p-5">
							<span class="text-xs px-2 font-medium bg-gray-500 bg-opacity-10 text-gray-800 rounded 
								inline-block w-full py-3">
								Cameroun
							</span>
							<div class="flex text-left">
								<div class="bg-white rounded-lg w-full">
									<ul class="">
										<li class="p-2 hover:bg-gray-50 cursor-pointer">
											Orange

											<div class="float-right relative inline-block w-10 mr-2 align-middle select-none transition duration-200 ease-in">
												<input
													type="checkbox"
													name="toggle"
													id="toggle"
													class="toggle-checkbox absolute block w-6 h-6 rounded-full
														bg-white border-4 appearance-none cursor-pointer" />
												<label
													for="toggle"
													class="toggle-label block overflow-hidden h-6 rounded-full bg-gray-300 cursor-pointer">
												</label>
											</div>
										</li>

										<li class="p-2 hover:bg-gray-50 cursor-pointer">
											MTN
											<div class="float-right relative inline-block w-10 mr-2 align-middle select-none transition duration-200 ease-in">
												<input
													type="checkbox"
													name="toggle"
													id="toggle"
													class="toggle-checkbox absolute block w-6 h-6 rounded-full
														bg-white border-4 appearance-none cursor-pointer" />
												<label
													for="toggle"
													class="toggle-label block overflow-hidden h-6 rounded-full bg-gray-300 cursor-pointer">
												</label>
											</div>
										</li>

										<li class="p-2 hover:bg-gray-50 cursor-pointer">
											Moov
											<div class="float-right relative inline-block w-10 mr-2 align-middle select-none transition duration-200 ease-in">
												<input
													type="checkbox"
													name="toggle"
													id="toggle"
													class="toggle-checkbox absolute block w-6 h-6 rounded-full
														bg-white border-4 appearance-none cursor-pointer" />
												<label
													for="toggle"
													class="toggle-label block overflow-hidden h-6 rounded-full bg-gray-300 cursor-pointer">
												</label>
											</div>
										</li>
									</ul>
								</div>
							</div>
                        </div>

						<div class="col-span-1 p-5">
							<span class="text-xs px-2 font-medium bg-gray-500 bg-opacity-10 text-gray-800 rounded 
								inline-block w-full py-3">
								Tunisie
							</span>
							<div class="flex text-left">
								<div class="bg-white rounded-lg w-full">
									<ul class="">
										<li class="p-2 hover:bg-gray-50 cursor-pointer">
											Orange

											<div class="float-right relative inline-block w-10 mr-2 align-middle select-none transition duration-200 ease-in">
												<input
													type="checkbox"
													name="toggle"
													id="toggle"
													class="toggle-checkbox absolute block w-6 h-6 rounded-full
														bg-white border-4 appearance-none cursor-pointer" />
												<label
													for="toggle"
													class="toggle-label block overflow-hidden h-6 rounded-full bg-gray-300 cursor-pointer">
												</label>
											</div>
										</li>

										<li class="p-2 hover:bg-gray-50 cursor-pointer">
											MTN
											<div class="float-right relative inline-block w-10 mr-2 align-middle select-none transition duration-200 ease-in">
												<input
													type="checkbox"
													name="toggle"
													id="toggle"
													class="toggle-checkbox absolute block w-6 h-6 rounded-full
														bg-white border-4 appearance-none cursor-pointer" />
												<label
													for="toggle"
													class="toggle-label block overflow-hidden h-6 rounded-full bg-gray-300 cursor-pointer">
												</label>
											</div>
										</li>

										<li class="p-2 hover:bg-gray-50 cursor-pointer">
											Moov
											<div class="float-right relative inline-block w-10 mr-2 align-middle select-none transition duration-200 ease-in">
												<input
													type="checkbox"
													name="toggle"
													id="toggle"
													class="toggle-checkbox absolute block w-6 h-6 rounded-full
														bg-white border-4 appearance-none cursor-pointer" />
												<label
													for="toggle"
													class="toggle-label block overflow-hidden h-6 rounded-full bg-gray-300 cursor-pointer">
												</label>
											</div>
										</li>
									</ul>
								</div>
							</div>
                        </div>

					</div>

					<div class="py-2 align-middle inline-block min-w-full sm:px-6 lg:px-8 grid grid-cols-2">
						<div class="col-span-1">
							<button class="text-yellow-500 bg-transparent border border-solid 
								border-yellow-500 hover:bg-yellow-500 hover:text-white 
								active:bg-yellow-600 font-bold px-8 py-3 rounded-full 
								outline-none focus:outline-none mr-1 mb-1 ease-linear transition-all 
								duration-150 float-right mr-10"
								type="button">
								Annuler
						  </button>
						</div>

						<div class="col-span-1">
							<a routerLink="/home/general-settings/devises/validate"
								class="bg-yellow-500 text-white active:bg-yellow-600 font-bold
								text-base px-8 py-3 rounded-full shadow-md hover:shadow-lg outline-none 
								focus:outline-none mr-1 mb-1 ease-linear transition-all duration-150
								inline-block float-left">
								Suivant
							</a>
						</div>
					</div>

				</div>
			</div>
		</div>
	</div>
</div>
