import { Component, OnInit } from '@angular/core';

@Component({
	selector: 'config-currency',
	templateUrl: './config-currency.component.html',
	styleUrls: ['./config-currency.component.css'],
})

export class ConfigCurrencyComponent implements OnInit {
	constructor() {}
	ngOnInit(): void {}
}
