import { Component, OnInit } from '@angular/core';
import { TransactionService } from '@app/_services';
import { Transaction } from '@app/_models';
import { TransactionUtils } from '@app/utils/transactions.utils';

@Component({
	selector: 'app-conversion',
	templateUrl: './conversion.component.html',
	styleUrls: ['./conversion.component.css']
})

export class ConversionComponent implements OnInit {

	p: number = 1;

	successTransactions: Transaction[] = [];
	failedTransactions: Transaction[] = [];
	successPourcentage: number = 0;
	failsPourcentage: number = 0;
	transactions: Transaction[] = null;
	amountSum = 0;
	utils = new TransactionUtils();

	constructor(private transactionService: TransactionService) {}

	ngOnInit(): void {
		this.transactionService.transactionsSubject.subscribe(
			(transactions: any[]) => {
				this.transactions = transactions;

				this.successTransactions = this.transactions.filter(transaction => transaction.status.toLowerCase() === 'successful');
				this.failedTransactions = this.transactions.filter(transaction => transaction.status.toLowerCase() === 'failed');

				this.successPourcentage = this.successTransactions.length / this.transactions.length * 100;
				this.failsPourcentage = this.failedTransactions.length / this.transactions.length * 100;

				this.amountSum = this.utils.getAmountSumTransactions(this.transactions);
			}
		);
	}

	isMoney() {
		return true;
	}

}
