import { Injectable } from '@angular/core';
import { Router } from '@angular/router';
import { HttpClient, HttpParams, HttpHeaders } from '@angular/common/http';
import { BehaviorSubject, Observable } from 'rxjs';
import { map } from 'rxjs/operators';
import { environment } from '../../environments/environment';
import { User } from '../_models/index';
import { Subject } from "rxjs";

@Injectable({ providedIn: 'root' })
export class AccountService {

    private userSubject: BehaviorSubject<User>;
    public user: Observable<User>;
    public selectedService: Observable<string>;
    private users: User[];
    usersSubject = new Subject<User[]>();
    serviceSubject = new Subject<string>();

    constructor(
        private router: Router,
        private http: HttpClient
    ) {
        this.userSubject = new BehaviorSubject<User>(JSON.parse(localStorage.getItem('user')));
        this.serviceSubject = new BehaviorSubject<string>(localStorage.getItem('service'));
        this.user = this.userSubject.asObservable();
        this.selectedService = this.serviceSubject.asObservable();
    }

    public get userValue(): User {
        return this.userSubject.value;
    }

    login(email, password) {
        const body = new HttpParams()
        .set('email', email)
        .set('password', password);

        return this.http.post<User>(`${environment.api}/user-manager/auth/login`, body.toString(), { headers: new HttpHeaders().set('Content-Type', 'application/x-www-form-urlencoded')})
            .pipe(map(user => {
                // store user details and jwt token in local storage to keep user logged in between page refreshes
                // let decoded:any = jwt_decode(user['data'].access_token);
                let userConnected = new User(
                    user['data']['user']['id'],
                    user['data']['user']['firstName'],
                    user['data']['user']['lastName'],
                    user['data']['user']['email'],
                    user['data']['user']['address'],
                    user['data']['user']['status'],
                    user['data']['user']['partnerId'],
                    user['data']['user']['phoneNumber'],
                    user['data']['user']['profile'],
                    user['data']['user']['partner'],
                    user['data']['user']['connectionType'],
                    user['data'].access_token
                );
                localStorage.setItem('userDecoded', JSON.stringify(userConnected));
                localStorage.setItem('user', JSON.stringify(userConnected));
                this.userSubject.next(userConnected);
                return user;
            }));
    }

    setSelectedService(service: string) {
        localStorage.setItem('service', service);
        this.serviceSubject.next(service);
    }

    logout() {
        // remove user from local storage and set current user to null
        localStorage.removeItem('user');
        localStorage.removeItem('service');
        this.userSubject.next(null);
        this.serviceSubject.next(null);
        this.router.navigate(['/home/user/login']);
    }

    register(user: User) {
        user.status = true;
        return this.http.post(`${environment.api}/user-manager/users`, user);
    }

    emitUsers(users) {
        this.users = users;
        this.usersSubject.next(users.slice());
    }

    getAll() {
        return this.users;
    }

    fetchAll() {
        return this.http.get<User[]>(`${environment.api}/user-manager/users`);
    }

    getById(id: string) {
        return this.http.get<User>(`${environment.api}/user-manager/user/${id}`);
    }

    getConnectedUserDetails() {
      let user = JSON.stringify(localStorage.getItem('user'));
        return this.http.get<any>(`${environment.api}/user-manager/users/me${user['id']}`);
    }

    update(id, params) {
        params.zipCode = "14000";
        params.city = "Dakar";
        return this.http.put(`${environment.api}/user-manager/users/${id}`, params)
            .pipe(map(x => {
                // update stored user if the logged in user updated their own record
                if (id == this.userValue.id) {
                    // update local storage
                    const user = { ...this.userValue, ...params };
                    localStorage.setItem('user', JSON.stringify(user));

                    // publish updated user to subscribers
                    this.userSubject.next(user);
                }
                return x;
            }));
    }

    delete(id: number) {
        return this.http.delete(`${environment.api}/user-manager/users/${id}`)
            .pipe(map(x => {
                // auto logout if the logged in user deleted their own record
                /* if (id == this.userValue.id) {
                    this.logout();
                } */
                return x;
            }));
    }

    pushUser(user: User) {
        this.users.push(user);
        this.emitUsers(this.users);
    }

    getUsers() {
        return this.users;
    }

    setUsers(users: User[]) {
        this.users = users;
    }

    /*
    * Get all partners
    */
    getAllConnectionType(){
      return ["email", "sms", "link"];
    }
}
