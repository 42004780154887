import { Component, OnInit } from '@angular/core';

@Component({
	selector: 'offer-bizao',
	templateUrl: './offer.component.html'
})

export class OfferBizaoComponent implements OnInit {
	constructor() {}

	ngOnInit(): void {}
}
