import { Component } from '@angular/core';
import { MatDialog } from '@angular/material/dialog';
import { AddMnoEme } from './new-mno-eme/add-alert.component';

@Component({
	selector: 'add-mno',
	templateUrl: './add-mno.component.html'
})

export class AddMno {

    constructor(public dialog: MatDialog) {}

	ngOnInit(): void {}

	openDialog() {
		const dialogRef = this.dialog.open(AddMnoEme);

		dialogRef.afterClosed().subscribe(result => {
		    console.log(`Dialog result: ${result}`);
		});
	}
}
