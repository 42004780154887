export let AppConfig = {
    menus : [
        {
            id: 1,
            menu : 'dashboard',
            label: 'Dashboard',
            submenu: [
                {
                    menu : 'global',
                    label: 'Global',
                },
                {
                    menu : 'trafic',
                    label: 'Trafic',
                },
                {
                    menu : 'revenue',
                    label: 'Revenue',
                },
                {
                    menu : 'transaction-details',
                    label: 'Transactiond details',
                },
                {
                    menu : 'conversion',
                    label: 'Conversion',
                }
            ]
        },
        {
            id: 2,
            menu : 'parametrage-global',
            label: 'Parametrage général',
            submenu: [
                {
                    menu : 'profiles',
                    label: 'Profiles',
                },
                {
                    menu : 'parametrage-general',
                    label: 'Parametrage general',
                }
            ]
        },
        {
            id: 3,
            menu : 'utilisateur',
            label: 'Utilisateur',
            submenu: [
                {
                    menu : 'utilisateur',
                    label: 'Utilisateur',
                }
            ]
        },
        {
            id: 4,
            menu : 'partenaires',
            label: 'Partenaires',
            submenu: [
                {
                    menu : 'partenaires',
                    label: 'Partenaires',
                },
                {
                    menu : 'configuration-partenaires',
                    label: 'Configuration partenaires',
                },
                {
                    menu : 'workflow',
                    label: 'Workflow',
                }
            ]
        },
        {
            id: 5,
            menu : 'operations',
            label: 'Opérations',
            submenu: [
                {
                    menu : 'releve-compte',
                    label: 'Releve compte',
                },
                {
                    menu : 'decaissement',
                    label: 'Décaissement',
                },
                {
                    menu : 'transfert-compte-compte',
                    label: 'Transfert compte à compte',
                },
                {
                    menu : 'emission-ordre',
                    label: 'Emission ordre',
                },
                {
                    menu : 'provisioning-comptes-pricipaux',
                    label: 'Provisioning comptes pricipaux',
                },
                {
                    menu : 'provisioning-comptes-avance',
                    label: 'Provisioning comptes avance',
                },
                {
                    menu : 'service-client',
                    label: 'Service client',
                },
                {
                    menu : 'access-token',
                    label: 'Access token',
                }
            ]
        }
    ],
    countries: [
        {
            code: 'sn',
            name: 'Sénégal',
            mno: [
                {
                    name: 'Free sn',
                    selected: false
                },
                {
                    name: 'Orange sn',
                    selected: false
                },
                {
                    name: 'Expresso sn',
                    selected: false
                }
            ],
            currency: 'xof'
        },
        {
            code: 'ci',
            name: 'Cote d\'Ivoire',
            mno: [
                {
                    name: 'Moov ci',
                    selected: false
                },
                {
                    name: 'Orange ci',
                    selected: false
                },
                {
                    name: 'MTN ci',
                    selected: false
                }
            ],
            currency: 'xof'
        },
        {
            code: 'ml',
            name: 'Mali',
            mno: [
                {
                    name: 'Free ml',
                    selected: false
                },
                {
                    name: 'Orange ml',
                    selected: false
                },
                {
                    name: 'Malitel',
                    selected: false
                }
            ],
            currency: 'xof'
        },
        {
            code: 'fr',
            name: 'France',
            mno: [
                {
                    name: 'Free fr',
                    selected: false
                },
                {
                    name: 'Orange fr',
                    selected: false
                },
                {
                    name: 'SFR',
                    selected: false
                }
            ],
            currency: 'euro'
        }
    ]
}
