import { Subject } from "rxjs";

export class MenuState {
    private status: boolean = true;
    menuSubject = new Subject<boolean>();

    emitMenu() {
        this.menuSubject.next(this.status);
    }

    getStatus() {
        return this.status;
    }

    setStatus(status: boolean) {
        this.status = status;
        this.emitMenu();
    }
}
