import { Component, OnInit } from '@angular/core';
import { NgbDate, NgbCalendar, NgbDateParserFormatter } from '@ng-bootstrap/ng-bootstrap';
import { TransactionService } from '@app/_services/transaction.service';
import { Subscription } from 'rxjs';
import moment from 'moment';
import countries_code from '../../../../../../utils/countries_code.json'
import { Transaction } from '@app/_models/transaction.model';

@Component({
	selector: 'app-filter',
	templateUrl: './filter.component.html',
	styleUrls: ['./filter.component.css']
})

export class FilterComponent implements OnInit {

	hoveredDate: NgbDate | null = null;
	routerId = '';
	referenceNumber = '';
	email = '';
	numTel = '';

	partnerId = '';
	merchantReference = '';
	operator = '';
	fromCountry = '';
	toCountry = '';
	successTrafic = '';
	failTrafic = '';
	status = '';

	fromDate: NgbDate | null;
	toDate: NgbDate | null;
	transactionSubscription: Subscription;
	transactions: Transaction[];
	start = '';
	end = '';

	partners = [];
	merchants = [];
	operators = [];
	countries = [];

    country = countries_code;
    
	filterContent: Boolean = false;
	selected: {start: any, end: any} = {
		start: moment().subtract(6, "days"), end: moment()
	};

	constructor(private calendar: NgbCalendar,
		public formatter: NgbDateParserFormatter,
		private transactionService: TransactionService) {

		// #Tuning
		let startMonth = moment(this.selected.start).month() + 1;
        let strStartMonth = startMonth < 10 ? '0' + startMonth : startMonth;
        let startDate = moment(this.selected.start).date();
        let strStartDate = startDate < 10 ? '0' + startDate : startDate;

        let endMonth = moment(this.selected.end).month() + 1;
        let strEndMonth = endMonth < 10 ? '0' + endMonth : endMonth;
        let endDate = moment(this.selected.end).date() + 1;
        let strEndDate = endDate < 10 ? '0'+ endDate : endDate;

        this.start = `${this.selected.start.year()}-${strStartMonth}-${strStartDate}`;
        this.end = `${this.selected.end.year()}-${strEndMonth}-${strEndDate}`;

		this.transactionService.setWeekRange({
			firstday: this.start,
			lastday: this.end,
			partnerId: this.partnerId,
			merchantReference: this.merchantReference,
			operator: this.operator,
			fromCountry: this.fromCountry,
			toCountry: this.toCountry,
			successTrafic: this.successTrafic,
			failTrafic: this.failTrafic
		});

		this.transactionSubscription = this.transactionService.transactionsSubject.subscribe(
			(transactions: any[]) => {
				this.transactions = transactions;
				this.getFilters();
			}
		);
	}

	ngOnInit(): void {}

	onDateSelection(date: NgbDate) {
		if (!this.fromDate && !this.toDate) {
			this.fromDate = date;
		} else if (this.fromDate && !this.toDate && date && date.after(this.fromDate)) {
			this.toDate = date;
		} else {
			this.toDate = null;
			this.fromDate = date;
		}
	}

	isHovered(date: NgbDate) {
		return this.fromDate && !this.toDate && this.hoveredDate && date.after(this.fromDate) && date.before(this.hoveredDate);
	}

	isInside(date: NgbDate) {
		return this.toDate && date.after(this.fromDate) && date.before(this.toDate);
	}

	isRange(date: NgbDate) {
		return date.equals(this.fromDate) || (this.toDate && date.equals(this.toDate)) || this.isInside(date) || this.isHovered(date);
	}

	validateInput(currentValue: NgbDate | null, input: string): NgbDate | null {
		const parsed = this.formatter.parse(input);
		return parsed && this.calendar.isValid(NgbDate.from(parsed)) ? NgbDate.from(parsed) : currentValue;
	}

	showFilterContent() {
		this.filterContent = !this.filterContent;
	}

	datesUpdated(e: any) {
		// #Tuning
		if (e.startDate) {
			let startMonth = e.startDate.$M + 1 < 10 ? '0' + (e.startDate.$M + 1) : e.startDate.$M + 1;
			let startDate = e.startDate.$D < 10 ? '0' + e.startDate.$D : e.startDate.$D;

			let endMonth = e.endDate.$M + 1 < 10 ? '0' + (e.endDate.$M + 1) : e.endDate.$M + 1;
			let endDate = e.endDate.$D < 10 ? '0' + e.endDate.$D : e.endDate.$D;

			let start = `${e.startDate.$y}-${startMonth}-${startDate}`;
			let end = `${e.endDate.$y}-${endMonth}-${endDate}`;

			this.transactionService.setWeekRange({
				firstday: start,
				lastday: end,
				partnerId: this.partnerId,
				merchantReference: this.merchantReference,
				operator: this.operator,
				fromCountry: this.fromCountry,
				toCountry: this.toCountry,
				successTrafic: this.successTrafic,
				failTrafic: this.failTrafic
			});
		}
	}

	getFilters() {
		this.transactions.forEach(transaction => {
			// console.log('transaction : ', transaction);
			!this.partners.includes(transaction.serviceProvider) ? this.partners.push(transaction.serviceProvider) : null;
			!this.operators.includes(transaction.mnoName) ? this.operators.push(transaction.mnoName) : null;

			!this.countries.includes(transaction.countryCode) ? this.countries.push(transaction.countryCode) : null;
			!this.countries.includes(transaction.fromCountryCode) ? this.countries.push(transaction.fromCountryCode) : null;
			!this.countries.includes(transaction.toCountryCode) ? this.countries.push(transaction.toCountryCode) : null;
		});
	}

	onFilterChange(value, filter) {
		if (filter == "from-country") {
			this.fromCountry = value;
		} else if (filter == "partner") {
			this.partnerId = value;
		} else if (filter == "mno") {
			this.operator = value;
		} else if (filter == "to-country") {
			this.toCountry = value;
		} else if (filter == "status") {
			this.status = value;
		}

		this.transactionService.applyFilter({
			firstday: this.start,
			lastday: this.end,
			partnerId: this.partnerId,
			merchantReference: this.merchantReference,
			operator: this.operator,
			fromCountry: this.fromCountry,
			toCountry: this.toCountry,
			successTrafic: this.successTrafic,
			failTrafic: this.failTrafic,
			status: this.status
		});
	}
}
