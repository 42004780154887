<div class="flex flex-col">
	<div class="my-2 overflow-x-auto sm:-mx-6 lg:-mx-8">
		<div class="py-2 align-middle inline-block min-w-full sm:px-6 lg:px-8 grid grid-cols-6 gap-4">

			<div class="col-span-6 pb-5 pl-10 border-gray-200 border-b relative">
                <h6>
                    Api configuration : {{ currentPartnerName }}
                </h6>

				<h6 class="text-blak text-right absolute right-5 top-1 font-bold px-5 cursor-pointer"
					(click)="closeDialog()">
					x
				</h6>
            </div>

			<div class="col-start-2 col-span-4 pt-5">

				<div class="grid grid-cols-4">

					<div class="col-span-4 text-left pr-5 font-semibold">
						Please confirm the MNO
					</div>

					<div class="col-span-4 grid grid-cols-4 text-left pr-5 mt-5 font-semibold">

						<div class="col-span-2">
							Country : {{ countryArray[indexCountry].name }}
						</div>

						<div class="col-span-2 text-right">
							<img src="../../../../../../assets/icones/previous.png" class="inline cursor-pointer" width="25px" alt="Previous" (click)='previous()'/>
							<img src="../../../../../../assets/icones/next.png" class="inline cursor-pointer" width="25px" alt="Next" (click)='next()'/>
						</div>
					</div>

					<div class="col-span-4 py-2 border-gray-200 border-b mt-5" *ngFor="let mno of countryArray[indexCountry].mno; let i = index">
						<label class="inline-flex items-center">
							<input type="checkbox" class="form-checkbox" value="{{ countryArray[indexCountry].code + '-' + mno.name }}" (change)="checkCountryValue($event)" [checked]="mno.selected"/>
							<span class="ml-2">{{ mno.name }}</span>
						</label>
					</div>

					<div class="col-span-4 text-center pb-5">
						<button class="bg-white-500 text-black shadow-lg font-bold py-2 px-10 rounded-full mt-5 mr-5" (click)="openDialogPrevious()">
							Précédent
						</button>

						<button class="bg-yellow-500 text-white shadow-lg font-bold py-2 px-10 rounded-full mt-5" (click)="openDialogNext()">
							Suivant
						</button>
					</div>

				</div>

			</div>
		</div>
	</div>
</div>
