<nav class="bg-transparent shadow-none">
	<div class="nav-wrapper">
		<div class="col s12">
			<a href="#" class="breadcrumb app-root text-gray-400">App</a>
			<a href="#" class="breadcrumb text-gray-400">Offer</a>
			<a href="#" class="breadcrumb text-gray-400">Add</a>
		</div>
	</div>
</nav>

<div class="content">
	<div class="content-header bg-white rounded m-5 p-0">
		<div class="grid grid-cols-1 shadow-lg p-5">

			<div class="bg-white grid grid-cols-2 border-b-2 border-gray-200 pb-5">
				<h6 class="col-span-1">
					Currency configuration
				</h6>
			</div>

			<!-- This example requires Tailwind CSS v2.0+ -->
			<div class="flex flex-col">
				<div class="my-2 overflow-x-auto sm:-mx-6 lg:-mx-8">
					<div class="py-2 align-middle inline-block min-w-full sm:px-6 lg:px-8 grid grid-cols-6 gap-4">

                        <div class="col-start-2 col-span-4 pt-20">

                            <div class="grid grid-cols-4">
                                <div class="col-span-1 text-right pt-5 pr-5">
                                    <span class="text-gray-700">Pays</span>
                                </div>
                                <div class="col-span-3">
                                    <input type="text" placeholder="Nom de l'offre" />
                                </div>

                                <div class="col-span-1 text-right pt-5 pr-5 mt-5">
                                    <span class="text-gray-700">Continent</span>
                                </div>
                                <div class="col-span-3 mt-5">
                                    <textarea cols="50" rows="10" class="border"> Lorem ipsum dolor sit amet, consectetur adipiscing elit. Ut nec dignissim ante. Vestibulum mollis, tortor at blandit pharetra, libero sapien finibus purus, nec blandit mauris dolor vitae purus. Nulla ligula velit, dignissim id semper at, euismod tempus odio. Fusce interdum diam nec lorem tempor, et ultrices ligula ornare. Ut id feugiat justo.
                                    </textarea>
                                </div>

                                <div class="col-span-1 text-right pt-5 pr-5 mt-5">
                                </div>
                                <div class="col-span-3 mt-5">
                                    <p class="mt-10"> Please select the configuration url applicable to thi offer : </p>
                                    <div class="mt-5">
                                        <input type="checkbox" class="opacity-100"/>
                                        <span>
                                            Callback URL
                                        </span>
                                    </div>
                                    <div class="mt-5">
                                        <input type="checkbox" class="opacity-100"/>
                                        <span>
                                            Cancel URL
                                        </span>
                                    </div>
                                    <div class="mt-5">
                                        <input type="checkbox" class="opacity-100"/>
                                        <span>
                                            Return URL
                                        </span>
                                    </div>
                                </div>

                            </div>

                            <div class="col-span-3 grid grid-cols-2 mt-20">
                                <div class="col-span-1">
                                    <button class="text-yellow-500 bg-transparent border border-solid 
                                        border-yellow-500 hover:bg-yellow-500 hover:text-white 
                                        active:bg-yellow-600 font-bold px-8 py-3 rounded-full 
                                        outline-none focus:outline-none mr-1 mb-1 ease-linear transition-all 
                                        duration-150 float-right mr-10"
                                        type="button">
                                        Annuler
                                  </button>
                                </div>

                                <div class="col-span-1">
                                    <a routerLink="/home/general-settings/offer"
                                        class="bg-yellow-500 text-white active:bg-yellow-600 font-bold
                                        text-base px-8 py-3 rounded-full shadow-md hover:shadow-lg outline-none 
                                        focus:outline-none mr-1 mb-1 ease-linear transition-all duration-150
                                        inline-block">
                                        Validate
                                    </a>
                                </div>
                            </div>
                        </div>
					</div>
				</div>
			</div>
		</div>
	</div>
</div>
