import { Component, Input, OnInit } from '@angular/core';
import { MatDialog, MatDialogConfig } from '@angular/material/dialog';
import { AddNewOfferComponent } from '../add-new-offer/add-new-offer.component';
import { PartnerService } from '@app/_services';

@Component({
	selector: 'modal',
	templateUrl: 'modal.component.html',
})

export class ModalPartnerComponent implements OnInit {

	partnerName: string;
	partnerType: string;
	partnerContact: string;

	@Input() isEditingPartner: boolean;

	constructor(public dialog: MatDialog, private partnerService: PartnerService) {}

	ngOnInit(): void {
		this.partnerName = this.partnerService.getCurrentPartner().getName();
		this.partnerType = this.partnerService.getCurrentPartner().getType();
		this.partnerContact = this.partnerService.getCurrentPartner().getContact();
	}

	closeDialog() {
		this.dialog.closeAll();
	}

	openDialog() {
		this.partnerService.getCurrentPartner().setName(this.partnerName);
		this.partnerService.getCurrentPartner().setType(this.partnerType);
		this.partnerService.getCurrentPartner().setContact(this.partnerContact);

		const dialogConfig = new MatDialogConfig();

        dialogConfig.disableClose = true;
        dialogConfig.autoFocus = true;

		this.dialog.closeAll();

		const dialogRef = this.dialog.open(AddNewOfferComponent, dialogConfig);
		dialogRef.componentInstance.isEditingPartner = this.isEditingPartner;
	}

}
