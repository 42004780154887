import { Component, OnInit } from '@angular/core';
import { MenuState, AccountService } from '@app/_services';
import { TranslateService } from '@ngx-translate/core';
import { User } from '@app/_models';

@Component({
	selector: 'app-sidenav',
	templateUrl: './sidenav.component.html',
	styleUrls: ['./sidenav.component.css']
})

export class SidenavComponent implements OnInit {
	status: boolean;
	selectedService: string;
	user: User;

	services = {
		'dcb': 'DCB',
		'mobile-money': 'Mobile money',
		'bulk': 'Bulk',
		'mobile-transfer': 'Mobile Tranfer'
	};

	constructor(
		private translate: TranslateService,
		private accountService: AccountService,
		private menuService: MenuState
	) {
		translate.setDefaultLang('en');
		this.accountService.user.subscribe(x => this.user = x);
		this.accountService.selectedService.subscribe(x => {
			console.log('this.selectedService : ', x);
			this.selectedService = x;
		});
	}

	ngOnInit(): void {
		this.status = this.menuService.getStatus();
	}

	sidebarHandler() {
		this.menuService.setStatus(!this.menuService.getStatus());
		this.status = this.menuService.getStatus();
	}

	selectService(service: string) {
		this.selectedService = service;
		this.accountService.setSelectedService(service);
	}
}
