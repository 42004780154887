import { NgModule } from '@angular/core';
import { Routes, RouterModule } from '@angular/router';

import { LayoutComponent } from '@app/components/layout/layout.component';
import { BankDepositComponent } from './bank-deposit/bank-deposit.component';
import { AddDepositComponent } from './bank-deposit/add/add-deposit.component';
import { RepaymentComponent } from './repayment/repayment.component';
import { ValidationComponent } from './validation/validation.component';

const routes: Routes = [
    {
        path: '', component: LayoutComponent,
        children: [
            { path: 'bank-deposit', component: BankDepositComponent },
            { path: 'bank-deposit/add', component: AddDepositComponent },
            { path: 'repayment', component: RepaymentComponent },
            { path: 'deposit-validation', component: ValidationComponent }
        ]
    }
];

@NgModule({
    imports: [RouterModule.forChild(routes)],
    exports: [RouterModule]
})
export class OrderEmissionRoutingModule { }
