import { BrowserModule } from '@angular/platform-browser';
import { ReactiveFormsModule, FormsModule } from '@angular/forms';
import { CommonModule } from '@angular/common';  
import { APP_INITIALIZER, NgModule, CUSTOM_ELEMENTS_SCHEMA } from '@angular/core';
import { SuiModule } from 'ng2-semantic-ui';
import { BrowserAnimationsModule } from '@angular/platform-browser/animations'; 

import { AppRoutingModule } from './app-routing.module';
import { AppComponent } from './app.component';
import { HeaderComponent } from './components/header/header.component';
import { MainWrapperComponent } from './components/main-wrapper/main-wrapper.component';
import { SidenavComponent } from './components/main-wrapper/sidenav/sidenav.component';
import { ContentContainerComponent } from './components/main-wrapper/content-container/content-container.component';
import { FooterComponent } from './components/main-wrapper/content-container/footer/footer.component';
import { DashboardGlobalComponent } from './components/main-wrapper/content-container/dashboard/dashboard-global/dashboard-global.component';
import { RevenueComponent } from './components/main-wrapper/content-container/dashboard/revenue/revenue.component';
import { TrafficComponent } from './components/main-wrapper/content-container/dashboard/traffic/traffic.component';
import { TransactionsDetailsComponent } from './components/main-wrapper/content-container/dashboard/transactions-details/transactions-details.component';

import { ModalDetails } from './components/main-wrapper/content-container/dashboard/transactions-details/modal-details/details.component';

import { PartnersManagementComponent } from './components/main-wrapper/content-container/partners-management/partners-management.component';
import { ModalPartnerComponent } from './components/main-wrapper/content-container/partners-management/add-partner-modal/modal.component';
import { AddNewOfferComponent } from './components/main-wrapper/content-container/partners-management/add-new-offer/add-new-offer.component';
import { ApiConfiguration } from './components/main-wrapper/content-container/partners-management/api-configuration/api-configuration.component';
import { SelectMnoComponent } from './components/main-wrapper/content-container/partners-management/select-mno/select-mno.component';
import { InformationsComponent } from './components/main-wrapper/content-container/partners-management/informations/informations.component';
import { DeleteAlert } from './components/main-wrapper/content-container/partners-management/delete/delete.component';

import { GeneralSettingsModule } 
	from './components/main-wrapper/content-container/global-settings/general-settings/general-setting.module';
import { PartnerSettingsModule } 
	from './components/main-wrapper/content-container/partners-settings/partner-setting.module';

import { OrderEmissionModule } 
	from './components/main-wrapper/content-container/order-emission/order-emission.module';

import { ClientServiceModule } 
	from './components/main-wrapper/content-container/client-service/client-service.module';

import { ProfilsModule } from './components/main-wrapper/content-container/global-settings/profils/profils.module';
import { UserManagementModule } from './components/main-wrapper/content-container/user-management/user-management.module';

import { AccountsStatementsComponent } from './components/main-wrapper/content-container/accounts-statements/accounts-statements.component';
import { PushComponent } from './components/main-wrapper/content-container/push/push.component';
import { PartnersProvisionningComponent } from './components/main-wrapper/content-container/partners-provisionning/partners-provisionning.component';
import { DataContainerPrototypeComponent } from './components/data-container-prototype/data-container-prototype.component';
import { LoginPageComponent } from './components/login-page/login-page.component';
import { HomeComponent } from './components/home/home.component';
import { LayoutComponent } from './components/layout/layout.component';
import { MatDialogModule } from '@angular/material/dialog';
import { MatMenuModule } from '@angular/material/menu';
import { MatButtonModule } from '@angular/material/button';

import { ModalComponent } from './components/_helper/modal/modal.component';
import { CancelButtonComponent } from './components/_helper/cancel-button/cancel-button.component';

// import ngx-translate and the http loader
import { TranslateLoader, TranslateModule } from '@ngx-translate/core';
import { TranslateHttpLoader } from '@ngx-translate/http-loader';
import { HttpClient, HttpClientModule, HTTP_INTERCEPTORS } from '@angular/common/http';
import { HashLocationStrategy, LocationStrategy } from '@angular/common';

import { ChartComponent } from '@app/components/main-wrapper/content-container/dashboard/dashboard-global/chart/chart.component';
import { TransactionTableComponent } from '@app/components/main-wrapper/content-container/dashboard/dashboard-global/transaction-table/transaction-table.component';
import { AmountCardComponent } from '@app/components/main-wrapper/content-container/dashboard/dashboard-global/amount-card/amount-card.component';

import { ChartsModule } from 'ng2-charts';
import { BizaoInterceptor, ErrorInterceptor } from '@app/_helpers';
import { DisbursementComponent } from '@app/components/main-wrapper/content-container/disbursement/disbursement.component';
import { AccountToAccountTransferComponent } from '@app/components/main-wrapper/content-container/account-to-account-transfer/account-to-account-transfer.component';
import { ConversionComponent } from '@app/components/main-wrapper/content-container/dashboard/conversion/conversion.component';

import { FilterComponent } from '@app/components/main-wrapper/content-container/dashboard/dashboard-global/filter/filter.component';

import { AlertComponent } from '@app/components/_helper/alert/alert.component';

import { UiSwitchModule } from 'ngx-ui-switch';
import { NgxDaterangepickerMd } from 'ngx-daterangepicker-material';

import { NgbPopoverModule, NgbDropdownModule, NgbCollapseModule, 
	NgbPaginationModule, NgbModalModule, NgbDatepickerModule, 
	NgbNavModule, NgbAccordionModule, NgbAlertModule
   } from '@ng-bootstrap/ng-bootstrap';


import { NgxPaginationModule } from 'ngx-pagination'; // <-- import the module

// Imported Syncfusion Switch module from buttons package.
import { SwitchModule } from '@syncfusion/ej2-angular-buttons';
import { MenuState } from './_services';

@NgModule({
	declarations: [
		AppComponent,
		HeaderComponent,
		MainWrapperComponent,
		SidenavComponent,
		ContentContainerComponent,
		FooterComponent,
		DashboardGlobalComponent,
		RevenueComponent,
		TrafficComponent,
		FilterComponent,
		TransactionsDetailsComponent,
		PartnersManagementComponent,
		ModalPartnerComponent,
		AddNewOfferComponent,
		ApiConfiguration,
		SelectMnoComponent,
		InformationsComponent,
		DeleteAlert,
		AccountsStatementsComponent,
		PushComponent,
		PartnersProvisionningComponent,
		DataContainerPrototypeComponent,
		LoginPageComponent,
		HomeComponent,
		ChartComponent,
		TransactionTableComponent,
		AmountCardComponent,
		LayoutComponent,
		ModalComponent,
		CancelButtonComponent,
		DisbursementComponent,
		AccountToAccountTransferComponent,
		ConversionComponent,
		AlertComponent,
		ModalDetails
	],
	imports: [
		CommonModule,
		BrowserModule,
		FormsModule,
		ReactiveFormsModule,
		AppRoutingModule,
		ChartsModule,
		SuiModule,
		MatDialogModule,
		MatMenuModule,
		MatButtonModule,
		// ngx-translate and the loader module
		HttpClientModule,
		TranslateModule.forRoot({
			loader: {
				provide: TranslateLoader,
				useFactory: HttpLoaderFactory,
				deps: [HttpClient],
			},
		}),
		BrowserAnimationsModule,
		PartnerSettingsModule,
		GeneralSettingsModule,
		OrderEmissionModule,
		ClientServiceModule,
		ProfilsModule,
		SwitchModule,
		UserManagementModule,
		UiSwitchModule.forRoot({
            size: 'small',
            color: 'rgb(0, 189, 99)',
            switchColor: '#1ba231',
            defaultBgColor: '#b9e4c0',
            defaultBoColor : '#b9e4c0',
            checkedLabel: 'on',
			uncheckedLabel: 'off',
			checkedTextColor: '#fff',
			uncheckedTextColor: '#fff'
        }),
		NgxPaginationModule,
		NgxDaterangepickerMd.forRoot(),
		NgbPopoverModule,
		NgbDropdownModule,
		NgbCollapseModule,
		NgbPaginationModule,
		NgbModalModule,
		NgbDatepickerModule,
		NgbNavModule,
		NgbAccordionModule,
		NgbAlertModule
	],
	exports: [MatDialogModule, CancelButtonComponent],
	providers: [
		{ provide: LocationStrategy, useClass: HashLocationStrategy },
		{ provide: HTTP_INTERCEPTORS, useClass: BizaoInterceptor, multi: true },
		{ provide: HTTP_INTERCEPTORS, useClass: ErrorInterceptor, multi: true },
		MenuState
	],
	entryComponents: [
		ModalComponent
	],
	bootstrap: [AppComponent],
	schemas: [
		CUSTOM_ELEMENTS_SCHEMA
	],
})
export class AppModule { }

// required for AOT compilation
export function HttpLoaderFactory(http: HttpClient): TranslateHttpLoader {
	return new TranslateHttpLoader(http);
}
