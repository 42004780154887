import { Component, Input } from '@angular/core';
import { Transaction } from '@app/_models';

@Component({
	selector: 'app-details',
	templateUrl: './details.component.html',
    styleUrls: ['./details.component.css']
})

export class ModalDetails {

    @Input() transaction: Transaction;

    constructor() { }

	ngOnInit() {
        console.log('transaction : ', this.transaction);
    }

}
