<nav class="bg-transparent shadow-none">
	<div class="nav-wrapper">
		<div class="col s12">
			<a href="#" class="breadcrumb app-root text-gray-400">App</a>
			<a href="#" class="breadcrumb text-gray-400">Paramétres globales</a>
			<a href="#" class="breadcrumb text-gray-400">Paramétres généraux</a>
			<a href="#" class="breadcrumb text-gray-400">Devises</a>
		</div>
	</div>
</nav>

<div class="content pb-10">
	<div class="content-header bg-white rounded m-5 p-0">
		<div class="grid grid-cols-1 shadow-lg p-5">

			<div class="bg-white grid grid-cols-2 border-b-2 border-gray-200 pb-5">
				<h6 class="col-span-1">
					Configuration to be validated
				</h6>
			</div>

			<!-- This example requires Tailwind CSS v2.0+ -->
			<div class="flex flex-col">
				<div class="my-2 overflow-x-auto sm:-mx-6 lg:-mx-8">
					<div class="py-2 align-middle inline-block min-w-full sm:px-6 lg:px-8">
						
                        <div class="shadow overflow-hidden border-b border-gray-200 sm:rounded-lg">
							<table class="min-w-full divide-y divide-gray-200">
								<thead class="bg-gray-50">
									<tr>
										<th scope="col"
											class="px-6 py-3 text-left text-xs font-medium text-gray-500 uppercase tracking-wider">
											#
										</th>
		
										<th scope="col"
											class="px-6 py-3 text-left text-xs font-medium text-gray-500 uppercase tracking-wider">
											Continent
										</th>
		
										<th scope="col"
											class="px-6 py-3 text-left text-xs font-medium text-gray-500 uppercase tracking-wider">
											Country
										</th>

										<th scope="col"
											class="px-6 py-3 text-left text-xs font-medium text-gray-500 uppercase tracking-wider">
											MNO
										</th>

									</tr>
								</thead>

								<tbody class="bg-white divide-y divide-gray-200">
									<tr>
										<td class="px-6 py-4 whitespace-nowrap text-sm text-gray-500">
											1
										</td>
		
										<td class="px-6 py-4 whitespace-nowrap text-sm text-gray-500">
											Africa
										</td>
		
										<td class="px-6 py-4 whitespace-nowrap text-sm text-gray-500">
											Ivory Coast
										</td>
		
										<td class="px-6 py-4 whitespace-nowrap text-sm text-gray-500">
											<span class="inline-flex items-center justify-center px-2 py-1 text-xs font-bold leading-none text-white bg-yellow-400 rounded mr-5">Orange</span>
											<span class="inline-flex items-center justify-center px-2 py-1 text-xs font-bold leading-none text-white bg-yellow-400 rounded mr-5">MTN</span>
											<span class="inline-flex items-center justify-center px-2 py-1 text-xs font-bold leading-none text-white bg-yellow-400 rounded mr-5">Mooov</span>
										</td>
									</tr>
		
									<tr>
										<td class="px-6 py-4 whitespace-nowrap text-sm text-gray-500">
											1
										</td>
		
										<td class="px-6 py-4 whitespace-nowrap text-sm text-gray-500">
											Africa
										</td>
		
										<td class="px-6 py-4 whitespace-nowrap text-sm text-gray-500">
											Ivory Coast
										</td>
		
										<td class="px-6 py-4 whitespace-nowrap text-sm text-gray-500">
											<span class="inline-flex items-center justify-center px-2 py-1 text-xs font-bold leading-none text-white bg-yellow-400 rounded mr-5">MTN</span>
										</td>
									</tr>
		
									<tr>
										<td class="px-6 py-4 whitespace-nowrap text-sm text-gray-500">
											1
										</td>
		
										<td class="px-6 py-4 whitespace-nowrap text-sm text-gray-500">
											Africa
										</td>
		
										<td class="px-6 py-4 whitespace-nowrap text-sm text-gray-500">
											Ivory Coast
										</td>
		
										<td class="px-6 py-4 whitespace-nowrap text-sm text-gray-500">
											<span class="inline-flex items-center justify-center px-2 py-1 text-xs font-bold leading-none text-white bg-yellow-400 rounded mr-5">MTN</span>
											<span class="inline-flex items-center justify-center px-2 py-1 text-xs font-bold leading-none text-white bg-yellow-400 rounded mr-5">Mooov</span>
										</td>
									</tr>
		
									<tr>
										<td class="px-6 py-4 whitespace-nowrap text-sm text-gray-500">
											1
										</td>
		
										<td class="px-6 py-4 whitespace-nowrap text-sm text-gray-500">
											Africa
										</td>
		
										<td class="px-6 py-4 whitespace-nowrap text-sm text-gray-500">
											Ivory Coast
										</td>
		
										<td class="px-6 py-4 whitespace-nowrap text-sm text-gray-500">
											<span class="inline-flex items-center justify-center px-2 py-1 text-xs font-bold leading-none text-white bg-yellow-400 rounded mr-5">Orange</span>
										</td>
									</tr>
		
		
									<tr>
										<td class="px-6 py-4 whitespace-nowrap text-sm text-gray-500">
											1
										</td>
		
										<td class="px-6 py-4 whitespace-nowrap text-sm text-gray-500">
											Africa
										</td>
		
										<td class="px-6 py-4 whitespace-nowrap text-sm text-gray-500">
											Ivory Coast
										</td>
		
										<td class="px-6 py-4 whitespace-nowrap text-sm text-gray-500">
											<span class="inline-flex items-center justify-center px-2 py-1 text-xs font-bold leading-none text-white bg-yellow-400 rounded mr-5">Orange</span>
											<span class="inline-flex items-center justify-center px-2 py-1 text-xs font-bold leading-none text-white bg-yellow-400 rounded mr-5">MTN</span>
										</td>
									</tr>
									<!-- More people... -->
								</tbody>
							</table>
						</div>
					</div>

                    <div class="py-2 align-middle inline-block min-w-full sm:px-6 lg:px-8 grid grid-cols-2">
						<div class="col-span-1">
							<button class="text-yellow-500 bg-transparent border border-solid 
								border-yellow-500 hover:bg-yellow-500 hover:text-white 
								active:bg-yellow-600 font-bold px-8 py-3 rounded-full 
								outline-none focus:outline-none mr-1 mb-1 ease-linear transition-all 
								duration-150 float-right mr-10"
								type="button">
								Annuler
						  </button>
						</div>

						<div class="col-span-1">
							<a routerLink="/home/general-settings/devises/"
								class="bg-yellow-500 text-white active:bg-yellow-600 font-bold
								text-base px-8 py-3 rounded-full shadow-md hover:shadow-lg outline-none 
								focus:outline-none mr-1 mb-1 ease-linear transition-all duration-150
								inline-block float-left">
								Confirmer
							</a>
						</div>
					</div>
				</div>
			</div>
		</div>
	</div>
</div>
