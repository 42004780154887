<div class="grid grid-cols-1">

    <!-- This example requires Tailwind CSS v2.0+ -->
    <div class="flex flex-col">
        <div class="my-2 overflow-x-auto sm:-mx-6 lg:-mx-8">
            <div class="py-2 align-middle inline-block min-w-full sm:px-6 lg:px-8 grid grid-cols-1 gap-4">

                <div class="col-span-1 px-20">

                    <div class="text-center">
                        Your Transfert has been done with success
                    </div>

                    <div class="py-2 align-middle inline-block min-w-full mt-10 text-center">
                        <button class="bg-yellow-500 text-white active:bg-yellow-600 font-bold
                            text-base px-8 py-3 rounded-full shadow-md hover:shadow-lg outline-none 
                            focus:outline-none mr-1 mb-1 ease-linear transition-all duration-150
                            inline-block" cdkFocusInitial>
                            OK
                        </button>
                    </div>

                </div>
            </div>
        </div>
    </div>

</div>
