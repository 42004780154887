import { Component } from '@angular/core';
import { Router, ActivatedRoute } from '@angular/router';


@Component({
	selector: 'add-currency',
	templateUrl: './add-currency.component.html'
})
export class AddCurrencyComponent {

    constructor(private router: Router) {

    }

    configNewCurrency() {}
}
