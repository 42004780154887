import { Component, OnInit } from '@angular/core';
import { MatDialog } from '@angular/material/dialog';
import { AddAlert } from './add-or-edit/add-alert.component';
import { DeleteAlert } from './delete/delete.component';
import { first } from 'rxjs/operators';
import { Subscription } from 'rxjs';
import { AlertService, AccountService } from '@app/_services'
import { User } from '../../../../_models';

@Component({
	selector: 'app-user-management',
	templateUrl: './user-management.component.html',
	styleUrls: ['./user-management.component.css']
})

export class UserManagementComponent implements OnInit {

	users = null;
	userSize: number;
	userSubscription: Subscription;
	addUserLoader: boolean;
	loading: boolean;

	constructor(
		public dialog: MatDialog,
		private accountService: AccountService,
		private alertService: AlertService) { }

	ngOnInit(): void {
		this.getAllUsers()

		this.userSubscription = this.accountService.usersSubject.subscribe(users => {
			this.users = users;
			this.userSize = this.users.length;
		});
	}

	getAllUsers() {
		this.accountService.fetchAll()
			.pipe(first())
			.subscribe(users => {
				this.accountService.emitUsers(users['data']);
			});
	}

	openDialog() {
		const dialogRef = this.dialog.open(AddAlert);

		dialogRef.afterClosed().subscribe(result => {
			console.log(`Dialog result: ${result}`);
		});
	}

	onChangeUser(e, user) {
		user.status = e;
		this.accountService.update(user.id, user)
			.pipe(first())
			.subscribe({
				next: (userUpdated) => {
					this.addUserLoader = false;
					this.alertService.success('Success', 'Update successful', { keepAfterRouteChange: true });
					this.dialog.closeAll();
				},
				error: error => {
					this.addUserLoader = false;
					this.dialog.closeAll();
					this.alertService.success('Error', 'Update failed', { keepAfterRouteChange: true });
					this.loading = false;
				}
			});
	}

	openUpdateUser(id) {
		const user = this.users.filter(u => u.id === id);
		const dialogRef = this.dialog.open(AddAlert);
		dialogRef.componentInstance.user = user[0];
		dialogRef.afterClosed().subscribe(result => {
			this.getAllUsers()
		});
	}

	openDeleteUserModal(id) {
		const dialogRef = this.dialog.open(DeleteAlert);
		dialogRef.componentInstance.userId = id;
	}

}
