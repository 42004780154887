import { Component, OnInit } from '@angular/core';
import { TranslateService } from '@ngx-translate/core';
import { User } from '@app/_models';
import { AccountService, MenuState } from '@app/_services/';

@Component({
	selector: 'app-header',
	templateUrl: './header.component.html',
	styleUrls: ['./header.component.css'],
})

export class HeaderComponent implements OnInit {
	userName: string;
	token: any;
	language: string;
	user: User;
	selectedService: string;

	services = {
		'dcb': 'DCB',
		'mobile-money': 'Mobile money',
		'bulk': 'Bulk',
		'mobile-transfer': 'Mobile Tranfer'
	};

	constructor(
		private translate: TranslateService,
		private accountService: AccountService,
		private menuService: MenuState
	) {
		translate.setDefaultLang('en');
		this.accountService.user.subscribe(x => this.user = x);
		this.accountService.selectedService.subscribe(x => {
			this.selectedService = x;
		});
	}

	useLanguage(lang: string): void {
		//lang == 'en' ? this.language = 'EN' : this.language = 'FR';
		localStorage.setItem('lang', 'FR'/*lang*/);
		localStorage.setItem('language', 'FR'/*this.language*/);
		this.translate.use(localStorage.getItem('lang'));
	}

	logout() {
		// this.keycloakService.logout();
		this.accountService.logout();
	}

	ngOnInit(): void {
		this.translate.use(localStorage.getItem('lang'));
		this.language = localStorage.getItem('language') || 'FR';
	}

	sidebarHandler() {
		// this.status = !this.status;
		this.menuService.setStatus(!this.menuService.getStatus());
	}

	selectService(service: string) {
		this.selectedService = service;
		this.accountService.setSelectedService(service);
	}

}
