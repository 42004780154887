<footer *ngIf="user && selectedService" class="w-full">
    <div class="footer-copyright">
        <div class="w-full footer-bg halign left copyright">
            <div class="wrapper">
                <span class="footer_rights">© 2020 Bizao All Rights Reserved</span>
                <a class="grey-text text-lighten-4 right  finance hide-on-small-only" href="#!"><i class="material-icons">person</i>{{ 'footer.finance' | translate }}</a>
                <a class="grey-text text-lighten-4 right  hide-on-small-only" href="#"><i class="material-icons">phone</i>{{ 'footer.contactUs' | translate }}</a>
                <a class="grey-text text-lighten-4 right  hide-on-small-only support" href="#"><i class="material-icons">person_pin_circle</i>{{ 'footer.help' | translate }}</a>
                <a class="grey-text text-lighten-4 right  hide-on-small-only support" href="#"><i class="material-icons">group</i>{{ 'footer.aboutUs' | translate }}</a>
            </div>
        </div>
    </div>
</footer>
