
<div class="w-full md:w-full mx-auto px-8">

    <div class="grid grid-cols-6 px-5">
        <div class="col-span-2 mr-3">
            <button
                class="bg-white text-black active:bg-white font-bold
                text-base px-5 py-3 rounded-full shadow-md hover:shadow-lg outline-none 
                focus:outline-none mr-1 mb-1 ease-linear transition-all duration-150 
                inline-block inline left w-full mt-3" cdkFocusInitial (click)='showFilterContent()'>
                <img src="../../../../../../../assets/icones/Boutton/filtrer.svg" alt="Filter icone" class="inline mr-2"/>
                    Fitre avancé
            </button>

            <div *ngIf='filterContent' class="absolute top-20 bg-white rounded w-96">
                <div class="shadow-md rounded-md white-background" style="z-index: 9999; position: relative; background: #fff;">

                    <div class="tab w-full overflow-hidden border-t">
                        <input class="absolute opacity-0 " id="tab-multi-one" type="radio" name="tabs" />
                        <label class="block p-5 leading-normal cursor-pointer text-base text-black" for="tab-multi-one">
                            Partenaire
                        </label>
                        <div class="tab-content overflow-hidden border-l-2 bg-gray-100 leading-normal accordeonContent">
                            <select class="form-control select-filter" id="profil" name="profil" 
                                (change)="onFilterChange($event.target.value, 'partner')" [(ngModel)]="partnerId">
                                <option value="" disabled>Sélectionnez un partenaire</option>
                                <option *ngFor="let partner of partners">
                                    {{ partner }}
                                </option>
                            </select>
                        </div>
                    </div>

                    <div class="tab w-full overflow-hidden border-t">
                        <input class="absolute opacity-0" id="tab-multi-three" type="radio" name="tabs">
                        <label class="block p-5 leading-normal cursor-pointer text-base text-black" for="tab-multi-three">
                            Operateur
                        </label>
                        <div class="tab-content overflow-hidden border-l-2 bg-gray-100 leading-normal accordeonContent">
                                <select class="form-control select-filter" id="operator" name="operator"
                                    (change)="onFilterChange($event.target.value, 'mno')" [(ngModel)]="operator">
                                    <option value="" disabled>Sélectionnez un MNO</option>
                                    <option *ngFor="let operator of operators">
                                        {{ operator }}
                                    </option>
                                </select>
                        </div>
                    </div>

                    <div class="tab w-full overflow-hidden border-t">
                        <input class="absolute opacity-0" id="tab-multi-four" type="radio" name="tabs">
                        <label class="block p-5 leading-normal cursor-pointer text-base text-black" for="tab-multi-four">
                            From country
                        </label>
                        <div class="tab-content overflow-hidden border-l-2 bg-gray-100 leading-normal accordeonContent">
                            <select class="form-control select-filter" id="country" name="country"
                                (change)="onFilterChange($event.target.value, 'from-country')" [(ngModel)]="fromCountry">
                                <option value="" disabled>Sélectionnez un pays</option>
                                <option *ngFor="let ct of countries" value="{{ ct }}">
                                    {{ country[ct] }}
                                </option>
                            </select>
                        </div>
                    </div>

                    <div class="tab w-full overflow-hidden border-t">
                        <input class="absolute opacity-0" id="tab-multi-five" type="radio" name="tabs">
                        <label class="block p-5 leading-normal cursor-pointer text-base text-black" for="tab-multi-five">
                            To country
                        </label>
                        <div class="tab-content overflow-hidden border-l-2 bg-gray-100 leading-normal accordeonContent">
                            <select class="form-control select-filter" id="country" name="country"
                                (change)="onFilterChange($event.target.value, 'to-country')" [(ngModel)]="toCountry">
                                <option value="" disabled>Sélectionnez un pays</option>
                                <option *ngFor="let ct of countries" value="{{ ct }}">
                                    {{ country[ct] }}
                                </option>
                            </select>
                        </div>
                    </div>

                    <div class="tab w-full overflow-hidden border-t">
                        <input class="absolute opacity-0" id="tab-multi-seven" type="radio" name="tabs">
                        <label class="block p-5 leading-normal cursor-pointer text-base text-black" for="tab-multi-seven">
                            Statut transactions
                        </label>
                        <div class="tab-content overflow-hidden border-l-2 bg-gray-100 leading-normal accordeonContent">
                            <select class="form-control select-filter" id="country" name="country"
                                (change)="onFilterChange($event.target.value, 'status')" [(ngModel)]="toCountry">
                                <option value="" disabled>Sélectionnez un Statut</option>
                                <option value="Successful">
                                    Success
                                </option>
                                <option value="Failed">
                                    Failed
                                </option>
                                <option value="Inprogress">
                                    In progress
                                </option>
                            </select>
                        </div>
                    </div>
                </div>
            </div>
        </div>

        <div class="col-span-4">

            <div class="relative">
                <img src="../../../../../../../assets/icones/Boutton/calendrier.svg" alt="Icone calendrier" class="absolute icone-calendar"/>

                <input type="text"
                    class="rounded-full shadow-md hover:shadow-lg date-input-filter datepicker-calendar-icon"
                    ngxDaterangepickerMd
                    [locale]="{applyLabel: 'Filtrer', format: 'DD-MM-YYYY'}"
                    startKey="start"
                    endKey="end"
                    [showWeekNumbers]=false
                    [showISOWeekNumbers]=false
                    [(ngModel)]="selected"
                    (datesUpdated)="datesUpdated($event)"
                    name="daterange"/>
            </div>
        </div>
    </div>
</div>
