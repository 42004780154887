import { Injectable } from '@angular/core';
import { Router } from '@angular/router';
import { HttpClient } from '@angular/common/http';
import { BehaviorSubject, Observable } from 'rxjs';
import { environment } from '../../environments/environment';
import { Partner } from '@app/_models/index';
import { Subject } from "rxjs";
import { map } from 'rxjs/operators';
import { PartnerUtils } from '@app/utils';

@Injectable({ providedIn: 'root' })
export class PartnerService {
    private partnerSubject: BehaviorSubject<Partner>;
    public partner: Observable<Partner>;
    private partners: Partner[] = [];
    partnersSubject = new Subject<Partner[]>();
    partnerToAdd = new Partner();

    constructor(
        private router: Router,
        private http: HttpClient
    ) {
        this.getAll()
            .subscribe(partners => {
                this.partners = new PartnerUtils().arrayToPartner(partners);
                this.emitPartners();
            });
    }

    public get userValue(): Partner {
        return this.partnerSubject.value;
    }

    create(partner: Partner) {
        return this.http.post(`${environment.api}/partner-manager/partners`, partner);
    }

    emitPartners() {
        this.partnersSubject.next(this.partners.slice());
    }

    emitUpdatedPartners(partnerUpdated) {
        let p = this.partners.filter(partner => partner.getId() == partnerUpdated['partnerId']);

        if (p.length > 0) {
            p[0].setCallbackUrl(partnerUpdated['partnerCallbackURL']);
            p[0].setCancelUrl(partnerUpdated['partnerCancelURL']);
            p[0].setContact(partnerUpdated['partnerContact']);
            p[0].setFailureUrl(partnerUpdated['partnerFailureURL']);
            p[0].setName(partnerUpdated['partnerName']);
            p[0].setOperatingCountries(partnerUpdated['partnerOperatingCountries']);
            p[0].setType(partnerUpdated['partnerType']);
            p[0].setOffers(partnerUpdated['offers']);
        } else {
            this.partners.push(partnerUpdated);
        }

        this.emitPartners();
    }

    emitPartnersAfterDeletd(id) {
        this.partners.forEach((partner, i) => {
            if (partner.getId() == id) {
                this.partners.splice(i, 1);
            };
        });

        this.emitPartners();
    }

    getAll() {
        return this.http.get<Partner[]>(`${environment.api}/partner-manager/partners`);
    }

    getPartnersForUsers() {
        return this.http.get<Partner[]>(`${environment.api}/partner-manager/partners`).pipe(
            map(partners => {
                let newPartners = [];
                for (let partner of partners) {
                    let customPartner = {
                        "id": partner["partnerId"],
                        "name": partner["partnerName"],
                        "type": partner["partnerType"],
                        "contact": partner["partnerContact"]
                    }
                    newPartners.push(customPartner);
                }
                return newPartners;

            })
        );
    }

    getPartnerType() {
        return this.http.get<Partner[]>(`${environment.api}/partner-manager/partners`);
    }

    getById(id: string) {
        return this.http.get<Partner>(`${environment.api}/partner-manager/partners/${id}`);
    }

    getPartner() {
        return this.partners;
    }

    addPartner(partnerName: string) {
        return this.http.post(`${environment.api}/partner-manager/partners`, partnerName).pipe(response => response);
    }

    updatePartner(params) {
        return this.http.put(`${environment.api}/partner-manager/partners/${params.getId()}`, params)
            .pipe(map(x => {
                return x;
            }));
    }

    delete(id) {
        return this.http.delete(`${environment.api}/partner-manager/partners/` + id).pipe(response => response);
    }

    getCurrentPartner() {
        return this.partnerToAdd;
    }

    setCurrentPartner(partner: Partner) {
        this.partnerToAdd = partner;
    }

    initPartnerToAdd() {
        this.partnerToAdd = new Partner();
    }
}
