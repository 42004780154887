import { Component, OnInit } from '@angular/core';

@Component({
	selector: 'app-client-service',
	templateUrl: './client-service.component.html',
	styleUrls: ['./client-service.component.css']
})

export class ClientServiceComponent implements OnInit {
	sectionTitle = "Dashboard";
	sectionItem = "Revenue";
	sectionLeftTitle = "Revenue";
	sectionRightTitle = "To be defined";
	constructor() { }

	ngOnInit(): void {}
}
