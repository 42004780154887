
<div class="bg-white rounded p-0">
	<div class="grid grid-cols-1">

		<div class="bg-white grid grid-cols-2 border-b border-gray-200 pb-5">
			<h6 class="col-span-1">
				Customer service - new request
			</h6>
		</div>

		<!-- This example requires Tailwind CSS v2.0+ -->
		<div class="flex flex-col">
			<div class="my-2 overflow-x-auto sm:-mx-6 lg:-mx-8">
				<div class="py-2 align-middle inline-block min-w-full sm:px-6 lg:px-8 grid grid-cols-8 gap-4">

					<div class="col-start-3 col-span-4 pt-20">
						<div class="grid grid-cols-4">
							<div class="col-span-4 grid grid-cols-4">
								<div class="col-span-1 text-right pt-5 pr-5">
									<span class="text-gray-700">Transaction ID</span>
								</div>
								<div class="col-span-3">
									<select name="cars" id="cars" class="block">
										<option value="xof">XOF</option>
										<option value="Euro">Euro</option>
									</select>
								</div>
							</div>

							<div class="col-span-4 grid grid-cols-4">
								<div class="col-span-1 text-right pt-5 pr-5 mt-5">
									<span class="text-gray-700">Trasanction date</span>
								</div>
								<div class="col-span-3 mt-5">
									<select name="cars" id="cars" class="block">
										<option value="xof">XOF</option>
										<option value="Euro">Euro</option>
									</select>
								</div>
							</div>

							<div class="col-span-4 grid grid-cols-4">
								<div class="col-span-1 text-right pt-5 pr-5 mt-5">
									<span class="text-gray-700">Transaction type</span>
								</div>
								<div class="col-span-3 mt-5">
									<select name="cars" id="cars" class="block">
										<option value="xof">XOF</option>
										<option value="Euro">Euro</option>
									</select>
								</div>
							</div>
						</div>

						<div class="col-span-3 grid grid-cols-2 mt-20">
							<div class="col-span-1">
								<button class="text-yellow-500 bg-transparent border border-solid 
									border-yellow-500 hover:bg-yellow-500 hover:text-white 
									active:bg-yellow-600 font-bold px-8 py-3 rounded-full 
									outline-none focus:outline-none mr-1 mb-1 ease-linear transition-all 
									duration-150 float-right mr-10"
									type="button">
									Annuler
								</button>
							</div>

							<div class="col-span-1">
								<a routerLink="/home/order-emission/bank-deposit/"
									class="bg-yellow-500 text-white active:bg-yellow-600 font-bold
									text-base px-8 py-3 rounded-full shadow-md hover:shadow-lg outline-none 
									focus:outline-none mr-1 mb-1 ease-linear transition-all duration-150
									inline-block">
									Validate
								</a>
							</div>
						</div>

					</div>
				</div>
			</div>
		</div>
	</div>
</div>
