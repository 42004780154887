import { Component, NgModule } from '@angular/core';
import { Routes, RouterModule } from '@angular/router';

import { LayoutComponent } from '@app/components/layout/layout.component';
import { LoginComponent } from './login/login.component';
import { UserManagementComponent } from './user-management.component';
import { SelectServiceComponent } from './select-service/select-service.component';

import { AuthGuard } from '@app/_helpers';

const routes: Routes = [
    {
        path: '', component: LayoutComponent,
        children: [
            { path: '', component: UserManagementComponent },
            { path: 'login', component: LoginComponent },
            { path: 'select-service', component: SelectServiceComponent, canActivate : [AuthGuard]  }
        ]
    }
];

@NgModule({
    imports: [RouterModule.forChild(routes)],
    exports: [RouterModule]
})

export class UserManagementRoutingModule { }
