import { Component, Inject, OnInit } from '@angular/core';
import { MatDialog, MatDialogRef, MAT_DIALOG_DATA } from '@angular/material/dialog';
import { ActivatedRoute } from '@angular/router';
import { AppConfig } from '@app/config/app-config';

@Component({
	selector: 'app-profile-name-modal',
	templateUrl: './profile-name-modal.component.html',
	styleUrls: ['./profile-name-modal.component.css']
})

export class ProfileNameModalComponent implements OnInit {
	loading = false;
	submitted = false;
	idMenu: number;
	currentMenu: any;
	menus = AppConfig.menus;
	rights = {
		menu: null,
		roles: []
	};
	reads: Array<boolean> = [];
	writes: Array<boolean> = [];

	constructor(public dialog: MatDialog,  public dialogRef: MatDialogRef<ProfileNameModalComponent>, @Inject(MAT_DIALOG_DATA) public data: any) {

	}

	ngOnInit(): void {
		this.currentMenu = this.menus.filter(menu => menu.id == this.idMenu)[0];
		this.rights.menu = this.currentMenu.label;
		this.rights.roles = this.data && this.data.roles ? this.data.roles : [];
		for(let menu of this.currentMenu.submenu){
			let elm = this.data?.roles?.find(e => e.submenu ==  menu.label);
			if(elm){
			  menu.read = elm.read
				menu.write = elm.write
			}else{
				menu.read = false;
				menu.write = false;
			}
		}
	}

	closeDialog() {
		this.dialogRef.close(this.rights);
	}

	changeRight(event, submenu, action){
		let role = {
			submenu: submenu.label,
			read: action ==  'read' ? event.returnValue : !event.returnValue,
			write: action ==  'write' ? event.returnValue : !event.returnValue
		}
		let existMenu = this.rights && this.rights.roles ?  this.rights.roles.findIndex(m => m.submenu == submenu.label) : -1
		if(existMenu != -1)
		  this.rights.roles.splice(existMenu, 1)

		this.rights.roles.push(role)
	}

}
