import { NgModule } from '@angular/core';
import { Routes, RouterModule } from '@angular/router';

import { LayoutComponent } from '@app/components/layout/layout.component';
import { CommissionConfig } from './commission-config/commission-config.component';
import { AddFees } from './commission-config/add/add-fees.component';
import { AlertConfig } from './alert-config/alert-config.component';
import { AddAlert } from './alert-config/add/add-alert.component';
import { WorkFlowComponent } from './work-flow/work-flow.component';
import { AddFlow } from './work-flow/add/add-flow.component';
import { StepFlow } from './work-flow/add/configure/step.component';
import { StepFlow2 } from './work-flow/add/configure/step-2.component';

const routes: Routes = [
    {
        path: '', component: LayoutComponent,
        children: [
            { path: 'fees', component: CommissionConfig },
            { path: 'fees/add', component: AddFees },
            { path: 'alert', component: AlertConfig },
            { path: 'alert/add', component: AddAlert },
            { path: 'work-flow', component: WorkFlowComponent },
            { path: 'work-flow/add', component: AddFlow },
            { path: 'work-flow/add/step', component: StepFlow },
            { path: 'work-flow/add/step-2', component: StepFlow2 },
        ]
    }
];

@NgModule({
    imports: [RouterModule.forChild(routes)],
    exports: [RouterModule]
})

export class PartnerSettingsRoutingModule { }
