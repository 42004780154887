<nav class="bg-transparent shadow-none">
	<div class="nav-wrapper">
		<div class="col s12">
			<a href="#" class="breadcrumb app-root text-gray-400">App</a>
			<a href="#" class="breadcrumb text-gray-400">Opérations</a>
			<a href="#" class="breadcrumb text-gray-400">Emission d'ordre</a>
			<a href="#" class="breadcrumb text-gray-400">Demande de reversement</a>
			<a href="#" class="breadcrumb text-gray-400">Initiation</a>
		</div>
	</div>
</nav>

<div class="content pb-5">
	<div class="content-header bg-white rounded m-5 p-0">
		<div class="grid grid-cols-1 shadow-lg p-5">

			<div class="bg-white grid grid-cols-2 border-b border-gray-200 pb-5">
				<h6 class="col-span-1">
					Order de reversement
				</h6>

				<div class="col-span-1 float-right content-shadow">
					<a routerLink="/home/order-emission/bank-deposit/add"
						class="bg-yellow-500 text-white font-bold py-2 px-4 rounded-full float-right">
						Configurer mon mode de réception
					</a>
				</div>
			</div>

			<div class="grid grid-cols-7 gap-4">
				<div class="col-span-3">
					<h6 class="mt-5">Virer des fonds</h6>
					<div class="grid grid-cols-1 px-5">

						<div class="col-span-1 grid grid-cols-3">
							<div class="col-span-1 text-right pt-5 pr-5">
								<span class="text-gray-700">Chiffre d'affaire</span>
							</div>
							<div class="col-span-2">
								<input type="text" placeholder="Partner name" />
							</div>
						</div>

						<div class="col-span-1 grid grid-cols-3">
							<div class="col-span-1 text-right pt-5 pr-5 mt-5">
								<span class="text-gray-700">Mode de réception</span>
							</div>
							<div class="col-span-2 mt-5">
								<select name="cars" id="cars" class="block">
									<option value="xof">Orange money</option>
									<option value="Euro">Free</option>
								</select>
							</div>
						</div>

						<div class="col-span-1 grid grid-cols-3">
							<div class="col-span-1 text-right pt-5 pr-5 mt-5">
								<span class="text-gray-700">Montant à virer</span>
							</div>
							<div class="col-span-2 mt-5">
								<input type="text" placeholder="Montant à virer" />
							</div>
						</div>
					</div>

					<div class="col-span-3 grid grid-cols-2 mt-10">
						<div class="col-span-1">
							<button class="text-yellow-500 bg-transparent border border-solid 
								border-yellow-500 hover:bg-yellow-500 hover:text-white 
								active:bg-yellow-600 font-bold px-5 py-3 rounded-full 
								outline-none focus:outline-none mr-1 mb-1 ease-linear transition-all 
								duration-150 float-right mr-10"
								type="button">
								Faire une sommission
						  </button>
						</div>

						<div class="col-span-1">
							<button (click)="openDialog()"
								class="bg-yellow-500 text-white active:bg-yellow-600 font-bold
								text-base px-8 py-3 rounded-full shadow-md hover:shadow-lg outline-none 
								focus:outline-none mr-1 mb-1 ease-linear transition-all duration-150
								inline-block">
								Faire la demande
							</button>
						</div>
					</div>
				</div>

				<div class="col-span-2 border-r border-gray-200">
					<h6 class="mt-5">Simulation</h6>
					<div class="grid grid-cols-1 p-5 bg-gray-100 mr-5">
						<div class="col-span-1 grid grid-cols-3">
							<div class="col-span-2 text-right  pr-5">
								<span class="text-gray-700">Montant HT</span>
							</div>
							<div class="col-span-1">
								<span class="text-gray-700">N / A</span>
							</div>
						</div>

						<div class="col-span-1 grid grid-cols-3">
							<div class="col-span-2 text-right pr-5 mt-5">
								<span class="text-gray-700">Taux de commission appliqué :</span>
							</div>
							<div class="col-span-1 mt-5">
								<span class="text-gray-700">2%</span>
							</div>
						</div>

						<div class="col-span-1 grid grid-cols-3">
							<div class="col-span-2 text-right pr-5 mt-5">
								<span class="text-gray-700">Commission HT :</span>
							</div>
							<div class="col-span-1 mt-5">
								<span class="text-gray-700">N / A</span>
							</div>
						</div>

						<div class="col-span-1 grid grid-cols-3">
							<div class="col-span-2 text-right pr-5 mt-5">
								<span class="text-gray-700">TVA sur commission (18%) :</span>
							</div>
							<div class="col-span-1 mt-5">
								<span class="text-gray-700">N / A</span>
							</div>
						</div>

						<div class="col-span-1 grid grid-cols-3">
							<div class="col-span-2 text-right pr-5 mt-5">
								<span class="text-gray-700">Commission total TTC :</span>
							</div>
							<div class="col-span-1 mt-5">
								<span class="text-gray-700">N / A</span>
							</div>
						</div>

						<div class="col-span-1 grid grid-cols-3">
							<div class="col-span-2 text-right pr-5 mt-5">
								<span class="text-gray-700">Montant net à virer :</span>
							</div>
							<div class="col-span-1 mt-5">
								<span class="text-gray-700">N / A</span>
							</div>
						</div>
					</div>
				</div>

				<div class="col-span-2">
					<h6 class="mt-5">Suivi</h6>
					<div class="grid grid-cols-1 p-5">
						<div class="col-span-1 grid grid-cols-3">
							<div class="col-span-2 text-right pr-5">
								<span class="text-gray-700">Montant HT :</span>
							</div>
							<div class="col-span-1">
								<span class="text-gray-700">N / A</span>
							</div>
						</div>

						<div class="col-span-1 grid grid-cols-3">
							<div class="col-span-2 text-right pr-5 mt-5">
								<span class="text-gray-700">Taux de commission appliqué :</span>
							</div>
							<div class="col-span-1 mt-5">
								<span class="text-gray-700">2%</span>
							</div>
						</div>

						<div class="col-span-1 grid grid-cols-3">
							<div class="col-span-2 text-right pr-5 mt-5">
								<span class="text-gray-700">Commission HT :</span>
							</div>
							<div class="col-span-1 mt-5">
								<span class="text-gray-700">N / A</span>
							</div>
						</div>

						<div class="col-span-1 grid grid-cols-3">
							<div class="col-span-2 text-right pr-5 mt-5">
								<span class="text-gray-700">TVA sur commission (18%) : </span>
							</div>
							<div class="col-span-1 mt-5">
								<span class="text-gray-700">N / A</span>
							</div>
						</div>

						<div class="col-span-1 grid grid-cols-3">
							<div class="col-span-2 text-right pr-5 mt-5">
								<span class="text-gray-700">Commission total TTC :</span>
							</div>
							<div class="col-span-1 mt-5">
								<span class="text-gray-700">N / A</span>
							</div>
						</div>

						<div class="col-span-1 grid grid-cols-3">
							<div class="col-span-2 text-right pr-5 mt-5">
								<span class="text-gray-700">Montant net à virer : </span>
							</div>
							<div class="col-span-1 mt-5">
								<span class="text-gray-700">N / A</span>
							</div>
						</div>
					</div>
				</div>

			</div>

		</div>
	</div>
</div>

<div class="content pb-10">
	<div class="content-header bg-white rounded m-5 p-0">
		<div class="grid grid-cols-1 shadow-lg p-5">

			<div class="bg-white grid grid-cols-2 border-b border-gray-200 pb-5">
				<h6 class="col-span-1">
					Order de reversement
				</h6>

				<div class="col-span-1 float-right content-shadow">
					<a routerLink="/home/order-emission/bank-deposit/add"
						class="bg-yellow-500 text-white font-bold py-2 px-4 rounded-full float-right">
						Export CSV
					</a>
				</div>
			</div>

			<!-- This example requires Tailwind CSS v2.0+ -->
			<div class="flex flex-col">
				<div class="my-2 overflow-x-auto sm:-mx-6 lg:-mx-8">
					<div class="py-2 align-middle inline-block min-w-full sm:px-6 lg:px-8">
						<div class="shadow overflow-hidden border-b border-gray-200 sm:rounded-lg">

							<table class="min-w-full divide-y divide-gray-200">
								<thead class="bg-gray-50">
									<tr>
										<th scope="col"
											class="px-6 py-3 text-left text-xs font-medium text-gray-500 uppercase tracking-wider">
											#
										</th>
		
										<th scope="col"
											class="px-6 py-3 text-left text-xs font-medium text-gray-500 uppercase tracking-wider">
											Id
										</th>
		
										<th scope="col"
											class="px-6 py-3 text-left text-xs font-medium text-gray-500 uppercase tracking-wider">
											Date de création
										</th>
		
										<th scope="col"
											class="px-6 py-3 text-left text-xs font-medium text-gray-500 uppercase tracking-wider">
											Partner
										</th>

										<th scope="col"
											class="px-6 py-3 text-left text-xs font-medium text-gray-500 uppercase tracking-wider">
											Mode de réception
										</th>

										<th scope="col"
											class="px-6 py-3 text-left text-xs font-medium text-gray-500 uppercase tracking-wider">
											IBAN/MSISDN
										</th>

										<th scope="col"
											class="px-6 py-3 text-left text-xs font-medium text-gray-500 uppercase tracking-wider">
											Montant HT
										</th>

										<th scope="col"
											class="px-6 py-3 text-left text-xs font-medium text-gray-500 uppercase tracking-wider">
											Taux Appliqué
										</th>

										<th scope="col"
											class="px-6 py-3 text-left text-xs font-medium text-gray-500 uppercase tracking-wider">
											Commission
										</th>

										<th scope="col"
											class="px-6 py-3 text-left text-xs font-medium text-gray-500 uppercase tracking-wider">
											Montant Net
										</th>

									</tr>
								</thead>

								<tbody class="bg-white divide-y divide-gray-200">
									<tr>
										<td class="px-6 py-4 whitespace-nowrap text-sm text-gray-500">
											1
										</td>

										<td class="px-6 py-4 whitespace-nowrap text-sm text-gray-500">
											Rev20210429-b1
										</td>

										<td class="px-6 py-4 whitespace-nowrap text-sm text-gray-500">
											22-04-021
										</td>

										<td class="px-6 py-4 whitespace-nowrap text-sm text-gray-500">
											Djamo
										</td>

										<td class="px-6 py-4 whitespace-nowrap text-sm text-gray-500">
											Virement
										</td>

										<td class="px-6 py-4 whitespace-nowrap text-sm text-gray-500">
											FR639973656402
										</td>

										<td class="px-6 py-4 whitespace-nowrap text-sm text-gray-500">
											90 000
										</td>

										<td class="px-6 py-4 whitespace-nowrap text-sm text-gray-500">
											2%
										</td>

										<td class="px-6 py-4 whitespace-nowrap text-sm text-gray-500">
											1859
										</td>

										<td class="px-6 py-4 whitespace-nowrap text-sm text-gray-500">
											88 148
										</td>

									</tr>

									<tr>
										<td class="px-6 py-4 whitespace-nowrap text-sm text-gray-500">
											1
										</td>

										<td class="px-6 py-4 whitespace-nowrap text-sm text-gray-500">
											Rev20210429-b1
										</td>

										<td class="px-6 py-4 whitespace-nowrap text-sm text-gray-500">
											22-04-021
										</td>

										<td class="px-6 py-4 whitespace-nowrap text-sm text-gray-500">
											Djamo
										</td>

										<td class="px-6 py-4 whitespace-nowrap text-sm text-gray-500">
											Virement
										</td>

										<td class="px-6 py-4 whitespace-nowrap text-sm text-gray-500">
											FR639973656402
										</td>

										<td class="px-6 py-4 whitespace-nowrap text-sm text-gray-500">
											90 000
										</td>

										<td class="px-6 py-4 whitespace-nowrap text-sm text-gray-500">
											2%
										</td>

										<td class="px-6 py-4 whitespace-nowrap text-sm text-gray-500">
											1859
										</td>

										<td class="px-6 py-4 whitespace-nowrap text-sm text-gray-500">
											88 148
										</td>

									</tr>

									<tr>
										<td class="px-6 py-4 whitespace-nowrap text-sm text-gray-500">
											1
										</td>

										<td class="px-6 py-4 whitespace-nowrap text-sm text-gray-500">
											Rev20210429-b1
										</td>

										<td class="px-6 py-4 whitespace-nowrap text-sm text-gray-500">
											22-04-021
										</td>

										<td class="px-6 py-4 whitespace-nowrap text-sm text-gray-500">
											Djamo
										</td>

										<td class="px-6 py-4 whitespace-nowrap text-sm text-gray-500">
											Virement
										</td>

										<td class="px-6 py-4 whitespace-nowrap text-sm text-gray-500">
											FR639973656402
										</td>

										<td class="px-6 py-4 whitespace-nowrap text-sm text-gray-500">
											90 000
										</td>

										<td class="px-6 py-4 whitespace-nowrap text-sm text-gray-500">
											2%
										</td>

										<td class="px-6 py-4 whitespace-nowrap text-sm text-gray-500">
											1859
										</td>

										<td class="px-6 py-4 whitespace-nowrap text-sm text-gray-500">
											88 148
										</td>

									</tr>

								</tbody>
							</table>

						</div>
					</div>
				</div>
			</div>

		</div>
	</div>
</div>
