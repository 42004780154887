import { Component, OnInit, AfterViewInit } from '@angular/core';
import { TransactionService } from '@app/_services';
import { Transaction } from '@app/_models';
import { TransactionUtils } from '@app/utils/transactions.utils';

@Component({
	selector: 'app-revenue',
	templateUrl: './revenue.component.html',
	styleUrls: ['./revenue.component.css']
})

export class RevenueComponent implements OnInit, AfterViewInit {
	sectionTitle = "Dashboard";
	sectionItem = "Revenue";
	sectionLeftTitle = "Revenue";
	sectionRightTitle = "To be defined";

	transactions: Transaction[] = null;
	p: number = 1;
	globalRevenu = 0;
	utils = new TransactionUtils();
	amountSum = 0;

	constructor(private transactionService: TransactionService) {}

	ngAfterViewInit(): void {
		this.transactionService.transactionsSubject.subscribe(
			(transactions: any[]) => {
				this.transactions = transactions;
				this.amountSum = this.utils.getAmountSumTransactions(this.transactions);
			}
		);
		this.transactionService.emitTransactions();

		this.transactionService.globalRevenuSubject.subscribe(globalRevenu => {
			this.globalRevenu = globalRevenu;
		});
		this.transactionService.emitGlobalRevenu();
	}

	ngOnInit(): void {}

	getLoader () {
		return this.transactionService.loaderTransactions;
	}

}
