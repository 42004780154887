import { Menu } from ".";

/**
 * @description contains Profil information
 */
export class Profil {

    private id: string;
    public name: string;
    private menu: Menu[];

    constructor() {}

    setId(id: string) {
        this.id = id;
    }

    setName(name: string) {
        this.name = name;
    }

    setMenu(menu: Menu[]) {
        this.menu = menu;
    }

    getId() {
        return this.id;
    }
}
