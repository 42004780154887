import { Component, OnInit } from '@angular/core';
import { MatDialog } from '@angular/material/dialog';
import { CategoryDetailComponent } from '../category-detail/category-detail.component';

@Component({
	selector: 'app-add-request',
	templateUrl: './add-request.component.html',
	styleUrls: ['./add-request.component.css']
})

export class AddRequestComponent implements OnInit {

	constructor(public dialog: MatDialog) {}

	ngOnInit(): void {}

	openDialog() {
		const dialogRef = this.dialog.open(CategoryDetailComponent);
	
		dialogRef.afterClosed().subscribe(result => {
		  	console.log(`Dialog result: ${result}`);
		});
	}
}
