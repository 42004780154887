import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { ReactiveFormsModule, FormsModule } from '@angular/forms';
import { RouterModule } from '@angular/router';

import { MatMenuModule } from '@angular/material/menu';
import { MatButtonModule } from '@angular/material/button';
import { UserManagementRoutingModule } from './user-management-rooting.module';

import { LoginComponent } from './login/login.component';
import { UserManagementComponent } from './user-management.component';
import { AddAlert } from './add-or-edit/add-alert.component';
import { DeleteAlert } from './delete/delete.component';
import { SelectServiceComponent } from './select-service/select-service.component';

import { UiSwitchModule } from 'ngx-ui-switch';

@NgModule({
    imports: [
        CommonModule,
        FormsModule,
        ReactiveFormsModule,
        UserManagementRoutingModule,
        RouterModule,
        MatMenuModule,
        MatButtonModule,
        UiSwitchModule.forRoot({
            size: 'small',
            color: 'rgb(0, 189, 99)',
            switchColor: '#1ba231',
            defaultBgColor: '#b9e4c0',
            defaultBoColor : '#b9e4c0',
            checkedLabel: 'on',
            uncheckedLabel: 'off',
            checkedTextColor: '#fff'
        })        
    ],
    declarations: [
        UserManagementComponent,
        LoginComponent,
        AddAlert,
        DeleteAlert,
        SelectServiceComponent
    ]
})

export class UserManagementModule { }
