import { Component, OnInit } from '@angular/core';
import { MatDialog } from '@angular/material/dialog';
import { AddAlert } from './add/add-alert.component';

@Component({
	selector: 'app-alert-config',
	templateUrl: './alert-config.component.html',
	styleUrls: ['./alert-config.component.css']
})

export class AlertConfig implements OnInit {

	constructor(public dialog: MatDialog) {}

	ngOnInit(): void {}

	openDialog() {
		const dialogRef = this.dialog.open(AddAlert);
	
		dialogRef.afterClosed().subscribe(result => {
		  	console.log(`Dialog result: ${result}`);
		});
	}

}
