import { NgModule } from '@angular/core';
import { RouterModule } from '@angular/router';
import { ReactiveFormsModule } from '@angular/forms';
import { CommonModule } from '@angular/common';
import { HttpClient } from '@angular/common/http';

import { GeneralSettingRoutingModule } from './general-setting-rooting.module';

import { CurrencyComponent } from './currency/currency.component';
import { ValidateComponent } from './currency/validate/validate.component';
import { AddCurrencyComponent } from './currency/add/add-currency.component';
import { ConfigCurrencyComponent } from './currency/config/config-currency.component';
import { MnoComponent } from './pays-mno-eme/mno.component';
import { AddMno } from './pays-mno-eme/add/add-mno.component';
import { OfferBizaoComponent } from './offer/offer.component';
import { GeneralSettingsComponent } from './general-settings.component';
import { AddOfferComponent } from './offer/add/add-offer.component';

// import ngx-translate and the http loader
import { TranslateLoader, TranslateModule } from '@ngx-translate/core';
import { TranslateHttpLoader } from '@ngx-translate/http-loader';
import { MatMenuModule } from '@angular/material/menu';


@NgModule({
    imports: [
        CommonModule,
        ReactiveFormsModule,
        GeneralSettingRoutingModule,
        TranslateModule.forRoot({
			loader: {
				provide: TranslateLoader,
				useFactory: HttpLoaderFactory,
				deps: [HttpClient],
			},
		}),
        MatMenuModule,
        RouterModule
    ],
    declarations: [
        CurrencyComponent,
        ValidateComponent,
        AddCurrencyComponent,
        ConfigCurrencyComponent,
        MnoComponent,
        AddMno,
        OfferBizaoComponent,
        AddOfferComponent,
        GeneralSettingsComponent
    ]
})
export class GeneralSettingsModule { }

// required for AOT compilation
export function HttpLoaderFactory(http: HttpClient): TranslateHttpLoader {
	return new TranslateHttpLoader(http);
}
