import { NgModule } from '@angular/core';
import { Routes, RouterModule } from '@angular/router';

import { LayoutComponent } from '@app/components/layout/layout.component';
import { ProfilComponent } from './profil.component';
import { ProfilConfigComponent } from './profile-config/profil-config.component';

const routes: Routes = [
    {
        path: '', component: LayoutComponent,
        children: [
            { path: '', component: ProfilComponent },
            { path: 'config', component: ProfilConfigComponent }
        ]
    }
];

@NgModule({
    imports: [RouterModule.forChild(routes)],
    exports: [RouterModule]
})
export class ProfilsRoutingModule { }
