import { Component, OnInit } from '@angular/core';
import { User } from 'src/app/_models';
import { AccountService } from '../../../../_services/account.service';

@Component({
	selector: 'app-footer',
	templateUrl: './footer.component.html',
	styleUrls: ['./footer.component.css']
})
export class FooterComponent implements OnInit {

	user: User;
	selectedService: String;

	constructor(private accountService: AccountService) {
		this.accountService.user.subscribe(x => this.user = x);
		this.accountService.selectedService.subscribe(x => {
			this.selectedService = x;
			
		});
	}

	ngOnInit(): void {}
}
