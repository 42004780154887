import { Partner } from "@app/_models";

export class PartnerUtils {

    arrayToPartner(arr: any[]) {
        let partners: Partner[] = [];
        arr.forEach(a => {
            let p = new Partner();

            p.setId(a.partnerId);
            p.setContact(a.partnerContact);
            p.setCancelUrl(a.partnerCancelURL);
            p.setFailureUrl(a.partnerFailureURL);
            p.setCallbackUrl(a.partnerCallbackURL);
            p.setName(a.partnerName);
            p.setOffers(a.offers);
            p.setType(a.partnerType);
            p.setOperatingCountries(a.partnerOperatingCountries);

            partners.push(p);
        });

        return partners;
    }

}
