import { Component, Input } from '@angular/core';
import { FormGroup } from '@angular/forms';
import { first } from 'rxjs/operators';
import { MatDialog } from '@angular/material/dialog';

import { AccountService, AlertService } from '@app/_services';

@Component({
	selector: 'delete-alert',
	templateUrl: './delete.component.html'
})

export class DeleteAlert {

	form: FormGroup;
    loading = false;
    submitted = false;
    @Input() userId: number;

    constructor(
        private accountService: AccountService,
		private dialog: MatDialog,
        private alertService: AlertService
    ) {}

	ngOnInit() {}

    dismissDialog() {
        this.dialog.closeAll();
    }

    processDelete() {
        console.log("user processDelete : ", this.userId);
        this.accountService.delete(this.userId)
            .pipe(first())
            .subscribe(() => {
                let newUsersarray = this.accountService.getUsers().filter(user => parseInt(user.id) !== this.userId);
                this.accountService.emitUsers(newUsersarray);
                this.alertService.success('Success', 'Utilisateur supprimé avec succès', { keepAfterRouteChange: true });
                this.dialog.closeAll();
            },
            error => {
                this.alertService.success('Success', 'Utilisateur supprimé avec succès', { keepAfterRouteChange: true });
                console.log('after update error : ', error);
                this.dialog.closeAll();
                this.loading = false;
            });
    }
}
