import { Component, OnInit } from '@angular/core';
import { Router, ActivatedRoute } from '@angular/router';
import { FormBuilder, FormGroup, Validators } from '@angular/forms';
import { AccountService } from '@app/_services';

@Component({
	selector: 'app-select-service',
	templateUrl: './select-service.component.html',
	styleUrls: ['./select-service.component.css']
})

export class SelectServiceComponent implements OnInit {
	form: FormGroup;
	loading = false;
    submitted = false;

	constructor(
		private formBuilder: FormBuilder,
        private route: ActivatedRoute,
        private router: Router,
		private accountService: AccountService
	) { }

	ngOnInit(): void {
		this.form = this.formBuilder.group({
            service: ['', Validators.required]
        });
	}

	// convenience getter for easy access to form fields
    get f() { return this.form.controls; }

    onSubmit() {
        this.submitted = true;

        // stop here if form is invalid
        if (this.form.invalid) {
			console.log('this.form : ', this.form);
            //return;
        }
		console.log('this.form : ', this.form.value);
		this.accountService.setSelectedService(this.form.value.service);
		this.router.navigate(['/home'], { relativeTo: this.route });
    }

	onSelectService(event) {}

}
