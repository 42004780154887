import { Component, OnInit, AfterViewInit } from '@angular/core';
import { MatDialog, MatDialogConfig } from '@angular/material/dialog';
import { Partner } from '@app/_models';
import { ModalPartnerComponent } from './add-partner-modal/modal.component';
import { PartnerService } from '@app/_services';
import { PartnerUtils } from '@app/utils';
import { DeleteAlert } from './delete/delete.component';

@Component({
	selector: 'app-partners-management',
	templateUrl: './partners-management.component.html',
	styleUrls: ['./partners-management.component.css']
})

export class PartnersManagementComponent implements OnInit, AfterViewInit {

	p: number = 1;
	sectionTitle = "Partenaires";
	sectionItem = "Gestion des partenaires";
	sectionLeftTitle = "Gestion des partenaires";
	sectionRightTitle = "To be defined";
	partners: Partner[] = [];
	loader: boolean = false;

	constructor(public dialog: MatDialog, private partnerService: PartnerService) { }

	ngAfterViewInit(): void { }

	ngOnInit(): void {
		this.loader = true;
		this.partnerService.partnersSubject.subscribe(
			(partners: any[]) => {
				this.loader = false;
				this.partners = new PartnerUtils().arrayToPartner(partners);
			});
		this.partnerService.emitPartners();
	}

	openDialog() {
		const dialogConfig = new MatDialogConfig();

		dialogConfig.disableClose = true;
		dialogConfig.autoFocus = true;
		const dialogRef = this.dialog.open(ModalPartnerComponent, dialogConfig);
		dialogRef.componentInstance.isEditingPartner = false;
	}

	openDeletePartnerModal(id) {
		const dialogRef = this.dialog.open(DeleteAlert);
		dialogRef.componentInstance.parnerId = id;
	}

	openUpdatePartner(id) {
		let partnerToEdit = this.partners.filter(partner => partner.getId() == id)[0];
		this.partnerService.setCurrentPartner(partnerToEdit);

		const dialogConfig = new MatDialogConfig();

		dialogConfig.disableClose = true;
		dialogConfig.autoFocus = true;
		const dialogRef = this.dialog.open(ModalPartnerComponent, dialogConfig);
		dialogRef.componentInstance.isEditingPartner = true;
	}
}
