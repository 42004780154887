<div class="none-padding  bg-white">

    <div class="border-bottom block-header padding">
        <div class="grid grid-cols-3 shadow-lg">
			<div class="col-span-2">
                <h6>
                    Détails des transactions
                </h6>
            </div>

			<div class="col-span-1">
                <button
                    class="bg-white text-black active:bg-white font-bold
                    text-base px-5 py-3 rounded-full shadow-md hover:shadow-lg outline-none
                    focus:outline-none mr-1 mb-1 ease-linear transition-all duration-150
                     inline btn-filter float-right" cdkFocusInitial (click)='downloadExcel()'>
                    <img src="../../../../../../../assets/icones/Boutton/exporter.svg" alt="Filter icone" class="inline mr-2"/>
                    Export Excel
                </button>
            </div>
        </div>

        <!-- <button class="shadow-sm bg-white white-button float-right content-shadow" (click)="downloadExcel()">
            <img _ngcontent-agq-c177="" src="/assets/img/icons/csv.svg" alt="dashboard" height="15px" width="30px">
            Export excel
        </button>

        <button class="shadow-sm bg-white white-button float-right mr-15 content-shadow"
            (click)="refrechTransactionStatus()" title="Rafraichir les transactions InProgress"
            style="padding: 5px 10px 5px 10px;">
            <img _ngcontent-agq-c177="" src="/assets/img/reload.png" alt="dashboard" height="30px" width="30px"
                [ngClass]="{
                    'image-animation': getLoaderRefresh()
                }" />
        </button>

        <button class="shadow-sm bg-white white-button float-right mr-15 content-shadow"
            (click)="refrechFailedTransaction()" title="Rafraichir une transaction Failed"
            style="padding: 5px 10px 5px 10px;">

            <img _ngcontent-agq-c177="" src="/assets/img/refresh-falied.png" alt="dashboard" height="30px" width="30px"
                [ngClass]="{
                    'image-animation': getFailedLoader()
                }" />
        </button> -->

        <!-- <button class="shadow-sm bg-white white-button float-right mr-15" (click)="downloadCsv()">
            <img _ngcontent-agq-c177="" src="/assets/img/icons/csv.svg"
                alt="dashboard" height="15px" width="30px">
            Export CSV
        </button> -->
    </div>

    <!-- This example requires Tailwind CSS v2.0+ -->
    <div class="flex flex-col">
        <div class="-my-2 overflow-x-auto sm:-mx-6 lg:-mx-8">
            <div class="py-2 align-middle inline-block min-w-full sm:px-6 lg:px-8">
                <div class="shadow overflow-hidden border-b border-gray-200 sm:rounded-lg">
                    <table class="min-w-full divide-y divide-gray-200">
                        <thead class="bg-gray-50">
                            <tr>
                                <th scope="col"
                                    class="px-6 py-3 text-left text-xs font-medium text-gray-500 uppercase tracking-wider">
                                    #
                                </th>

                                <th scope="col"
                                    class="px-6 py-3 text-left text-xs font-medium text-gray-500 uppercase tracking-wider">
                                    FromCountry
                                </th>

                                <th scope="col"
                                    class="px-6 py-3 text-left text-xs font-medium text-gray-500 uppercase tracking-wider">
                                    ToCountry
                                </th>

                                <th scope="col"
                                    class="px-6 py-3 text-left text-xs font-medium text-gray-500 uppercase tracking-wider">
                                    Operator
                                </th>

                                <th scope="col"
                                    class="px-6 py-3 text-left text-xs font-medium text-gray-500 uppercase tracking-wider">
                                    Merchant Reference
                                </th>

                                <th scope="col"
                                    class="px-6 py-3 text-left text-xs font-medium text-gray-500 uppercase tracking-wider">
                                    Amount
                                </th>

                                <th scope="col"
                                    class="px-6 py-3 text-left text-xs font-medium text-gray-500 uppercase tracking-wider">
                                    Commission IRT
                                </th>

                                <th scope="col"
                                    class="px-6 py-3 text-left text-xs font-medium text-gray-500 uppercase tracking-wider">
                                    Commission Bizao
                                </th>

                                <th scope="col" class="relative px-6 py-3">
                                    <span class="sr-only">Edit</span>
                                </th>
                            </tr>
                        </thead>

                        <tbody class="bg-white divide-y divide-gray-200">
                            <!-- *ngFor="let transaction of transactions" -->
                            <tr *ngFor="let transaction of transactions | paginate: { itemsPerPage: 10, currentPage: p }, let index = index">
                                <td class="px-6 py-4 whitespace-nowrap text-sm text-gray-500">
                                    {{ index + 1 }}
                                </td>

                                <td class="px-6 py-4 whitespace-nowrap text-sm text-gray-500">
                                    {{ transactionService.country[transaction.fromCountryCode.toUpperCase()] }}
                                </td>

                                <td class="px-6 py-4 whitespace-nowrap text-sm text-gray-500">
                                    {{ transactionService.country[transaction.toCountryCode.toUpperCase()] }}
                                </td>

                                <td class="px-6 py-4 whitespace-nowrap text-sm text-gray-500">
                                    {{ transaction.mnoName }}
                                </td>

                                <td class="px-6 py-4 whitespace-nowrap text-sm text-gray-500">
                                    {{ transaction.merchantReference }}
                                </td>

                                <td class="px-6 py-4 whitespace-nowrap text-sm text-gray-500">
                                    {{ transaction.amount }}
                                </td>

                                <td class="px-6 py-4 whitespace-nowrap text-sm text-gray-500">
                                    {{ transaction.commissionIrt }}
                                </td>

                                <td class="px-6 py-4 whitespace-nowrap text-sm text-gray-500">
                                    {{ transaction.commissionBizao }}
                                </td>

                            </tr>

                            <!-- More people... -->
                        </tbody>
                    </table>

                    <pagination-controls
                        (pageChange)="p = $event"
                        responsive="true"
                        previousLabel="Précédent"
                        nextLabel="Suivant"></pagination-controls>

                </div>
            </div>
        </div>
    </div>

    <ng-template #content let-modal>
        <div class="modal-body none-padding">
            <div class="none-padding">
                <div class="row">
                    <div class="col-md-5 left-block text-center">
                        <img _ngcontent-agq-c177="" src="/assets/img/list-current-transaction.png" alt="dashboard"
                            width="100px">
                        <h5>
                            N° {{ currentTransaction[0].referenceNumber }}
                        </h5>
                        <h3>
                            {{ currentTransaction[0].amount }} FCFA
                        </h3>

                        <h6>
                            {{ currentTransaction[0].date | date:'fullDate':'UTC' }}
                        </h6>
                    </div>

                    <div class="col-md-7 rigth-block">
                        <h6> Details transaction </h6>
                        <div class="row">
                            <div class="col-md-5">
                                <ul class="list-item-title">
                                    <li>
                                        Date :
                                    </li>
                                    <li>
                                        Partenaire :
                                    </li>
                                    <li>
                                        Transaction ID :
                                    </li>

                                    <li>
                                        Prénom :
                                    </li>
                                    <li>
                                        Nom :
                                    </li>

                                    <li>
                                        E-mail :
                                    </li>

                                    <li>
                                        Téléphone :
                                    </li>

                                    <li>
                                        Offre :
                                    </li>

                                    <li>
                                        country :
                                    </li>
                                    <li>
                                        Opérateur :
                                    </li>
                                    <li>
                                        Partner reference :
                                    </li>
                                    <li>
                                        Montant :
                                    </li>
                                    <li>
                                        Devise :
                                    </li>
                                    <li>
                                        Statut :
                                    </li>
                                </ul>
                            </div>

                            <div class="col-md-7">
                                <ul class="list-item-values">
                                    <li>
                                        {{ currentTransaction[0].date | date:'fullDate':'UTC' }}
                                    </li>

                                    <li>
                                        {{ currentTransaction[0].partnerReference }}
                                    </li>

                                    <li>
                                        {{ currentTransaction[0].routers ? currentTransaction[0].routers.code : '-' }}
                                    </li>

                                    <li>
                                        {{ currentTransaction[0].user ? currentTransaction[0].user.firstName : '-' }}
                                    </li>

                                    <li>
                                        {{ currentTransaction[0].user ? currentTransaction[0].user.lastName : '-' }}
                                    </li>

                                    <li>
                                        {{ currentTransaction[0].user ? currentTransaction[0].user.email : '-' }}
                                    </li>

                                    <li>
                                        {{ currentTransaction[0].user ? currentTransaction[0].user.phone : '-' }}
                                    </li>

                                    <li>
                                        {{ currentTransaction[0].package ? currentTransaction[0].package.offer.label :
                                        '-' }}
                                    </li>

                                    <li>
                                        {{ currentTransaction[0].country }}
                                    </li>
                                    <li>
                                        {{ currentTransaction[0].mno }}
                                    </li>
                                    <li>
                                        {{ currentTransaction[0].partnerReference }}
                                    </li>
                                    <li>
                                        {{ currentTransaction[0].amount }}
                                    </li>
                                    <li>
                                        {{ currentTransaction[0].currency }}
                                    </li>
                                    <li>
                                        {{ currentTransaction[0].status }}
                                    </li>
                                </ul>
                            </div>
                        </div>

                    </div>
                </div>
            </div>

            <!-- <div class="modal-header">
                <h4 class="modal-title" id="modal-basic-title">Transaction</h4>
                <button type="button" class="close" aria-label="Close" (click)="modal.dismiss('Cross click')">
                    <span aria-hidden="true">&times;</span>
                </button>
            </div> -->


        </div>
    </ng-template>

</div>
