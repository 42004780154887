import { Component } from '@angular/core';

@Component({
	selector: 'add-alert',
	templateUrl: './add-alert.component.html'
})

export class AddMnoEme {

    constructor() {}

}
