import { Component, Input, OnInit } from '@angular/core';
import { MatDialog, MatDialogConfig } from '@angular/material/dialog';
import { ApiConfiguration } from '../api-configuration/api-configuration.component';
import { ModalPartnerComponent } from '../add-partner-modal/modal.component';
import { PartnerService } from '@app/_services';

@Component({
	selector: 'add-new-offer',
	templateUrl: 'add-new-offer.component.html',
})

export class AddNewOfferComponent implements OnInit {

	offer: string;
	callBackUrl: string;
	cancelUrl: string;
	failureUrl: string;
	@Input() isEditingPartner: boolean;
	type: string;
	constructor(public dialog: MatDialog, private partnerService: PartnerService) { }

	ngOnInit(): void {
		this.offer = this.partnerService.getCurrentPartner().getOffers();
		this.callBackUrl = this.partnerService.getCurrentPartner().getCallbackUrl();
		this.cancelUrl = this.partnerService.getCurrentPartner().getCancelUrl();
		this.failureUrl = this.partnerService.getCurrentPartner().getFailureUrl();

		this.type = this.partnerService.getCurrentPartner().getType();
	}

	closeDialog() {
		this.dialog.closeAll();
	}

	openDialogNext() {
		this.partnerService.getCurrentPartner().setCancelUrl(this.cancelUrl);
		this.partnerService.getCurrentPartner().setOffers(this.offer);
		this.partnerService.getCurrentPartner().setCallbackUrl(this.callBackUrl);
		this.partnerService.getCurrentPartner().setFailureUrl(this.failureUrl);

		const dialogConfig = new MatDialogConfig();

		dialogConfig.disableClose = true;
		dialogConfig.autoFocus = true;

		this.dialog.closeAll();
		const dialogRef = this.dialog.open(ApiConfiguration, dialogConfig);
		dialogRef.componentInstance.isEditingPartner = this.isEditingPartner;
	}

	openDialogPrevious() {
		const dialogConfig = new MatDialogConfig();

		dialogConfig.disableClose = true;
		dialogConfig.autoFocus = true;

		this.dialog.closeAll();
		const dialogRef = this.dialog.open(ModalPartnerComponent, dialogConfig);
		dialogRef.componentInstance.isEditingPartner = this.isEditingPartner;
	}
}
