<nav class="h-auto">
	<div class="nav-wrapper sm:grid sm:grid-cols-3 lg:grid lg:grid-cols-3 xl:grid xl:grid-cols-3
		2xl:grid 2xl:grid-cols-3">
		<div class="px-10 col-span-3 xl:col-span-1 2xl:col-span-1 lg:col-span-1 md:col-span-3 sm:col-span-3" style="padding-left: 25px;">
			<a href="#" class="breadcrumb app-root text-breadcrumb">App</a>
			<a href="#" class="breadcrumb text-breadcrumb">{{ 'sidenav.dashboard.title' | translate }}</a>
			<a href="#" class="breadcrumb text-breadcrumb">{{ 'sidenav.dashboard.global' | translate }}</a>
		</div>

		<div class="col-span-3 xl:col-span-2 2xl:col-span-2 lg:col-span-2 md:col-span-3 sm:col-span-3">
			<app-filter></app-filter>
		</div>

	</div>
</nav>

<div class="pb-10">
	<div class="bg-white rounded m-5 p-0 sm:w-full">
		<div class="pr-3 grid grid-cols-3 shadow-lg w-full">

			<div class="lg:col-span-2 md:col-span-3 xs:col-span-3 col-span-3 sm:col-span-3 xl:col-span-2 2xl:col-span-2 bg-white
				lg:border-r-2 xl:border-r-2 2xl:border-r-2 lg:p-10 xl:p-10 2xl:p-10
				sm:py-10 md:py-10 sm:px-3 md:px-3 relative">
				<div>
					<span class="spinner-border spinner-border-lg align-center"></span>
				</div>
				<h6 class="inline-block align-middle">Volume des transferts </h6>
				<div class="loader ease-linear rounded-full border-2 border-t-2 border-gray-200 h-10 w-10 inline-block ml-5 mt-2 absolute right-11" *ngIf="getLoader()"></div>
				<app-chart transactionType='volume'></app-chart>
			</div>

			<div class="lg:col-span-1 md:col-span-3 sm:col-span-3 col-span-3 xl:col-span-1 2xl:col-span-1 xs:col-span-3 grid
				xl:grid-rows-2 2xl:grid-rows-2 md:grid-cols-2 sm:grid-cols-2 sm:pb-5 md:pb-5">

				<div class="xl:col-span-2 2xl:col-span-2 lg:col-span-2 md:col-span-1 sm:col-span-2 row-span-1
					grid grid-cols-1 justify-center items-center
					xl:border-b-2 2xl:border-b-2 lg:border-b-2 sm:border-r-2 sm:pb-5 pb-5 pt-5">
					<app-amount-card class=""
						[amount]="totalSucccessRevene"
						title="Montant en CFA"
						badge="none"
						description="Montant total des transactions à succès"
						currency="XOF"></app-amount-card>
				</div>

				<div class="lg:col-span-2 xl:col-span-2 2xl:col-span-2 md:col-span-1 sm:col-span-2 row-span-1
					grid grid-cols-1 justify-center items-center sm:pt-5 pt-5 pb-5">
					<app-amount-card class=""
						[amount]="transactionsVolume"
						title="Nombre de transferts"
						badge="none"
						description="Volume total de transferts effectués"
						currency="no"></app-amount-card>
				</div>
			</div>
		</div>
	</div>

	<div class="w-full pr-3 rounded m-5">
		<div class="grid grid-cols-2 gap-2">
			<div class="xl:col-span-1 2xl:col-span-1 lg:col-span-1 md:col-span-2 sm:col-span-2 col-span-2 bg-white
				rounded  p-5 shadow-lg">
				<div>
					<span class="spinner-border spinner-border-lg align-center"></span>
				</div>
				<h6>Commission</h6>
				<app-chart transactionType='commission'></app-chart>
			</div>

			<div class="xl:col-span-1 2xl:col-span-1 lg:col-span-1 md:col-span-2 col-span-2 sm:col-span-2
				rounded bg-white p-5 shadow-lg">
				<div>
					<span class="spinner-border spinner-border-lg align-center"></span>
				</div>
				<h6>Trafic</h6>
				<app-chart transactionType='trafic'></app-chart>
			</div>
		</div>
	</div>

	<div class="w-full pr-3 bg-white rounded mx-5 mb-10 shadow-xl">
		<div class="grid grid-cols-1 gap-2 p-5">
			<app-transaction-table ></app-transaction-table>
		</div>
	</div>

</div>
