import { Component, OnInit } from '@angular/core';
import { MatDialog } from '@angular/material/dialog';
import { AdminNameModalComponent } from './admin-name-modal/admin-name-modal.component';
import { ProfilService } from '@app/_services';
import { Profil } from '@app/_models';
import { DeleteAlert } from './modal-delete/delete.component';

@Component({
	selector: 'app-profil',
	templateUrl: './profil.component.html',
	styleUrls: ['./profil.component.css']
})

export class ProfilComponent implements OnInit {

	profils: Profil[];
	constructor(public dialog: MatDialog, private profilService: ProfilService) {}

	ngOnInit(): void {
		this.profilService.profilsSubject.subscribe(resp => {
			this.profils =  resp;
		});
        this.profilService.emitProfils();
	}

	openDialog(profil?: any) {
		const dialogRef = this.dialog.open(AdminNameModalComponent);
		dialogRef.componentInstance.profil = profil;
	}

	deleteItem(profil: Profil, index){
		const dialogRef = this.dialog.open(DeleteAlert);
		dialogRef.componentInstance.profil = profil;
	}
}
