<div class="flex flex-col">
	<div class="my-2 overflow-x-auto sm:-mx-6 lg:-mx-8">
		<div class="py-2 align-middle inline-block min-w-full sm:px-6 lg:px-8 grid grid-cols-6 gap-4">

			<div class="col-span-6 pb-5 pl-10 border-gray-200 border-b relative">
                <h6>
                    Ajouter une offre
                </h6>

				<h6 class="text-blak text-right absolute right-5 top-1 font-bold px-5 cursor-pointer"
					(click)="closeDialog()">
					x
				</h6>
            </div>

			<div class="col-start-2 col-span-4 pt-5">

				<div class="grid grid-cols-4">

					<div class="col-span-1 text-right pt-5 pr-5 mt-5">
						<span class="text-gray-700">Offre</span>
					</div>
					<div class="col-span-3 mt-5">
						<select name="offer" id="offer" class="block" [(ngModel)]="offer">
							<option value="dcb" *ngIf="type === 'service-provider'">DCB</option>
							<option value="mobile-money" *ngIf="type === 'merchand'">Mobile money</option>
							<option value="money-transfert" *ngIf="type === 'irt'">Tranfert d'argent</option>
							<option value="bulk">Bulk</option>
						</select>
					</div>

					<div class="col-span-1 text-right pt-5 pr-5 mt-5">
						<span class="text-gray-700">Callback url</span>
					</div>
					<div class="col-span-3 mt-5">
						<input type = "text" placeholder="Callback url" [(ngModel)]="callBackUrl"/>
					</div>

					<div class="col-span-1 text-right pt-5 pr-5 mt-5">
						<span class="text-gray-700">Cancel url</span>
					</div>
					<div class="col-span-3 mt-5">
						<input type = "text" placeholder="Cancel url" [(ngModel)]="cancelUrl"/>
					</div>

					<div class="col-span-1 text-right pt-5 pr-5 mt-5">
						<span class="text-gray-700">Failure url</span>
					</div>
					<div class="col-span-3 mt-5">
						<input type = "text" placeholder="Failure url" [(ngModel)]="failureUrl"/>
					</div>

					<div class="col-span-4 text-center pb-5">
						<button class="bg-white-500 text-black shadow-lg font-bold py-2 px-10 rounded-full mt-5 mr-5" (click)="openDialogPrevious()">
							Précédent
						</button>

						<button class="bg-yellow-500 text-white shadow-lg font-bold py-2 px-10 rounded-full mt-5" (click)="openDialogNext()">
							Suivant
						</button>
					</div>

				</div>
			</div>
		</div>
	</div>
</div>
