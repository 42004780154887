import { Subject, forkJoin } from "rxjs";
import { Transaction, IrtCommission } from '../_models/';
import { Injectable } from '@angular/core';
import { HttpClient, HttpHeaders } from '@angular/common/http';
import { environment } from '../../environments/environment';
import {TransactionUtils} from '@app/utils/transactions.utils';
import { Utils, TransactionFilterUtils } from "@app/utils/";
import { AccountService } from ".";
import countries_code from '../utils/countries_code.json';



@Injectable({ providedIn: 'root' })
export class TransactionService {

    transactionsSubject = new Subject<Transaction[]>();
    commissionSubject = new Subject<IrtCommission[]>();
    globalRevenuSubject = new Subject<number>();
    transactionsVolumeSubject = new Subject<number>();

    weekRangeSubject = new Subject<any[]>();
    allTransactions: Transaction[];
    loaderTransactions = true;
    loaderRefresh = false;
    loaderFailedRefresh = false;
    rawData = {};
    transactionUtils = new TransactionUtils();
    utils = new Utils();
    transactionFilterUtils = new TransactionFilterUtils();
    selectedService: string;


    country = countries_code;

    public transactions = [];
    private irtCommissions = [];
    private globalRevenu = null;
    private transactionVolume = null;
    private weekRange = [];

    constructor(private http: HttpClient, private accountService: AccountService) {
        this.accountService.selectedService.subscribe(x => {
			this.selectedService = x;
            if(this.weekRange['firstday']) {
                this.loaderTransactions = true;
                this.transactions = [];
                this.transactionVolume = [];
                this.globalRevenu = [];

                this.fetchAllData(this.weekRange['firstday'], this.weekRange['lastday'], '');
            }
		});
        this.weekRange = this.getWeekRange();
    }

    emitTransactions() {
        this.transactionsSubject.next(this.transactions.slice());
    }

    emitVolume() {
        this.transactionsVolumeSubject.next(this.transactionVolume);
    }

    emitGlobalRevenu() {
        this.globalRevenuSubject.next(this.globalRevenu);
    }

    emitIrtCommission() {
        this.commissionSubject.next(this.irtCommissions);
    }

    emitWeekRange() {
        this.weekRangeSubject.next(this.weekRange.slice());
    }

    getAll() {
        return this.allTransactions;
    }

    fetchGlobalRevenu(start: string, end: string, partner: string) {
        let type = this.selectedService == 'mobile-transfer' ? 'wallet' : 'bulk';
        const requestOptions = {
            headers: new HttpHeaders({
                'Service-Provider': partner ? partner : '',
                'Start-Date': start,
                'End-Date': end,
                'Type': type
            }),
        };

        return this.http.get<object>(`${environment.api}/dashboard-manager/bizao-global-revenue`, requestOptions);
    }

    fetchByDateInterval(start: string, end: string, partner: string) {
        let type = this.selectedService == 'mobile-transfer' ? 'wallet' : 'bulk';
        const requestOptions = {
            headers: new HttpHeaders({
                'Service-Provider': partner ? partner : '',
                'Start-Date': start,
                'End-Date': end,
                'Type': type
            }),
        };

        return this.http.get<object>(`${environment.api}/dashboard-manager/money-transfer-details`, requestOptions);
    }

    fetchIrtCommission(start: string, end: string, spName: string) {
        let type = this.selectedService == 'mobile-transfer' ? 'wallet' : 'bulk';
        const requestOptions = {
            headers: new HttpHeaders({
                'Service-Provider': spName ? spName : '',
                'Start-Date': start,
                'End-Date': end,
                'Type': type
            }),
        };

        return this.http.get<object>(`${environment.api}/dashboard-manager/irt-commissions`, requestOptions);
    }

    applyFilter(weekRange) {
        this.weekRange = weekRange;
        this.transactions = [];

        if (this.weekRange['fromCountry'].length >= 1) {
            this.transactions = this.transactionFilterUtils.filterFromCountry(this.allTransactions, this.weekRange['fromCountry'] );
        }

        if (this.weekRange['partnerId'].length >= 1) {
            if (this.transactions.length <= 0) {
                this.transactions = this.transactionFilterUtils.filterByPartnerId(this.allTransactions, this.weekRange['partnerId'] );
            } else {
                this.transactions = this.transactionFilterUtils.filterByPartnerId(this.transactions, this.weekRange['partnerId'] );
            }
        }

        if (this.weekRange['toCountry'].length >= 1) {
            if (this.transactions.length <= 0) {
                this.transactions = this.transactionFilterUtils.filterToCountry(this.allTransactions, this.weekRange['toCountry']);
            } else {
                this.transactions = this.transactionFilterUtils.filterToCountry(this.transactions, this.weekRange['toCountry']);
            }
        }

        if (this.weekRange['merchantReference'].length >= 1) {
            if (this.transactions.length <= 0) {
                this.transactions = this.transactionFilterUtils.filterByMerchantReference(this.allTransactions, this.weekRange['merchantReference']);
            } else {
                this.transactions = this.transactionFilterUtils.filterByMerchantReference(this.transactions, this.weekRange['merchantReference']);
            }
        }

        if (this.weekRange['fromCountry'].length >= 1) {
            if (this.transactions.length <= 0) {
                this.transactions = this.transactionFilterUtils.filterFromCountry(this.allTransactions, this.weekRange['fromCountry']);
            } else {
                this.transactions = this.transactionFilterUtils.filterFromCountry(this.transactions, this.weekRange['fromCountry']);
            }
        }

        if (this.weekRange['operator'].length >= 1) {
            if (this.transactions.length <= 0) {
                this.transactions = this.transactionFilterUtils.filterByMno(this.allTransactions, this.weekRange['operator']);
            } else {
                this.transactions = this.transactionFilterUtils.filterByMno(this.transactions, this.weekRange['operator']);
            }
        }

        if (this.weekRange['status'].length >= 1) {
            if (this.transactions.length <= 0) {
                this.transactions = this.transactionFilterUtils.filterByStatus(this.allTransactions, this.weekRange['status']);
            } else {
                this.transactions = this.transactionFilterUtils.filterByStatus(this.transactions, this.weekRange['status']);
            }
        }

        this.transactions = this.utils.sortByDate(this.transactions);
        this.emitTransactions();
    }

    fetchAllData(start, end, partner) {
        forkJoin({
            transactions: this.fetchByDateInterval(start, end, partner),
            globalRevenu: this.fetchGlobalRevenu(start, end, partner),
            irtCommissions: this.fetchIrtCommission(start, end, partner)
        }).subscribe(({transactions, globalRevenu, irtCommissions}) => {
           // console.log('this.transactions : ', transactions);
            transactions['data'].forEach(element => {
                this.transactions.push(this.transactionUtils.rawDataTOTransaction(element));
            });

            irtCommissions['data'].forEach(element => {
                this.irtCommissions.push(this.transactionUtils.rawDataToIrtCommission(element));
            });

            this.globalRevenu = globalRevenu['data'][0].turnover_balance;
            this.transactionVolume = transactions['meta'].total_record_count;

            this.transactions = this.utils.sortByDate(this.transactions);
            this.allTransactions = this.utils.sortByDate(this.transactions);

            // Apply filter
            if(this.weekRange['fromCountry'].length >= 1) {
                this.transactions = this.transactionFilterUtils.filterFromCountry(this.transactions, this.weekRange['fromCountry'] );
            }

            if(this.weekRange['toCountry'].length >= 1) {
                this.transactions = this.transactionFilterUtils.filterToCountry(this.transactions, this.weekRange['toCountry']);
            }

            if(this.weekRange['merchantReference'].length >= 1) {
                this.transactions = this.transactionFilterUtils.filterByMerchantReference(this.transactions, this.weekRange['merchantReference']);
            }

            //console.log('this.transactions : ', this.transactions);

            this.emitTransactions();
            this.emitVolume();
            this.emitGlobalRevenu();
            this.loaderTransactions = false;
        });
    }

    getWeekRange() {
        let curr = new Date; // get current date
        let first = curr.getDate() - curr.getDay(); // First day is the day of the month - the day of the week
        let last = first + 6; // last day is the first day + 6

        let firstday = new Date(curr.setDate(first));
        let lastday = new Date(curr.setDate(last));

        return [{
            firstday,
            lastday
        }];
    }

    setWeekRange(weekRange) {
        this.loaderTransactions = true;
        this.transactions = [];
        this.transactionVolume = [];
        this.globalRevenu = [];

        this.weekRange = weekRange;
        this.fetchAllData(weekRange.firstday, weekRange.lastday, '');
    }
}
