import { Component } from '@angular/core';
import { Router } from '@angular/router';

@Component({
	selector: 'add-alert',
	templateUrl: './add-alert.component.html'
})

export class AddAlert {

    constructor(private router: Router) {}

}
