/**
 * @description contains Role information
 */
export class Menu {

    id: string;
    name: string;
    read: boolean;
    write: boolean;

    constructor(id: string, name: string, read: boolean, write: boolean) {
        this.id = id;
        this.name = name;
        this.read = read;
        this.write = write;
    }
}
