import { Component, OnInit } from '@angular/core';

@Component({
	selector: 'app-add-bank-deposit',
	templateUrl: './add-deposit.component.html'
})

export class AddDepositComponent implements OnInit {
	sectionTitle = "Dashboard";
	sectionItem = "Traffic";
	sectionLeftTitle = "Traffic";
	sectionRightTitle = "To be defined";

	constructor() { }

	ngOnInit(): void {}

}
