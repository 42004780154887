import { Component, OnInit, Input, AfterViewInit } from '@angular/core';
import { ChartDataSets, ChartOptions } from 'chart.js';
import { Color, Label } from 'ng2-charts';
import { Transaction, IrtCommission } from '@app/_models';
import { TransactionService } from '@app/_services/transaction.service';
import { Subscription } from 'rxjs';
import { NgbDate } from '@ng-bootstrap/ng-bootstrap';


@Component({
	selector: 'app-chart',
	templateUrl: './chart.component.html',
	styleUrls: ['./chart.component.less']
})

export class ChartComponent implements OnInit, AfterViewInit {

	@Input() transactionType: string;
	transactions: Transaction[];
	fullTransactions: Transaction[];
	irtCommissions: IrtCommission[] = null;
	weekRange: any[];
	transactionSubscription: Subscription;

	dataChart = [];
	irtCommissionChart = [];
	bizaoCommissionChart = [];
	successDataChart = [];
	failedDataChart = [];

	currentDate: Date;

	public lineChartData: ChartDataSets[] = [
		{ data: this.dataChart, label: 'Transactions' },
		{ data: this.successDataChart, label: 'Transactions success' }
	];

	dayTab = ['Dimanche', 'Lundi', 'Mardi', 'Mercredi', 'Jeudi', 'Vendredi', 'Samedi'];
	public lineChartLabels: Label[] = [];

	public lineChartOptions: ChartOptions = {
		responsive: true,
	};
	public lineChartColors: Color[] = [
		{
			borderColor: '#F8AC3D',
			backgroundColor: 'transparent',
		},
		{
			borderColor: '#85cc94',
			backgroundColor: 'transparent',
		},
	];
	public lineChartLegend = true;
	public lineChartType = 'line';
	public lineChartPlugins = [];

	constructor(private transactionService: TransactionService) {}

	ngAfterViewInit(): void {}

	ngOnInit(): void {
		this.transactionSubscription = this.transactionService.transactionsSubject.subscribe(transactions => {
      // Debugging
      //console.log("DATA DEBUGGING", transactions);
			this.transactions = transactions;
			if (this.transactionType == 'volume') {
				this.loadDataChartVolume();
			} else if (this.transactionType == 'trafic') {
				this.loadDataChartTrafic();
			} else if (this.transactionType == 'commission') {
				this.loadDataChartCommission();
			} else if (this.transactionType == 'revenue') {
				this.loadDataChartRevenue();
			}
		});

		this.transactionService.commissionSubject.subscribe(commission => {
			this.irtCommissions = commission;
		});
		this.transactionService.emitIrtCommission();
	}

  	getTotalWeeklyTransactions() {}

	/* getTransactionChartDataFromamount() {
		this.dataChart = [];
		this.lineChartLabels = [];

		this.transactions.forEach((transc, i) => {

			if (i === 0) {
				this.dataChart[0] = transc.amount == null ? 0 : transc.amount;
				let dayIndex = new Date(transc.date).getDay();
				this.lineChartLabels[0] = this.dayTab[dayIndex - 1] + ', ' + new Date(transc.date).getDate();
			} else {
				let transactionDate = new Date(transc.date);
				let transactionNgDate = new NgbDate(transactionDate.getFullYear(), transactionDate.getMonth() + 1, transactionDate.getDate());

				let previousTransactionDate = new Date(this.transactions[i - 1].date);
				let previousTransactionNgDate = new NgbDate(
					previousTransactionDate.getFullYear(), previousTransactionDate.getMonth() + 1, previousTransactionDate.getDate()
				);

				if(transactionNgDate.equals(previousTransactionNgDate)) {
				let amount = transc.amount == null ? 0 : transc.amount;

				this.dataChart[this.dataChart.length - 1] += amount;
				let dayIndexPrevious = new Date(transc.date).getDay();
				this.lineChartLabels[this.dataChart.length - 1] =
					this.dayTab[dayIndexPrevious - 1] + ', ' + new Date(transc.date).getDate();
				} else {
				this.dataChart[this.dataChart.length] = transc.amount;
				let newDateIndex = new Date(transc.date).getDay();
				this.lineChartLabels[this.dataChart.length] = this.dayTab[newDateIndex - 1] + ', ' + new Date(transc.date).getDate();
				}
			}
		});
		this.lineChartData = [
			{ data: this.dataChart, label: 'Transactions' },
		];
	} */

	loadDataChartVolume() {
		this.dataChart = [];
		this.lineChartLabels = [];
		this.transactions.slice().reverse().forEach((transc, i) => {
			if (i <= this.transactions.length - 1) {
				if (i === 0) {
					this.dataChart[0] = 1;
					let dayIndex = new Date(transc.createdAt).getDay();
					this.lineChartLabels[0] = this.dayTab[dayIndex] + ', ' + new Date(transc.createdAt).getDate();

				} else {
					let transactionDate = new Date(transc.createdAt);
					let transactionNgDate = new NgbDate(
						transactionDate.getFullYear(),
						transactionDate.getMonth() + 1,
						transactionDate.getDate()
					);

					let previousTransactionDate = new Date(this.transactions.slice().reverse()[i - 1].createdAt);
					let previousTransactionNgDate = new NgbDate(
						previousTransactionDate.getFullYear(),
						previousTransactionDate.getMonth() + 1,
						previousTransactionDate.getDate()
					);

					if(transactionNgDate.equals(previousTransactionNgDate)) {
						this.dataChart[this.dataChart.length - 1] ++;
					} else {
						this.dataChart[this.dataChart.length] = 1;
						let newDateIndex = new Date(transc.createdAt).getDay();
						this.lineChartLabels[this.dataChart.length - 1] = this.dayTab[newDateIndex] + ', ' + new Date(transc.createdAt).getDate();
					}
				}
			}
		});
		this.lineChartData = [
			{ data: this.dataChart, label: 'Transactions' }
		];
  	}

	loadDataChartTrafic() {
		this.dataChart = [];
		this.failedDataChart = [];
		this.successDataChart = [];
		this.lineChartLabels = [];
		this.transactions.slice().reverse().forEach((transc, i) => {
			if (i < this.transactions.length) {
				if (i === 0) {
					this.dataChart[0] = 1;
					this.failedDataChart[0] = (transc.status.toLocaleLowerCase() == 'failed' || transc.status.toLocaleLowerCase() == 'fail') ? 1 : 0;
					this.successDataChart[0] = (transc.status.toLocaleLowerCase() == 'success' || transc.status.toLocaleLowerCase() == 'successful') ? 1 : 0;

					let dayIndex = new Date(transc.createdAt).getDay();
					this.lineChartLabels[0] = this.dayTab[dayIndex] + ', ' + new Date(transc.createdAt).getDate();
				 } else {
					let transactionDate = new Date(transc.createdAt);
					let transactionNgDate = new NgbDate(
						transactionDate.getFullYear(),
						transactionDate.getMonth() + 1,
						transactionDate.getDate()
					);

					let previousTransactionDate = new Date(this.transactions.slice().reverse()[i-1].createdAt);
					let previousTransactionNgDate = new NgbDate(
						previousTransactionDate.getFullYear(),
						previousTransactionDate.getMonth() + 1,
						previousTransactionDate.getDate()
					);

					if(transactionNgDate.equals(previousTransactionNgDate)) {
						this.dataChart[this.dataChart.length - 1] ++;
						let dayIndexPrevious = new Date(transc.createdAt).getDay();

						if (transc.status.toLocaleLowerCase() == 'success' || transc.status.toLocaleLowerCase() == 'successful') {
							let successTransc = this.successDataChart[this.dataChart.length - 1];
							this.successDataChart[this.dataChart.length - 1] = successTransc ? successTransc + 1 : 1;
						} else if(transc.status.toLocaleLowerCase() == 'failed' || transc.status.toLocaleLowerCase() == 'fail') {
							let failedTransc = this.failedDataChart[this.dataChart.length - 1];
							this.failedDataChart[this.dataChart.length - 1] = failedTransc ? failedTransc + 1 : 1;
						}
					} else {
						this.dataChart[this.dataChart.length] = 1;
						let newDateIndex = new Date(transc.createdAt).getDay();
            			this.lineChartLabels[this.dataChart.length-1] = this.dayTab[newDateIndex] + ', ' + new Date(transc.createdAt).getDate();

						this.successDataChart[this.dataChart.length -1] =  transc.status.toLocaleLowerCase() == 'successful' ? 1 : 0;
						this.failedDataChart[this.dataChart.length -1 ] = transc.status.toLocaleLowerCase() == 'failed'  ? 1 : 0;
					}
				}
			}
		});

		this.lineChartData = [
			{ data: this.failedDataChart, label: 'Failed' },
			{ data: this.successDataChart, label: 'Success' },
		];
	}

	loadDataChartRevenue() {
		this.dataChart = [];
		this.lineChartLabels = [];
		this.irtCommissionChart = [];
		// #Tuning : à améliorer c'est juste pour faire vite pour la démo
		this.transactions.slice().reverse().forEach((transc, i) => {
			if (i <= this.transactions.length - 1) {
				if (i === 0) {
					this.dataChart[0] = parseFloat(transc.amount.toString());
					let dayIndex = new Date(transc.createdAt).getDay();
					this.lineChartLabels[0] = this.dayTab[dayIndex] + ', ' + new Date(transc.createdAt).getDate();
				} else {
					let transactionDate = new Date(transc.createdAt);
					let transactionNgDate = new NgbDate(
						transactionDate.getFullYear(),
						transactionDate.getMonth() + 1,
						transactionDate.getDate()
					);

					let previousTransactionDate = new Date(this.transactions.slice().reverse()[i - 1].createdAt);
					let previousTransactionNgDate = new NgbDate(
						previousTransactionDate.getFullYear(),
						previousTransactionDate.getMonth() + 1,
						previousTransactionDate.getDate()
					);

					if(transactionNgDate.equals(previousTransactionNgDate)) {
						this.dataChart[this.dataChart.length - 1] += parseFloat(transc.amount.toString());
					} else {
						this.dataChart[this.dataChart.length] = parseFloat(transc.amount.toString());
						let newDateIndex = new Date(transc.createdAt).getDay();
						this.lineChartLabels[this.dataChart.length - 1] = this.dayTab[newDateIndex] + ', ' + new Date(transc.createdAt).getDate();
					}
				}
			}
		});

		this.irtCommissions.slice().reverse().forEach((commission, i) => {
			if (i <= this.transactions.length - 1) {
				if (i === 0) {
					this.dataChart[0] = parseFloat(commission.turnoverBalance.toString());
				} else {
					let transactionDate = new Date(commission.createdDate);
					let transactionNgDate = new NgbDate(
						transactionDate.getFullYear(),
						transactionDate.getMonth() + 1,
						transactionDate.getDate()
					);

					let previousTransactionDate = new Date(this.irtCommissions.slice().reverse()[i - 1].createdDate);
					let previousTransactionNgDate = new NgbDate(
						previousTransactionDate.getFullYear(),
						previousTransactionDate.getMonth() + 1,
						previousTransactionDate.getDate()
					);

					if(transactionNgDate.equals(previousTransactionNgDate)) {
						this.irtCommissionChart[this.irtCommissionChart.length - 1] += parseFloat(commission.turnoverBalance.toString());
					} else {
						this.irtCommissionChart[this.irtCommissionChart.length] = parseFloat(commission.turnoverBalance.toString());
					}
				}
			}
		});

		this.lineChartData = [
			{ data: this.dataChart, label: 'Bizao' },
			{ data: this.irtCommissionChart, label: 'IRT' }
		];
	}

	loadDataChartCommission() {
		this.dataChart = [];
		this.lineChartLabels = [];
		this.irtCommissionChart = [];
		this.bizaoCommissionChart = [];

		// #Tuning : à améliorer c'est juste pour faire vite pour la démo
		this.transactions.slice().reverse().forEach((transc, i) => {
			if (i <= this.transactions.length - 1) {
				if (i === 0) {
					this.dataChart[0] = parseFloat(transc.amount.toString());
					this.irtCommissionChart[0] = parseFloat(transc.commissionIrt.toString());
					this.bizaoCommissionChart[0] = parseFloat(transc.commissionBizao.toString());

					let dayIndex = new Date(transc.createdAt).getDay();
					this.lineChartLabels[0] = this.dayTab[dayIndex] + ', ' + new Date(transc.createdAt).getDate();
				} else {
					let transactionDate = new Date(transc.createdAt);
					let transactionNgDate = new NgbDate(
						transactionDate.getFullYear(),
						transactionDate.getMonth() + 1,
						transactionDate.getDate()
					);

					let previousTransactionDate = new Date(this.transactions.slice().reverse()[i - 1].createdAt);
					let previousTransactionNgDate = new NgbDate(
						previousTransactionDate.getFullYear(),
						previousTransactionDate.getMonth() + 1,
						previousTransactionDate.getDate()
					);

					if(transactionNgDate.equals(previousTransactionNgDate)) {
						this.dataChart[this.dataChart.length - 1] += parseFloat(transc.amount.toString());
						this.irtCommissionChart[this.irtCommissionChart.length - 1] += transc.commissionIrt ?
							parseFloat(transc.commissionIrt.toString()) : 0;
						this.bizaoCommissionChart[this.bizaoCommissionChart.length - 1] += transc.commissionBizao ?
							parseFloat(transc.commissionBizao.toString()) : 0;
					} else {
						this.dataChart[this.dataChart.length] = parseFloat(transc.amount.toString());
						this.irtCommissionChart[this.irtCommissionChart.length] = transc.commissionIrt ?
							parseFloat(transc.commissionIrt.toString()) : 0;
						this.bizaoCommissionChart[this.bizaoCommissionChart.length] = transc.commissionBizao ?
							parseFloat(transc.commissionBizao.toString()) : 0;

						let newDateIndex = new Date(transc.createdAt).getDay();
						this.lineChartLabels[this.dataChart.length - 1] = this.dayTab[newDateIndex] + ', ' + new Date(transc.createdAt).getDate();
					}
				}
			}
		});

		this.lineChartData = [
			// { data: this.dataChart, label: 'Bizao' },
			{ data: this.irtCommissionChart, label: 'IRT' },
			{ data: this.bizaoCommissionChart, label: 'Bizao' }
		];
	}

  	isTransactionDateEquals() {}
}
