<nav class="bg-transparent shadow-none">
	<div class="nav-wrapper">
		<div class="col s12">
			<a href="#" class="breadcrumb app-root text-gray-400">App</a>
			<a href="#" class="breadcrumb text-gray-400">Offer</a>
		</div>
	</div>
</nav>

<div class="content pb-10">
	<div class="content-header bg-white rounded m-5 p-0">
		<div class="grid grid-cols-1 shadow-lg p-5">

			<div class="bg-white grid grid-cols-2 border-b-2 border-gray-200 pb-5">
				<h6 class="col-span-1">
					Customer service - request
				</h6>

				<div class="col-span-1 float-right content-shadow">
					<a routerLink="/home/request/add"
						class="bg-yellow-500 text-white font-bold py-2 px-4 rounded-full float-right">
						New request
					</a>
				</div>
			</div>

			<!-- This example requires Tailwind CSS v2.0+ -->
			<div class="flex flex-col">
				<div class="my-2 overflow-x-auto sm:-mx-6 lg:-mx-8">
					<div class="py-2 align-middle inline-block min-w-full sm:px-6 lg:px-8">
						<div class="shadow overflow-hidden border-b border-gray-200 sm:rounded-lg">
							<table class="min-w-full divide-y divide-gray-200">
								<thead class="bg-gray-50">
									<tr>
										<th scope="col"
											class="px-6 py-3 text-left text-xs font-medium text-gray-500 uppercase tracking-wider">
											#
										</th>
		
										<th scope="col"
											class="px-6 py-3 text-left text-xs font-medium text-gray-500 uppercase tracking-wider">
											Ticket Id
										</th>
		
										<th scope="col"
											class="px-6 py-3 text-left text-xs font-medium text-gray-500 uppercase tracking-wider">
											Partner
										</th>
		
										<th scope="col"
											class="px-6 py-3 text-left text-xs font-medium text-gray-500 uppercase tracking-wider">
											User name
										</th>

										<th scope="col"
											class="px-6 py-3 text-left text-xs font-medium text-gray-500 uppercase tracking-wider">
											Product
										</th>

										<th scope="col"
											class="px-6 py-3 text-left text-xs font-medium text-gray-500 uppercase tracking-wider">
											Category
										</th>

										<th scope="col"
											class="px-6 py-3 text-left text-xs font-medium text-gray-500 uppercase tracking-wider">
											Description
										</th>

										<th scope="col"
											class="px-6 py-3 text-left text-xs font-medium text-gray-500 uppercase tracking-wider">
											Criticity
										</th>

										<th scope="col"
											class="px-6 py-3 text-left text-xs font-medium text-gray-500 uppercase tracking-wider">
											Status
										</th>

										<th scope="col"
											class="px-6 py-3 text-left text-xs font-medium text-gray-500 uppercase tracking-wider">
											Handle by
										</th>

									</tr>
								</thead>

								<tbody class="bg-white divide-y divide-gray-200">
									<tr>
										<td class="px-6 py-4 whitespace-nowrap text-sm text-gray-500">
											1
										</td>

										<td class="px-6 py-4 whitespace-nowrap text-sm text-gray-500">
											Bizao-Order-14520
										</td>

										<td class="px-6 py-4 whitespace-nowrap text-sm text-gray-500">
											Small world
										</td>

										<td class="px-6 py-4 whitespace-nowrap text-sm text-gray-500">
											Beta
										</td>

										<td class="px-6 py-4 whitespace-nowrap text-sm text-gray-500">
											DCB
										</td>

										<td class="px-6 py-4 whitespace-nowrap text-sm text-gray-500">
											Lorem ipsum
										</td>

										<td class="px-6 py-4 whitespace-nowrap text-sm text-gray-500">
											Lorem ipsum
										</td>

										<td class="px-6 py-4 whitespace-nowrap text-sm text-gray-500">
											Low
										</td>

										<td class="px-6 py-4 whitespace-nowrap text-sm text-gray-500">
											<div style="padding-top: 0.1em; padding-bottom: 0.1rem" class="text-xs px-3 bg-yellow-200 text-yellow-800 rounded-full">Badge</div>
										</td>

										<td class="px-6 py-4 whitespace-nowrap text-sm text-gray-500">
											Awa Fall
										</td>
									</tr>

									<tr>
										<td class="px-6 py-4 whitespace-nowrap text-sm text-gray-500">
											1
										</td>

										<td class="px-6 py-4 whitespace-nowrap text-sm text-gray-500">
											Bizao-Order-14520
										</td>

										<td class="px-6 py-4 whitespace-nowrap text-sm text-gray-500">
											Small world
										</td>

										<td class="px-6 py-4 whitespace-nowrap text-sm text-gray-500">
											Beta
										</td>

										<td class="px-6 py-4 whitespace-nowrap text-sm text-gray-500">
											DCB
										</td>

										<td class="px-6 py-4 whitespace-nowrap text-sm text-gray-500">
											Lorem ipsum
										</td>

										<td class="px-6 py-4 whitespace-nowrap text-sm text-gray-500">
											Lorem ipsum
										</td>

										<td class="px-6 py-4 whitespace-nowrap text-sm text-gray-500">
											Low
										</td>

										<td class="px-6 py-4 whitespace-nowrap text-sm text-gray-500">
											<div style="padding-top: 0.1em; padding-bottom: 0.1rem" class="text-xs px-3 bg-blue-200 text-blue-800 rounded-full">Badge</div>
										</td>

										<td class="px-6 py-4 whitespace-nowrap text-sm text-gray-500">
											Awa Fall
										</td>
									</tr>
								</tbody>
							</table>

						</div>
					</div>
				</div>
			</div>
		</div>
	</div>
</div>
