import { Component, Input } from '@angular/core';
import { MatDialog, MatDialogConfig } from '@angular/material/dialog';
import { ApiConfiguration } from '../api-configuration/api-configuration.component';
import { PartnerService } from '@app/_services';
import { Router } from '@angular/router';

@Component({
	selector: 'select-mno',
	templateUrl: 'select-mno.component.html',
})

export class SelectMnoComponent {

	countryArray: any[];
	indexCountry = 0;
	currentPartnerName = '';
	@Input() isEditingPartner;

	constructor(public dialog: MatDialog, private partnerService: PartnerService, private router: Router) {
		this.countryArray = this.partnerService.getCurrentPartner().getOperatingCountries();
		this.currentPartnerName = this.partnerService.getCurrentPartner().getName();
	}

	closeDialog() {
		this.dialog.closeAll();
	}

	openDialogNext() {
		const dialogConfig = new MatDialogConfig();

        dialogConfig.disableClose = true;
        dialogConfig.autoFocus = true;

		this.dialog.closeAll();
    	this.router.navigate(['/home/partners-management/recap']);
	}

	openDialogPrevious() {
		const dialogConfig = new MatDialogConfig();

        dialogConfig.disableClose = true;
        dialogConfig.autoFocus = true;

		this.dialog.closeAll();
		const dialogRef = this.dialog.open(ApiConfiguration, dialogConfig);
		dialogRef.componentInstance.isEditingPartner = this.isEditingPartner;
	}

	previous() {
		if(this.indexCountry > 0) {
			this.indexCountry -= 1;
		} else {
			this.indexCountry = 0;
		}
	}

	next() {
		if(this.indexCountry < this.countryArray.length - 1) {
			this.indexCountry += 1;
		} else {
			this.indexCountry = this.countryArray.length - 1;
		}
	}

	checkCountryValue($e) {
		let countryCode = $e.target.value.split('-')[0];
		let mnoCode = $e.target.value.split('-')[1];
		let country = this.countryArray.filter(country => country.code == countryCode)[0];

		if ($e.target.checked) {
			country['mno'].filter(mno => mno.name == mnoCode)[0].selected = true;
		} else {
			country['mno'].filter(mno => mno.name == mnoCode)[0].selected = false;
		}
	}
}
