<div class="grid grid-cols-1">

	<div class="bg-white grid grid-cols-1 border-b border-gray-200 border-solid pl-5 py-5">
		<h6 class="col-span-1">
			Selected right : {{ currentMenu.label }}
		</h6>
	</div>

	<!-- This example requires Tailwind CSS v2.0+ -->
	<div class="flex flex-col">
		<div class="my-2 overflow-x-auto sm:-mx-6 lg:-mx-8">
			<div class="py-2 align-middle inline-block min-w-full sm:px-6 lg:px-8 grid grid-cols-1">

				<div class="col-span-1 px-5">

					<div class="grid grid-cols-4 col-span-6 bg-gray-200 p-2 font-bold">
						<div class="col-span-2 text-left">
							<span class="text-gray-700">Menu to grant to the profile</span>
						</div>
						<div class="col-span-1 text-center">
							Read
						</div>
						<div class="col-span-1 text-center">
							Write
						</div>
					</div>

					<div class="col-span-6 grid grid-cols-1">
						<div class="grid grid-cols-4 col-span-4"
							*ngFor="let submenu of currentMenu.submenu; let index = index;">
							<div class="col-span-2 text-left mt-5">
								<span class="text-gray-700">App > {{ currentMenu.label }} > {{ submenu.label }}</span>
							</div>
							<div class="col-span-1 mt-5 text-center">
								<label class="inline-flex items-center">
									<input type="radio" (change)="changeRight($event, submenu, 'read')"
										class="form-radio" name="{{ submenu.menu }}"
										[attr.checked]="submenu.read ? 'checked' : null">
									<span class=""></span>
								</label>
							</div>
							<div class="col-span-1 mt-5 text-center">
								<label class="inline-flex items-center">
									<input type="radio" (change)="changeRight($event, submenu, 'write')"
										class="form-radio" name="{{ submenu.menu }}"
										[attr.checked]="submenu.write ? 'checked' : null">
									<span class=""></span>
								</label>
							</div>
						</div>

						<div class="col-span-4 py-2 align-middle inline-block min-w-full sm:px-6 lg:px-8 mt-10 text-center">
							<button type="submit" class="bg-yellow-500 text-white active:bg-yellow-600 font-bold 
								text-base px-8 py-3 rounded-full shadow-md hover:shadow-lg outline-none 
								focus:outline-none mr-1 mb-1 ease-linear transition-all duration-150
								inline-block inline" cdkFocusInitial (click)="closeDialog()">
								Enregistrer
							</button>
						</div>

					</div>

				</div>
			</div>
		</div>
	</div>
</div>