import { Partner } from './partner.model';
import { UserPartner } from './user-partner.model';

/**
 * @description contains Client information
 */
export class User {

    id: string;
    firstName: string;
    lastName: string;
    password: string;
    address: string;
    email: string;
    profile: string;
    zipCode: string;
    city: string;
    country: string;
    phoneNumber: string;
    firstConnection: string;
    departmentId: string;
    partnerId: number;
    createdAt: Date;
    deleteAt: Date;
    updateAt: Date;
    amount: number;
    state: string;
    status: boolean;
    access_token: string;
    partner: UserPartner;
    connectionType: string;

    constructor(id: string, firstName: string, lastName: string, email: string, adress: string, status: boolean, partnerId: number, phoneNumber: string, profile: string, access_token: string, partner: UserPartner, connectionType: string) {
            this.id = id;
            this.firstName = firstName;
            this.lastName = lastName;
            this.access_token = access_token;
            this.email = email;
            this.address = adress;
            this.status = status;
            this.partnerId = partnerId;
            this.phoneNumber = phoneNumber;
            this.profile = profile;
            this.partner = partner;
            this.connectionType = connectionType;
    }
}
