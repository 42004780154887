import { Injectable } from '@angular/core';
import { Router } from '@angular/router';
import { HttpClient } from '@angular/common/http';
import { BehaviorSubject, Observable } from 'rxjs';
import { environment } from '../../environments/environment';
import { Profil } from '@app/_models/index';
import { Subject } from "rxjs";

@Injectable({ providedIn: 'root' })
export class ProfilService {
    private profilSubject: BehaviorSubject<Profil>;
    public profil: Observable<Profil>;
    private profils: Profil[] = [];
    profilsSubject = new Subject<Profil[]>();
    profileToAdd = null;

    constructor(
        private router: Router,
        private http: HttpClient
    ) {
        this.getAll()
            .subscribe(profils => {
                this.profils = profils['data'];
                this.emitProfils();
            });
    }

    public get userValue(): Profil {
        return this.profilSubject.value;
    }

    emitProfils() {
        this.profilsSubject.next(this.profils.slice());
    }

    create(profil: Profil) {
        return this.http.post(`${environment.api}/user-manager/users/profiles`, profil);
    }

    /* getAll() {
        return this.profils;
    } */

    getAll() {
        return this.http.get<Profil[]>(`${environment.api}/user-manager/users/profiles`);
    }

    getById(id: string) {
        return this.http.get<Profil>(`${environment.api}/user-manager/users/profiles/${id}`);
    }

    getProfils() {
        return this.profils;
    }

    addProfile(profileName: string) {
        return this.http.post(`${environment.api}/user-manager/users/profiles`, profileName).pipe(response => response);
    }

    updateProfile(oldName: string, profileName: string) {
        return this.http.put(`${environment.api}/user-manager/users/profiles/${oldName}`, profileName).pipe(response => response);
    }

    deleteProfile(profileName: string) {
        return this.http.delete(`${environment.api}/user-manager/users/profiles/`+profileName).pipe(response => response);
    }

    emitPartnersAfterDeletd(profil: Profil) {
		this.profils.forEach((p, i) => {
            if (p['id'] == profil['id']) {
                this.profils.splice(i, 1);
            };
        });

        this.emitProfils();
    }

    addRights(data: any){
        return this.http.post(`${environment.api}/user-manager/users/profiles/roles`, data).pipe(response => response);
    }

    getRights(profileName: string){
        return this.http.get(`${environment.api}/user-manager/users/profiles/roles/${profileName}`).pipe(response => response);
    }

    getCurrentProfile() {
        return this.profileToAdd;
    }
}
