import { Component, OnInit } from '@angular/core';

@Component({
	selector: 'app-repayment',
	templateUrl: './virement.component.html'
})

export class VirementComponent implements OnInit {
	sectionTitle = "Dashboard";
	sectionItem = "Traffic";
	sectionLeftTitle = "Traffic";
	sectionRightTitle = "To be defined";

	constructor() {}

	ngOnInit(): void {}
}
