<div class="w-full">
	<div class="content-header bg-white rounded shadow-xl">
		<div class="grid grid-cols-1">

			<div class="flex h-screen overflow-hidden">
				<div class="m-auto w-max container">
					<div class="text-center center mb-5">
						<img class="responsive-img inline" src="../../../assets/images/Logo.svg" alt="" />
					</div>
					<div class="bg-white shadow-md rounded grid grid-cols-2">

						<div  class="col-span-1 px-8 pt-6 pb-8 mb-4">
							<div class="mb-5">
								<h5>
									Binevenue
								</h5>

								<h6>
									Connectez-vous à votre compte
								</h6>
							</div>

							<form class="col-md-12" [formGroup]="form" (ngSubmit)="onSubmit()">

								<div class="mb-4">
									<label class="block text-grey-darker text-sm font-bold mb-2" for="username">
										Username
									</label>

									<input type="email" formControlName="email" class="shadow appearance-none border rounded w-full py-2 px-3 text-grey-darker input"
										[ngClass]="{ 'is-invalid': submitted && f.email.errors }" id="email" type="text" placeholder="Username">

									<div *ngIf="submitted && f.email.errors" class="invalid-feedback">
										<div *ngIf="f.email.errors.required">E-mail est obligatoire</div>
										<div *ngIf="f.email.errors.email">Le mail est incorrect</div>
									</div>
								</div>
								<div class="mb-6">
									<label class="block text-grey-darker text-sm font-bold mb-2" for="password">
										Password
									</label>
									<a class="inline-block align-baseline font-bold text-sm text-blue hover:text-blue-darker" href="#">
										Forgot Password?
									</a>

									<input formControlName="password" id="password" class="shadow appearance-none border border-red rounded w-full py-2 px-3 text-grey-darker mb-3 input"
										id="password" type="password" placeholder="******************" />

									<div *ngIf="submitted && f.password.errors" class="invalid-feedback">
										<div *ngIf="f.password.errors.required">Password is required</div>
									</div>
								</div>
								<div class="w-full items-center justify-between">

									<button  [disabled]="loading" class="bg-yellow-500 text-white font-bold py-2 px-4 rounded-full w-full">
										<svg class="animate-spin h-4 w-4 m-auto rounded-full bg-transparent border-2 border-transparent border-opacity-50 border-loader-color inline mr-5"
											viewBox="0 0 24 24" *ngIf="loading"></svg>
										Sign In
									</button>
								</div>
							</form>
						</div>

						<div class="col-span-1 img-section">
							<img src="../../../../../../assets/images/login-avatar.png" />
						</div>

					</div>

				</div>
			</div>

		</div>
	</div>
</div>
