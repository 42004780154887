import { Component, OnInit } from '@angular/core';

@Component({
	selector: 'app-category-detail',
	templateUrl: './category-detail.component.html',
	styleUrls: ['./category-detail.component.css']
})

export class CategoryDetailComponent implements OnInit {

	constructor() { }

	ngOnInit(): void {}
}
