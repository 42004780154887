import { NgModule } from '@angular/core';
import { ReactiveFormsModule } from '@angular/forms';
import { CommonModule } from '@angular/common';
import { RouterModule } from '@angular/router';

import { PartnerSettingsRoutingModule } from './partner-setting-rooting.module';

import { CommissionConfig } from './commission-config/commission-config.component';
import { AddFees } from './commission-config/add/add-fees.component';
import { AlertConfig } from './alert-config/alert-config.component';
import { AddAlert } from './alert-config/add/add-alert.component';
import { WorkFlowComponent } from './work-flow/work-flow.component';
import { MatDialogModule } from '@angular/material/dialog';
import { MatMenuModule } from '@angular/material/menu';
import { AddFlow } from './work-flow/add/add-flow.component';
import { StepFlow } from './work-flow/add/configure/step.component';
import { StepFlow2 } from './work-flow/add/configure/step-2.component';


@NgModule({
    imports: [
        CommonModule,
        ReactiveFormsModule,
        PartnerSettingsRoutingModule,
        MatDialogModule,
        MatMenuModule,
        RouterModule
    ],
    declarations: [
        CommissionConfig,
        AddFees,
        AlertConfig,
        AddAlert,
        WorkFlowComponent,
        AddFlow,
        StepFlow,
        StepFlow2
    ]
})

export class PartnerSettingsModule { }
