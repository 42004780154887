<nav class="h-auto">
	<div class="nav-wrapper grid grid-cols-3">
		<div class="px-10 col-span-3 xl:col-span-1 2xl:col-span-1 lg:col-span-1 md:col-span-3 sm:col-span-3" style="padding-left: 25px;">
			<a href="#" class="breadcrumb app-root text-breadcrumb">App</a>
			<a href="#" class="breadcrumb text-breadcrumb">{{ 'sidenav.dashboard.global' | translate }}</a>
			<a href="#" class="breadcrumb text-breadcrumb">{{ 'sidenav.dashboard.traffic' | translate }}</a>
		</div>

		<div class="col-span-3 xl:col-span-2 2xl:col-span-2 lg:col-span-2 md:col-span-3 sm:col-span-3">
			<app-filter></app-filter>
		</div>
	</div>
</nav>


<div class="pb-10">
	<div class="bg-white rounded m-5 p-0 sm:w-full">
		<div class="pr-3 grid grid-cols-3 shadow-lg w-full">

			<div class="lg:col-span-2 md:col-span-3 xs:col-span-3 col-span-3 sm:col-span-3 xl:col-span-2 2xl:col-span-2 bg-white 
				lg:border-r-2 xl:border-r-2 2xl:border-r-2 lg:p-10 xl:p-10 2xl:p-10
				sm:py-10 md:py-10 sm:px-3 md:px-3 relative">
				<div>
					<span class="spinner-border spinner-border-lg align-center"></span>
				</div>
				<h6 class="inline-block align-middle">Traffic</h6>
				<div class="loader ease-linear rounded-full border-2 border-t-2 border-gray-200 h-10 w-10 inline-block ml-5 mt-2 absolute right-11" *ngIf="getLoader()"></div>
				<app-chart transactionType='trafic'></app-chart>
			</div>

			<div class="lg:col-span-1 md:col-span-3 sm:col-span-3 col-span-3 xl:col-span-1 2xl:col-span-1 xs:col-span-3 grid
				xl:grid-rows-2 2xl:grid-rows-2 md:grid-cols-2 sm:grid-cols-2 sm:pb-5 md:pb-5">

				<div class="xl:col-span-2 2xl:col-span-2 lg:col-span-2 md:col-span-1 sm:col-span-2 row-span-1 
					grid grid-cols-1 justify-center items-center
					xl:border-b-2 2xl:border-b-2 lg:border-b-2 sm:border-r-2 sm:pb-5 pb-5 pt-5">
					<app-amount-card class="" [amount]="successTransactions.length" title="Success" badge="success"
						description="Total du trafic (success)" currency=""
						[pourcentage]="successPourcentage"></app-amount-card>
				</div>

				<div class="lg:col-span-2 xl:col-span-2 2xl:col-span-2 md:col-span-1 sm:col-span-2 row-span-1 
					grid grid-cols-1 justify-center items-center sm:pt-5 pt-5 pb-5">					<app-amount-card class="" [amount]="failedTransactions.length" title="Fails" badge="failed"
						description="Total du trafic (fails)" currency=""
						[pourcentage]="failsPourcentage"></app-amount-card>
				</div>
			</div>
		</div>
	</div>

	<div class="bg-white rounded mx-5 mb-10 shadow-xl">
		<div class="grid grid-cols-1 gap-2 p-5">

			<div class="grid grid-cols-4">
				<div class="col-span-2">
					<h6>
						Détails des transactions
					</h6>
				</div>
	
				<div class="col-span-2">
					<button
						class="bg-white text-black active:bg-white font-bold
						text-base px-5 py-3 rounded-full shadow-md hover:shadow-lg outline-none 
						focus:outline-none mr-1 mb-1 ease-linear transition-all duration-150 
						inline-block inline btn-filter float-right" cdkFocusInitial (click)='downloadExcel()'>
						<img src="../../../../../../../assets/icones/Boutton/exporter.svg" alt="Filter icone"
							class="inline mr-2 left-4 top-0" style="top: 0px;"/>
						<span class="ml-4">
							Export Excel
						</span>
					</button>
				</div>
			</div>

			<table class="min-w-full divide-y divide-gray-200">
				<thead class="bg-gray-50">
					<tr>
						<th scope="col" class="px-6 py-3 text-left text-xs font-medium text-gray-500 uppercase tracking-wider">
							#
						</th>

						<th scope="col" class="px-6 py-3 text-left text-xs font-medium text-gray-500 uppercase tracking-wider">
							FromCountry
						</th>

						<th scope="col" class="px-6 py-3 text-left text-xs font-medium text-gray-500 uppercase tracking-wider">
							ToCountry
						</th>

						<th scope="col" class="px-6 py-3 text-left text-xs font-medium text-gray-500 uppercase tracking-wider">
							Operator
						</th>

						<th scope="col" class="px-6 py-3 text-left text-xs font-medium text-gray-500 uppercase tracking-wider">
							Merchant Reference
						</th>

						<th scope="col" class="px-6 py-3 text-left text-xs font-medium text-gray-500 uppercase tracking-wider">
							Amount
						</th>
					</tr>
				</thead>

				<tbody class="bg-white divide-y divide-gray-200">
					<!-- *ngFor="let transaction of transactions" --> 
					<tr *ngFor="let transaction of transactions | paginate: { itemsPerPage: 10, currentPage: p }; let index = index">
						<td class="px-6 py-4 whitespace-nowrap text-sm text-gray-500">
							{{ index + 1 }}
						</td>

						<td class="px-6 py-4 whitespace-nowrap text-sm text-gray-500">
							{{ transactionService.country[transaction.fromCountryCode.toUpperCase()] }}
						</td>

						<td class="px-6 py-4 whitespace-nowrap text-sm text-gray-500">
							{{ transactionService.country[transaction.toCountryCode.toUpperCase()] }}
						</td>

						<td class="px-6 py-4 whitespace-nowrap text-sm text-gray-500">
							{{ transaction.mnoName }}
						</td>

						<td class="px-6 py-4 whitespace-nowrap text-sm text-gray-500">
							{{ transaction.merchantReference }}
						</td>

						<td class="px-6 py-4 whitespace-nowrap text-sm text-gray-500">
							{{ transaction.amount }}
						</td>

					</tr>

					<!-- More people... -->
				</tbody>
			</table>

			<pagination-controls 
				(pageChange)="p = $event"
				responsive="true"
				previousLabel="Précédent"
				nextLabel="Suivant"></pagination-controls>

		</div>
	</div>

</div>
