import { NgModule } from '@angular/core';
import { Routes, RouterModule } from '@angular/router';

import { LayoutComponent } from '@app/components/layout/layout.component';
import { CurrencyComponent } from './currency/currency.component';
import { AddCurrencyComponent } from './currency/add/add-currency.component';
import { ConfigCurrencyComponent } from './currency/config/config-currency.component';
import { ValidateComponent } from './currency/validate/validate.component';
import { MnoComponent } from './pays-mno-eme/mno.component';
import { AddMno } from './pays-mno-eme/add/add-mno.component';
import { OfferBizaoComponent } from './offer/offer.component';
import { AddOfferComponent } from './offer/add/add-offer.component';


const routes: Routes = [
    {
        path: '', component: LayoutComponent,
        children: [
            { path: 'devises', component: CurrencyComponent },
            { path: 'devises/add', component: AddCurrencyComponent },
            { path: 'devises/config', component: ConfigCurrencyComponent },
            { path: 'devises/validate', component: ValidateComponent },
            { path: 'devises/validate', component: ValidateComponent },
            { path: 'mno', component: MnoComponent },
            { path: 'mno/add', component: AddMno },
            { path: 'offer', component: OfferBizaoComponent },
            { path: 'offer/add', component: AddOfferComponent },
        ]
    }
];

@NgModule({
    imports: [RouterModule.forChild(routes)],
    exports: [RouterModule]
})
export class GeneralSettingRoutingModule { }
