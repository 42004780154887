<div class="grid grid-cols-1">
    <!-- This example requires Tailwind CSS v2.0+ -->
    <div class="flex flex-col">
        <div class="overflow-x-auto sm:-mx-6 lg:-mx-8">
            <div class="align-middle inline-block min-w-full sm:px-6 lg:px-8 grid grid-cols-4 " style="height: 700px; width: 800px; overflow-y: scroll;">

                <div class="col-span-4 grid grid-cols-5">

                    <div class="col-span-2 bg-yellow-500 text-center px-10 flex h-auto">
                        <div class="align-middle text-white m-auto">
                            <img class="inline" src="/assets/images/details-transactions.svg" alt="dashboard" width="100px" />

                            <h6>
                                N° {{ transaction.merchantReference }}
                            </h6>

                            <h4>
                                {{ transaction.amount }} FCFA
                            </h4>

                            <div class="text-xl">
                                {{ transaction.createdAt | date:'dd-MM-yyyy HH:mm:ss' }}
                            </div>
                        </div>
                    </div>

                    <div class="col-span-3 px-10">
                        <h6 class=""> Details transaction </h6>

                        <div class="bloc-modal-data">
                            <ul class="list-item-title divide-y-2 divide-gray-100">
                                <li class="p-3">
                                    Date :
                                </li>

                                <li class="p-3">
                                    Transaction ID :
                                </li>

                                <li class="p-3">
                                    Sender First Name :
                                </li>

                                <li class="p-3">
                                    Sender Last Name :
                                </li>

                                <li class="p-3">
                                    Sender Adress :
                                </li>

                                <li class="p-3">
                                    Sender Mobile Number :
                                </li>

                                <li class="p-3">
                                    From country :
                                </li>

                                <li class="p-3">
                                    Operator :
                                </li>

                                <li class="p-3">
                                    Merchant Reference :
                                </li>

                                <li class="p-3">
                                    Commission :
                                </li>

                                <li class="p-3">
                                    Beneficiary First Name :
                                </li>

                                <li class="p-3">
                                    Beneficiary Last Name :
                                </li>

                                <li class="p-3">
                                    Statut :
                                </li>
                            </ul>
                        </div>

                        <div class="bloc-modal-data">
                            <ul class="list-item-values divide-y-2 divide-gray-100">
                                <li class="p-3">
                                    {{ transaction.createdAt | date:'dd-MM-yyyy HH:mm:ss' }}
                                </li>

                                <li class="p-3">
                                    {{ transaction.orderId }}
                                </li>

                                <li class="p-3">
                                    {{ transaction.sender.firstName }}
                                </li>

                                <li class="p-3">
                                    {{ transaction.sender.lastName }}
                                </li>

                                <li class="p-3">
                                    {{ transaction.sender.address }}
                                </li>

                                <li class="p-3">
                                    {{ transaction.sender.msisdn }}
                                </li>

                                <li class="p-3">
                                    {{ transaction.fromCountryCode }}
                                </li>

                                <li class="p-3">
                                    -
                                </li>

                                <li class="p-3">
                                    {{ transaction.merchantReference }}
                                </li>

                                <li class="p-3">
                                    -
                                </li>

                                <li class="p-3">
                                    {{ transaction.beneficiary.firstName }}
                                </li>

                                <li class="p-3">
                                    {{ transaction.beneficiary.lastName }}
                                </li>

                                <li class="p-3">
                                    {{ transaction.status }}
                                </li>

                            </ul>
                        </div>
                    </div>

                </div>
            </div>
        </div>
    </div>
</div>
