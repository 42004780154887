import { Component, OnInit, AfterViewInit } from '@angular/core';
import { Transaction, IrtCommission } from '@app/_models/';
import { TransactionService } from '@app/_services/transaction.service';
import { Subscription } from 'rxjs';
import { TransactionUtils } from '@app/utils/transactions.utils';

@Component({
	selector: 'app-dashboard-global',
	templateUrl: './dashboard-global.component.html',
	styleUrls: ['./dashboard-global.component.css']
})

export class DashboardGlobalComponent implements OnInit, AfterViewInit {

	transactionSubscription: Subscription = new Subscription();
	transactions: Transaction[] = null;
	irtCommissions: IrtCommission[] = null;
	transactionsVolume = null;
	totalSucccessRevene = null;
	globalRevenu = null;
	loader = false;
	transactionUtils = new TransactionUtils();

	constructor(private transactionService: TransactionService) {}

	ngAfterViewInit(): void {
		this.transactionService.transactionsSubject.subscribe(
			(transactions: any[]) => {
				this.transactions = transactions;
				this.totalSucccessRevene = this.transactionUtils.getAmountSumSuccessTransactions(this.transactions);
			}
		);
		this.transactionService.emitTransactions();

		this.transactionService.globalRevenuSubject.subscribe(globalRevenu => {
			this.globalRevenu = globalRevenu;
		});
		this.transactionService.emitGlobalRevenu();

		this.transactionService.transactionsVolumeSubject.subscribe(volume => {
			this.transactionsVolume = volume;
		});
		this.transactionService.emitVolume();
	}

	ngOnInit(): void {}

	getLoader () {
		return this.transactionService.loaderTransactions;
	}

}
