<nav>
	<div class="nav-wrapper">
		<div class="col s12">
			<a href="#" class="breadcrumb app-root">App</a>
			<a href="#" class="breadcrumb">{{ 'sidenav.operations.title' | translate }}</a>
			<a href="#" class="breadcrumb">{{ 'sidenav.operations.accountStatement' | translate }}</a>
		</div>
	</div>
</nav>

<div class="content pb-10">
	<div class="content-header bg-white rounded m-5 p-0">
		<div class="grid grid-cols-1 shadow-lg p-5">

			<div class="bg-white grid grid-cols-2 border-b-2 border-gray-200 pb-5">
				<h6 class="col-span-1">
					Currency configuration
				</h6>

				<div class="col-span-1 float-right content-shadow">
					<button class="bg-yellow-500 text-white font-bold py-2 px-4 rounded-full float-right">
						Filtre
				</button>
				</div>
			</div>

			<!-- This example requires Tailwind CSS v2.0+ -->
			<div class="flex flex-col">
				<div class="my-2 overflow-x-auto sm:-mx-6 lg:-mx-8">
					<div class="py-2 align-middle inline-block min-w-full sm:px-6 lg:px-8">
						<div class="shadow overflow-hidden border-b border-gray-200 sm:rounded-lg">
							<table class="min-w-full divide-y divide-gray-200">
								<thead class="bg-gray-50">
									<tr>
										<th scope="col"
											class="px-6 py-3 text-left text-xs font-medium text-gray-500 uppercase tracking-wider">
											#
										</th>

										<th scope="col"
											class="px-6 py-3 text-left text-xs font-medium text-gray-500 uppercase tracking-wider">
											Date
										</th>

										<th scope="col"
											class="px-6 py-3 text-left text-xs font-medium text-gray-500 uppercase tracking-wider">
											Reference
										</th>

										<th scope="col"
											class="px-6 py-3 text-left text-xs font-medium text-gray-500 uppercase tracking-wider">
											Operation Type
										</th>

										<th scope="col"
											class="px-6 py-3 text-left text-xs font-medium text-gray-500 uppercase tracking-wider">
											Credit
										</th>

										<th scope="col"
											class="px-6 py-3 text-left text-xs font-medium text-gray-500 uppercase tracking-wider">
											Debit
										</th>

										<th scope="col"
											class="px-6 py-3 text-left text-xs font-medium text-gray-500 uppercase tracking-wider">
											Solde
										</th>

										<th scope="col" class="relative px-6 py-3">
											Status
										</th>
									</tr>
								</thead>

								<tbody class="bg-white divide-y divide-gray-200">
									<tr>
										<td class="px-6 py-4 whitespace-nowrap text-sm text-gray-500">
											1
										</td>

										<td class="px-6 py-4 whitespace-nowrap text-sm text-gray-500">
											14-05-2021
										</td>

										<td class="px-6 py-4 whitespace-nowrap text-sm text-gray-500">
											WLD2000
										</td>

										<td class="px-6 py-4 whitespace-nowrap text-sm text-gray-500">
											PREFINANCEMENT
										</td>

										<td class="px-6 py-4 whitespace-nowrap text-sm text-gray-500">
											10 000 000
										</td>

										<td class="px-6 py-4 whitespace-nowrap text-sm text-gray-500">
											10 000 000
										</td>

										<td class="px-6 py-4 whitespace-nowrap text-sm text-gray-500">
											10 000 000
										</td>

										<td class="px-6 py-4 whitespace-nowrap text-sm text-gray-500">
											<div style="padding-top: 0.1em; padding-bottom: 0.1rem" 
												class="text-xs px-3 bg-green-200 text-green-800 rounded-full inline-block">
												Success
											</div>
										</td>
									</tr>

									<tr>
										<td class="px-6 py-4 whitespace-nowrap text-sm text-gray-500">
											2
										</td>

										<td class="px-6 py-4 whitespace-nowrap text-sm text-gray-500">
											14-05-2021
										</td>

										<td class="px-6 py-4 whitespace-nowrap text-sm text-gray-500">
											WLD2000
										</td>

										<td class="px-6 py-4 whitespace-nowrap text-sm text-gray-500">
											PREFINANCEMENT
										</td>

										<td class="px-6 py-4 whitespace-nowrap text-sm text-gray-500">
											10 000 000
										</td>

										<td class="px-6 py-4 whitespace-nowrap text-sm text-gray-500">
											10 000 000
										</td>

										<td class="px-6 py-4 whitespace-nowrap text-sm text-gray-500">
											10 000 000
										</td>

										<td class="px-6 py-4 whitespace-nowrap text-sm text-gray-500">
											<div style="padding-top: 0.1em; padding-bottom: 0.1rem" 
												class="text-xs px-3 bg-red-200 text-red-800 rounded-full 
													inline-block">
												Echec
											</div>
										</td>
									</tr>

									<tr>
										<td class="px-6 py-4 whitespace-nowrap text-sm text-gray-500">
											3
										</td>

										<td class="px-6 py-4 whitespace-nowrap text-sm text-gray-500">
											14-05-2021
										</td>

										<td class="px-6 py-4 whitespace-nowrap text-sm text-gray-500">
											WLD2000
										</td>

										<td class="px-6 py-4 whitespace-nowrap text-sm text-gray-500">
											PREFINANCEMENT
										</td>

										<td class="px-6 py-4 whitespace-nowrap text-sm text-gray-500">
											10 000 000
										</td>

										<td class="px-6 py-4 whitespace-nowrap text-sm text-gray-500">
											10 000 000
										</td>

										<td class="px-6 py-4 whitespace-nowrap text-sm text-gray-500">
											10 000 000
										</td>

										<td class="px-6 py-4 whitespace-nowrap text-sm text-gray-500">
											
											<div style="padding-top: 0.1em; padding-bottom: 0.1rem" 
												class="text-xs px-3 bg-green-200 text-green-800 rounded-full
													inline-block">
												Success
											</div>
										</td>
									</tr>

									<tr>
										<td class="px-6 py-4 whitespace-nowrap text-sm text-gray-500">
											4
										</td>

										<td class="px-6 py-4 whitespace-nowrap text-sm text-gray-500">
											14-05-2021
										</td>

										<td class="px-6 py-4 whitespace-nowrap text-sm text-gray-500">
											WLD2000
										</td>

										<td class="px-6 py-4 whitespace-nowrap text-sm text-gray-500">
											PREFINANCEMENT
										</td>

										<td class="px-6 py-4 whitespace-nowrap text-sm text-gray-500">
											10 000 000
										</td>

										<td class="px-6 py-4 whitespace-nowrap text-sm text-gray-500">
											10 000 000
										</td>

										<td class="px-6 py-4 whitespace-nowrap text-sm text-gray-500">
											10 000 000
										</td>

										<td class="px-6 py-4 whitespace-nowrap text-sm text-gray-500">
											
											<div style="padding-top: 0.1em; padding-bottom: 0.1rem" 
												class="text-xs px-3 bg-yellow-200 text-yellow-800 rounded-full
													inline-block">
												En attente de validation
											</div>

										</td>
									</tr>

									<tr>
										<td class="px-6 py-4 whitespace-nowrap text-sm text-gray-500">
											5
										</td>

										<td class="px-6 py-4 whitespace-nowrap text-sm text-gray-500">
											14-05-2021
										</td>

										<td class="px-6 py-4 whitespace-nowrap text-sm text-gray-500">
											WLD2000
										</td>

										<td class="px-6 py-4 whitespace-nowrap text-sm text-gray-500">
											PREFINANCEMENT
										</td>

										<td class="px-6 py-4 whitespace-nowrap text-sm text-gray-500">
											10 000 000
										</td>

										<td class="px-6 py-4 whitespace-nowrap text-sm text-gray-500">
											10 000 000
										</td>

										<td class="px-6 py-4 whitespace-nowrap text-sm text-gray-500">
											10 000 000
										</td>

										<td class="px-6 py-4 whitespace-nowrap text-sm text-gray-500">
											<div style="padding-top: 0.1em; padding-bottom: 0.1rem" 
												class="text-xs px-3 bg-green-200 text-green-800 rounded-full
													inline-block">
												Success
											</div>
										</td>
									</tr>
									<!-- More people... -->
								</tbody>
							</table>
						</div>

						<div class="col-span-1 float-right content-shadow mt-5 mb-5 rounded shadow-lg rounded-full shadow">
							<a routerLink="/home/general-settings/devises/add"
								class="bg-white-500 text-black font-bold py-2 px-4 rounded-full float-right">
								Envoyer par mail
							</a>
						</div>

						<div class="col-span-1 float-right content-shadow mt-5 mb-5 mr-5 rounded shadow-lg rounded-full shadow">
							<a routerLink="/home/general-settings/devises/add"
								class="bg-white-500 text-black font-bold py-2 px-4 rounded-full float-right">
								Télécharger
							</a>
						</div>
					</div>
				</div>
			</div>
		</div>
	</div>
</div>
