<nav class="bg-transparent shadow-none">
	<div class="nav-wrapper">
		<div class="col s12">
			<a href="#" class="breadcrumb app-root text-gray-400">App</a>
			<a href="#" class="breadcrumb text-gray-400">Opération</a>
			<a href="#" class="breadcrumb text-gray-400">Emission d'ordre</a>
			<a href="#" class="breadcrumb text-gray-400">Déclaration de dépôt bancaire</a>
		</div>
	</div>
</nav>

<div class="content">
	<div class="content-header bg-white rounded m-5 p-0">
		<div class="grid grid-cols-1 shadow-lg p-5">

			<div class="bg-white grid grid-cols-2 border-b-2 border-gray-200 pb-5">
				<h6 class="col-span-1">
					Customer service - new request
				</h6>
			</div>

			<!-- This example requires Tailwind CSS v2.0+ -->
			<div class="flex flex-col">
				<div class="my-2 overflow-x-auto sm:-mx-6 lg:-mx-8">
					<div class="py-2 align-middle inline-block min-w-full sm:px-6 lg:px-8 grid grid-cols-8 gap-4">

                        <div class="col-start-3 col-span-4 pt-20">

							<div class="grid grid-cols-3 border-b border-gray-300 border-dashed mb-10">
								<div class="col-span-1">
									<span class="font-bold">Ticket ID</span> <br>
									<span>Biza-0000142521</span>
								</div>

								<div class="col-span-1">
									<span class="font-bold">Partner</span> <br>
									<span>Small world</span>
								</div>

								<div class="col-span-1">
									<span class="font-bold">User name</span> <br>
									<span>Beta</span>
								</div>
							</div>

                            <div class="grid grid-cols-4">
								<div class="col-span-4 grid grid-cols-4">
									<div class="col-span-1 text-right pt-5 pr-5">
										<span class="text-gray-700">Product</span>
									</div>
									<div class="col-span-3">
										<select name="cars" id="cars" class="block">
											<option value="xof">XOF</option>
											<option value="Euro">Euro</option>
										</select>
									</div>
								</div>

								<div class="col-span-4 grid grid-cols-4">
									<div class="col-span-1 text-right pt-5 pr-5 mt-5">
										<span class="text-gray-700">Category</span>
									</div>
									<div class="col-span-3 mt-5">
										<select name="cars" id="cars" class="block">
											<option value="xof">XOF</option>
											<option value="Euro">Euro</option>
										</select>
									</div>
								</div>

								<div class="col-span-4 grid grid-cols-4">
									<div class="col-span-1 text-right pt-5 pr-5 mt-5">
										<span class="text-gray-700">Criticity</span>
									</div>
									<div class="col-span-3 mt-5">
										<select name="cars" id="cars" class="block">
											<option value="xof">XOF</option>
											<option value="Euro">Euro</option>
										</select>
									</div>
								</div>

								<div class="col-span-4 grid grid-cols-4">
									<div class="col-span-1 text-right pt-5 pr-5 mt-5">
										<span class="text-gray-700">Deposit amount</span>
									</div>
									<div class="col-span-3 mt-5">
										<textarea cols="50" rows="5" class="border border-gray-300"></textarea>
									</div>
								</div>
                            </div>

                            <div class="col-span-3 grid grid-cols-2 mt-20">
                                <div class="col-span-1">
                                    <button class="text-yellow-500 bg-transparent border border-solid 
                                        border-yellow-500 hover:bg-yellow-500 hover:text-white 
                                        active:bg-yellow-600 font-bold px-8 py-3 rounded-full 
                                        outline-none focus:outline-none mr-1 mb-1 ease-linear transition-all 
                                        duration-150 float-right mr-10"
                                        type="button">
                                        Annuler
                                  </button>
                                </div>

                                <div class="col-span-1">
                                    <button (click) = "openDialog()"
                                        class="bg-yellow-500 text-white active:bg-yellow-600 font-bold
                                        text-base px-8 py-3 rounded-full shadow-md hover:shadow-lg outline-none 
                                        focus:outline-none mr-1 mb-1 ease-linear transition-all duration-150
                                        inline-block">
                                        Validate
								</button>
                                </div>
                            </div>

                        </div>
					</div>
				</div>
			</div>
		</div>
	</div>
</div>
