<nav class="bg-transparent shadow-none text-gray-400">
	<div class="nav-wrapper">
		<div class="col s12 text-gray-400">
			<a href="#" class="breadcrumb app-root text-gray-400">App</a>
			<a href="#" class="breadcrumb text-gray-400">Commission</a>
			<a href="#" class="breadcrumb text-gray-400"> Configuration de flow </a>
		</div>
	</div>
</nav>


<div class="content pb-10">
	<div class="content-header bg-white rounded m-5 p-0">
		<div class="grid grid-cols-1 shadow-lg p-5">

			<div class="bg-white grid grid-cols-2 border-b-2 border-gray-200 pb-5">
				<h6 class="col-span-1">
					Configure new flow
				</h6>
			</div>

            <!-- This example requires Tailwind CSS v2.0+ -->
            <div class="flex flex-col">
                <div class="my-2 overflow-x-auto sm:-mx-6 lg:-mx-8">
                    <div class="py-2 align-middle inline-block min-w-full sm:px-6 lg:px-8 grid grid-cols-6 gap-4">

                        <div class="col-start-2 col-span-4 pt-20">

                            <div class="grid grid-cols-4">
                                <div class="col-span-1 text-right pt-5 pr-5">
                                    <span class="text-gray-700">Workflow name</span>
                                </div>
                                <div class="col-span-3">
                                    <input type="text" placeholder="Workflow name"/>
                                </div>
                            </div>

                            <div class="py-2 align-middle inline-block min-w-full sm:px-6 lg:px-8 grid grid-cols-2 mt-10">
                            
                                <div class="col-span-1">
                                    <button class="text-yellow-500 bg-transparent border border-solid 
                                        border-yellow-500 hover:bg-yellow-500 hover:text-white 
                                        active:bg-yellow-600 font-bold px-8 py-3 rounded-full 
                                        outline-none focus:outline-none mr-1 mb-1 ease-linear transition-all 
                                        duration-150 float-right mr-10" mat-dialog-close
                                        type="button">
                                        Annuler
                                    </button>
                                </div>

                                <div class="col-span-1">
                                    <button routerLink="/home/partners-settings/work-flow/add/step"
                                        class="bg-yellow-500 text-white active:bg-yellow-600 font-bold
                                        text-base px-8 py-3 rounded-full shadow-md hover:shadow-lg outline-none 
                                        focus:outline-none mr-1 mb-1 ease-linear transition-all duration-150
                                        inline-block float-left" cdkFocusInitial>
                                        Suivant
                                    </button>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>

        </div>
    </div>
</div>

