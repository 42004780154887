import { Component, OnInit } from '@angular/core';

@Component({
	selector: 'app-bank-validation',
	templateUrl: './validation.component.html',
	styleUrls: ['./validation.component.css']
})

export class ValidationComponent implements OnInit {

	constructor() { }

	ngOnInit(): void {}

}
