import { Component, OnInit, Input } from '@angular/core';

@Component({
  selector: 'app-data-container-prototype',
  templateUrl: './data-container-prototype.component.html',
  styleUrls: ['./data-container-prototype.component.css']
})
export class DataContainerPrototypeComponent implements OnInit {
  @Input() sectionTitle: string;
  @Input() sectionItem: string;
  @Input() sectionLeftTitle: string;
  @Input() sectionRightTitle: string;
  constructor() { }

  ngOnInit(): void {
  }

}
