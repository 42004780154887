import { Component } from '@angular/core';
import { Router } from '@angular/router';

@Component({
	selector: 'add-offer',
	templateUrl: './add-offer.component.html',
    styleUrls: ['./add-offer.component.css']
})

export class AddOfferComponent {

    constructor(private router: Router) {}

    configNewCurrency() {}
}
