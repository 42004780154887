import { Component, OnInit } from '@angular/core';

@Component({
	selector: 'validate-currency',
	templateUrl: './validate.component.html'
})

export class ValidateComponent implements OnInit {
	constructor() {}

	ngOnInit(): void {}
}
