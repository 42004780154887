import { Component } from '@angular/core';
import { Router } from '@angular/router';

@Component({
	selector: 'alert-success',
	templateUrl: './alert-success.component.html'
})

export class AlertSuccess {

    constructor(private router: Router) {}

}
