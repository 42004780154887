<nav class="bg-transparent shadow-none">
	<div class="nav-wrapper">
		<div class="col s12">
			<a href="#" class="breadcrumb app-root text-gray-400">App</a>
			<a href="#" class="breadcrumb text-gray-400">Opérations</a>
			<a href="#" class="breadcrumb text-gray-400">Transfert compte à compte</a>
		</div>
	</div>
</nav>

<div class="content">
	<div class="content-header bg-white rounded m-5 p-0">
		<div class="grid grid-cols-1 shadow-lg p-5">

			<div class="bg-white grid grid-cols-2 border-b-2 border-gray-200 pb-5">
				<h6 class="col-span-1">
					Currency configuration
				</h6>
			</div>

			<!-- This example requires Tailwind CSS v2.0+ -->
			<div class="flex flex-col">
				<div class="my-2 overflow-x-auto sm:-mx-6 lg:-mx-8">
					<div class="py-2 align-middle inline-block min-w-full sm:px-6 lg:px-8 grid grid-cols-6 gap-4">

                        <div class="col-start-2 col-span-4 mt-20">

                            <div class="grid grid-cols-4">
                                <div class="col-span-4 grid grid-cols-4">
                                    <div class="col-span-1 text-right pt-5 pr-5">
                                        <span class="text-gray-700">Origin account</span>
                                    </div>
                                    <div class="col-span-2">
                                        <select name="cars" id="cars" class="block">
                                            <option value="volvo">Main account mobile money</option>
                                        </select>
                                    </div>
                                </div>
                                
                                <div class="col-span-4 grid grid-cols-4">
                                    <div class="col-span-1 text-right pt-5 pr-5 mt-10">
                                        <span class="text-gray-700">Currency</span>
                                    </div>
                                    <div class="col-span-2 mt-10">
                                        <input type = "text" placeholder="Currency"/>
                                    </div>
                                </div>

                                <div class="col-span-4 grid grid-cols-4">
                                    <div class="col-span-1 text-right pt-5 pr-5 mt-10">
                                        <span class="text-gray-700">Currency balance</span>
                                    </div>
                                    <div class="col-span-2 mt-10">
                                        <input type = "text" placeholder="Currency balance"/>
                                    </div>
                                </div>

                                <div class="col-span-4 grid grid-cols-4">
                                    <div class="col-span-1 text-right pt-5 pr-5 mt-10">
                                        <span class="text-gray-700">Destination account</span>
                                    </div>
                                    <div class="col-span-2 mt-10">
                                        <select name="cars" id="cars" class="block">
                                            <option value="volvo">Destination Account - Bulk payment</option>
                                        </select>
                                    </div>
                                </div>

                                <div class="col-span-4 grid grid-cols-4">
                                    <div class="col-span-1 text-right pt-5 pr-5 mt-10">
                                        <span class="text-gray-700">Amount to tranfert</span>
                                    </div>
                                    <div class="col-span-2 mt-10">
                                        <input type = "text" placeholder="Amount to tranfert"/>
                                    </div>
                                </div>

                                <div class="col-span-4 grid grid-cols-4">
                                    <div class="col-span-1 text-right pt-5 pr-5 mt-10">
                                        <span class="text-gray-700">Amount to tranfert</span>
                                    </div>
                                    <div class="col-span-2 mt-10">
                                        <textarea cols="50" rows="10"></textarea>
                                    </div>
                                </div>

                            </div>


                            <div class="col-span-3 grid grid-cols-3 mt-20">
                                <div class="col-span-1"></div>

                                <div class="col-span-1">
                                    <a routerLink="/home/general-settings/offer/"
                                        class="bg-yellow-500 text-white active:bg-yellow-600 font-bold
                                        text-base px-8 py-3 rounded-full shadow-md hover:shadow-lg outline-none 
                                        focus:outline-none mr-1 mb-1 ease-linear transition-all duration-150
                                        inline-block">
                                        Validate
                                    </a>
                                </div>
                            </div>

                        </div>
					</div>
				</div>
			</div>

            <!-- This example requires Tailwind CSS v2.0+ -->
			<div class="flex flex-col">
				<div class="my-2 overflow-x-auto sm:-mx-6 lg:-mx-8">
					<div class="py-2 align-middle inline-block min-w-full sm:px-6 lg:px-8 grid grid-cols-6 gap-4">

                        <div class="col-start-2 col-span-4">
                            <div class="grid grid-cols-4 mt-10">

                                <div class="col-span-4 grid grid-cols-3 py-5">
                                    <div class="col-span-1">
                                        <strong class="font-extrabold text-lg">Origin account</strong> <br>
                                        Main account - Mobile money  
                                    </div>

                                    <div class="col-span-1">
                                        Currency <br>
                                        XOF  
                                    </div>

                                    <div class="col-span-1 font-extrabold">
                                        <strong class="font-extrabold text-lg">Currency balance</strong><br>
                                        5,532,000
                                    </div>
                                </div>

                                <div class="col-span-4 grid grid-cols-3 py-5">
                                    <div class="col-span-1">
                                        <strong class="font-extrabold text-lg">Destination account</strong> <br>
                                        Destination Account - Bulk payment  
                                    </div>

                                    <div class="col-span-1">
                                        <strong class="font-extrabold text-lg">Account to transfert</strong><br>
                                        1,500,000  
                                    </div>

                                    <div class="col-span-1 font-extrabold">
                                        <strong class="font-extrabold text-lg">Reason of transfer</strong> <br>
                                        Lorem ipsum dolor sit amet ...
                                    </div>
                                </div>

                            </div>

                            <div class="col-start-2 col-span-4 mt-20">
                                <div class="grid grid-cols-4">
                                    <div class="col-span-1"></div>
                                    <div class="col-span-1">
                                        <button class="text-yellow-500 bg-transparent border border-solid 
                                            border-yellow-500 hover:bg-yellow-500 hover:text-white 
                                            active:bg-yellow-600 font-bold px-8 py-3 rounded-full 
                                            outline-none focus:outline-none mr-1 mb-1 ease-linear transition-all 
                                            duration-150 float-right mr-10"
                                            type="button">
                                            Précédent
                                        </button>
                                    </div>

                                    <div class="col-span-1">
                                        <button (click)="openDialog()"
                                            class="bg-yellow-500 text-white active:bg-yellow-600 font-bold
                                            text-base px-8 py-3 rounded-full shadow-md hover:shadow-lg outline-none 
                                            focus:outline-none mr-1 mb-1 ease-linear transition-all duration-150
                                            inline-block">
                                            Suivant
                                        </button>
                                    </div>
                                </div>
                            </div>

                        </div>
					</div>
				</div>
			</div>

		</div>
	</div>
</div>
