<div class="flex flex-col">
	<div class="my-2 overflow-x-auto sm:-mx-6 lg:-mx-8">
		<div class="py-2 align-middle inline-block min-w-full sm:px-6 lg:px-8 grid grid-cols-6 gap-4">

			<div class="col-span-6 pb-5 pl-10 border-gray-200 border-b">
                <h6>
                    Ajout de nouveau profile
                </h6>
            </div>

			<div class="col-start-2 col-span-4 pt-5">

				<div class="grid grid-cols-4">
					<div class="col-span-1 text-right pt-5 pr-5 mt-5">
						<span class="text-gray-700">Nom du partenaire</span>
					</div>
					<div class="col-span-3 mt-5">
						<select name="cars" id="cars" class="block">
							<option value="volvo">Small world</option>
							<option value="volvo">Small world</option>
						</select>
					</div>

					<div class="col-span-1 text-right pt-5 pr-5 mt-5">
						<span class="text-gray-700">Nom du partenaire</span>
					</div>
					<div class="col-span-3 mt-5">
						<select name="cars" id="cars" class="block">
							<option value="volvo">Small world</option>
							<option value="volvo">Small world</option>
						</select>
					</div>

					<div class="col-span-1 text-right pt-5 pr-5 mt-5">
						<span class="text-gray-700">Type de partenaire</span>
					</div>
					<div class="col-span-3 mt-5">
						<select name="cars" id="cars" class="block">
							<option value="volvo">IRT</option>
							<option value="saab">IRT</option>
						</select>
					</div>

					<div class="col-span-1 text-right pt-5 pr-5 mt-5">
						<span class="text-gray-700">Personne à contacter</span>
					</div>
					<div class="col-span-3 mt-5">
						<input type = "text" placeholder="Personne à contacter"/>
					</div>

					<div class="col-span-4 text-center">
						<button class="bg-yellow-500 text-white font-bold py-2 px-10 rounded-full mt-5">
							Suivant
						</button>
					</div>

				</div>
			</div>
		</div>
	</div>
</div>
